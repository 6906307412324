import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {UserGroup} from "./user-group.model";
import {ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";

@BasicEntity({
    name: 'Usuarios',
    endpoint: 'usuarios',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'username',
    type: 'Usuario'
})
export class Usuario extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;


    @BasicProperty({
        name: 'Nombre de usuario',
        type: PropertyType.String(),
        filters: PartialSearchFilter
    })
    private _username;

    @BasicProperty({
        name: 'Contraseña',
        type: PropertyType.String(),
        nullable: true,
        readWrite: ReadWrite.WriteOnly
    })
    private _plainPassword;

    @BasicProperty({
        name: 'Expiración contraseña',
        type: PropertyType.DateTime(),
        readWrite: ReadWrite.ReadOnly,
        nullable: true
    })
    private _passwordValidUntil;

    @BasicProperty({
        name: 'Activado',
        type: PropertyType.Boolean(),
        readWrite: ReadWrite.ReadWrite
    })
    private _enabled;

    @BasicProperty({
        name: 'Grupos',
        type: PropertyType.NestedModel(UserGroup, null, NestedBehavior.SendIriOnly),
        array: true,
        readWrite: ReadWrite.ReadWrite
    })
    private _groups;

    @BasicProperty({
        name: 'Idioma',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly,
        nullable: true
    })
    private _locale;

    @BasicProperty({
        name: 'Contraseña no expirada',
        type: PropertyType.Boolean(),
        readWrite: ReadWrite.ReadOnly
    })
    private _passwordValidYet;

    @BasicProperty({
        name: 'Roles',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly,
        array: true
    })
    private _roles;

    @BasicProperty({
        name: 'Token',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly,
        nullable: true
    })
    private _token: string | null;


    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get plainPassword() {
        return this._plainPassword;
    }

    set plainPassword(value) {
        this._plainPassword = value;
    }

    get passwordValidUntil() {
        return this._passwordValidUntil;
    }

    set passwordValidUntil(value) {
        this._passwordValidUntil = value;
    }

    get username() {
        return this._username;
    }

    set username(value) {
        this._username = value;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        this._enabled = value;
    }

    get groups() {
        return this._groups;
    }

    set groups(value) {
        this._groups = value;
    }

    get locale() {
        return this._locale;
    }

    set locale(value) {
        this._locale = value;
    }

    get passwordValidYet() {
        return this._passwordValidYet;
    }

    set passwordValidYet(value) {
        this._passwordValidYet = value;
    }

    get roles() {
        return this._roles;
    }

    set roles(value) {
        this._roles = value;
    }

    get token(): string | null {
        return this._token;
    }

    set token(value: string | null) {
        this._token = value;
    }
}
