import {Component} from '@angular/core';
import {
    BasicEntityTableColumnDescriptionOrString,
    ColumnJustification
} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Empleado} from "../../../model/personas/empleado.model";

@Component({
    selector: 'app-empleados',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model" id="empleados"></be-table>'
})
export class EmpleadosComponent {
    public model = Empleado;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        {key: 'id', hiddenDisplayable: true},
        {key: 'nombreCompleto', hiddenInEdition: true, weight: 4},
        {key: 'usuario', weight: 2},
        {key: 'nombre', hidden: true},
        {key: 'apellido1', hidden: true},
        {key: 'apellido2', hidden: true},
        {key: 'cifNif', weight: 2, justification: ColumnJustification.Center},
        {key: 'email', weight: 4},
        {key: 'fechaCreacion', weight: 2, justification: ColumnJustification.Center},
        {key: 'fechaModificacion', weight: 2, justification: ColumnJustification.Center},
        {key: 'direcciones', hidden: true},
        {key: 'telefonos', hidden: true},
        {key: 'cargo', weight: 2},
        {key: 'grupo', weight: 2},
        {key: 'centros', hidden: true},
        {key: 'idioma', weight: 2, justification: ColumnJustification.Center}
    ];
}
