import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {Persona} from './persona.model';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {ManagerCachingStrategy, ReadWrite} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {NumericFilter} from '../../basic-entity-back/filters/numeric-filter';
import {BooleanFilter} from '../../basic-entity-back/filters/boolean-filter';
import {PersonaContacto} from "./contacto.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {DescuentoCliente} from "../trabajos/descuento-cliente.model";
import {IRI_PROPERTY, TYPE_PROPERTY} from "../../basic-entity-back/basic-entity-interface/mapping-internal";
import {ExactSearchFilter, PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {Comercial} from "./comercial.model";
import {DireccionPersona} from "./direccion-persona.model";
import {
    DireccionesEditionComponent
} from "../../pages/personas/clientes/direcciones-edition/direcciones-edition.component";
import {FormaPagoCliente} from "../tesoreria/formas-pago/forma-pago-cliente.model";
import {Mandato} from "../tesoreria/mandatos/mandato.model";
import {
    FormasPagoClienteEditionComponent
} from "../../pages/tesoreria/formas-pago-cliente-edition/formas-pago-cliente-edition.component";
import {MandatosEditionComponent} from "../../pages/tesoreria/mandatos-edition/mandatos-edition.component";
import {CuentaBancariaCliente} from "../tesoreria/cuentas-bancarias/cuenta-bancaria-cliente.model";

@BasicEntity({
    name: 'Clientes',
    endpoint: 'clientes',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'nombreCompletoCliente',
    acceptsBulkIriSearch: true,
    type: 'Cliente',
    managerCaching: ManagerCachingStrategy.NoCache
})

export class Cliente extends Persona {
    @BasicProperty({
        name: 'Razón Social',
        type: PropertyType.String(),
        sortable: true,
        nullable: true,
        order: 2,
        filters: [PartialSearchFilter]
    })
    private _denominacionSocial;

    @BasicProperty({
        name: '% Descuento',
        type: PropertyType.Integer(-100, 100),
        filters: [NumericFilter],
        sortable: true
    })
    private _descuento: number = 0;

    // @BasicProperty({
    //     name: 'Código cliente',
    //     type: PropertyType.Integer(),
    //     filters: [NumericFilter],
    //     sortable: true
    // })
    // private _codigo: number = 0;

    @BasicProperty({
        name: 'Baja',
        type: PropertyType.Boolean(),
        filters: [BooleanFilter],
        order: 11
    })
    private _baja = false;

    @BasicProperty({
        name: 'Empresa',
        type: PropertyType.Boolean(),
        filters: [BooleanFilter],
        order: 11
    })
    private _empresa: boolean = false;

    @BasicProperty({
        name: 'Intracomunitaria',
        type: PropertyType.Boolean(),
    })
    private _intracomunitaria: boolean = false;

    @BasicProperty({
        name: 'Contactos',
        type: PropertyType.NestedModel(PersonaContacto, undefined, NestedBehavior.CreateOnly),
        array: true
    })
    private _contactos;

    @BasicProperty({
        name: 'Descuentos',
        tag: 'Descuentos',
        type: PropertyType.NestedModel(DescuentoCliente, undefined, NestedBehavior.CreateOnly, [IRI_PROPERTY, TYPE_PROPERTY, 'cliente']),
        array: true,
        order: 11
    })
    private _descuentos;

    @BasicProperty({
        name: 'Comercial asociado',
        tag: 'Comercial asociado',
        type: PropertyType.NestedModel(Comercial, 'clientes', NestedBehavior.SendIriOnly),
        nullable: true,
        filters: [ExactSearchFilter],
        order: 10
    })
    private _comercial;

    @BasicProperty({
        name: 'Dirección de facturación',
        tag: 'Direcciones',
        type: PropertyType.NestedModel(DireccionPersona, 'uriPersona', NestedBehavior.CreateOnly, [IRI_PROPERTY, TYPE_PROPERTY, 'personaId', 'tipoDireccion'], DireccionesEditionComponent),
        dontReadFromApi: true,
        nullable: true,
        order: 2
    })
    private _direccionFacturacion;

    @BasicProperty({
        name: 'Dirección de envío',
        tag: 'Direcciones',
        type: PropertyType.NestedModel(DireccionPersona, 'uriPersona', NestedBehavior.CreateOnly, [IRI_PROPERTY, TYPE_PROPERTY, 'personaId', 'tipoDireccion'], DireccionesEditionComponent),
        dontReadFromApi: true,
        nullable: true,
        order: 3
    })
    private _direccionEnvio;


    @BasicProperty({
        name: 'Formas de pago',
        tag: 'Facturación',
        type: PropertyType.NestedModel(FormaPagoCliente, null, NestedBehavior.CreateOnly, [TYPE_PROPERTY, IRI_PROPERTY, 'cliente', 'fechaCreacion', 'creadoPor', 'modificadoPor', 'version', 'fechaModificacion', 'bloqueadoPor'], FormasPagoClienteEditionComponent),
        array: true,
        nullable: true,
        order: 14
    })
    private _formasPagoDisponibles;


    @BasicProperty({
        name: 'Forma de pago or defecto',
        type: PropertyType.NestedModel(FormaPagoCliente, 'cliente', NestedBehavior.SendIriOnly),
        nullable: true,
        readWrite: ReadWrite.ReadOnly,
        order: 14
    })
    private _formaPagoClientePorDefecto;

    @BasicProperty({
        name: 'Cuentas bancarias',
        tag: 'Facturación',
        type: PropertyType.NestedModel(CuentaBancariaCliente, 'cliente', NestedBehavior.CreateOnly, [TYPE_PROPERTY, IRI_PROPERTY, 'cliente', 'fechaCreacion', 'creadoPor', 'modificadoPor', 'version', 'fechaModificacion', 'bloqueadoPor']),
        array: true,
        nullable: true,
        order: 12
    })
    private _cuentasBancariasCliente;


    @BasicProperty({
        name: 'Mandato',
        tag: 'Facturación',
        type: PropertyType.NestedModel(Mandato, null, NestedBehavior.CreateOnly, [TYPE_PROPERTY, IRI_PROPERTY, 'cliente', 'fechaCreacion', 'creadoPor', 'modificadoPor', 'version', 'fechaModificacion', 'bloqueadoPor'], MandatosEditionComponent),
        nullable: true,
        readWrite: ReadWrite.ReadOnly,
        order: 13
    })
    private _mandato;


    @BasicProperty({
        name: 'Día del mes para realizar el cargo',
        tag: 'Facturación',
        type: PropertyType.Integer(),
        order: 10
    })
    private _diaMesParaCargo = 1;


    @BasicProperty({
        name: 'Quiere factura detallada',
        tag: 'Facturación',
        type: PropertyType.Boolean(),
        order: 10
    })
    private _facturaDetallada: boolean = false;

    @BasicProperty({
        name: 'Razón Social / Nombre',
        type: PropertyType.String(),
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly
    })
    get nombreCompletoCliente(): string {
        if (this.nombreCompleto) {
            return this.nombreCompleto;
        }
        return this.empresa ? this.denominacionSocial : `${this.nombre} ${(this.apellido1 ? this.apellido1 : '')}  ${(this.apellido2 ? this.apellido2 : '')}`
    }

    get denominacionSocial() {
        return this._denominacionSocial;
    }

    set denominacionSocial(value) {
        this._denominacionSocial = value;
    }

    get descuento(): number {
        return this._descuento;
    }

    set descuento(value: number) {
        this._descuento = value;
    }

    get intracomunitaria(): boolean {
        return this._intracomunitaria;
    }

    set intracomunitaria(value: boolean) {
        this._intracomunitaria = value;
    }


    get empresa(): boolean {
        return this._empresa;
    }

    set empresa(value: boolean) {
        this._empresa = value;
    }

    get contactos() {
        return this._contactos;
    }

    set contactos(value) {
        this._contactos = value;
    }

    get datosCliente(): string {
        let segundaLinea = '';
        if (this.email) {
            segundaLinea = `${this.email}`;
        }
        return segundaLinea;
    }

    get matIcon(): string {
        if (this.empresa) {
            return 'business';
        }
        return 'person';
    }

    get matIconColor(): string {
        if (this.usuario) {
            if (this.usuario.enabled) {
                return 'primary';
            }
            return 'black';
        }
    }

    get descuentos() {
        return this._descuentos;
    }

    set descuentos(value) {
        this._descuentos = value;
    }

    get comercial() {
        return this._comercial;
    }

    set comercial(value) {
        this._comercial = value;
    }

    get direccionEnvio() {
        if (this.direcciones === undefined) {
            return [];
        }
        return this.direcciones.find(d => d && d.tipoDireccion === DireccionPersona.TIPOS_DIRECCION.ENVIO);
    }

    set direccionEnvio(value) {
        this._direccionEnvio = value;
        if (value) {
            if (this.direcciones.length === 0) {
                value.tipoDireccion = DireccionPersona.TIPOS_DIRECCION.ENVIO;
                this.direcciones.push(value)
            } else if (this.direcciones.length === 1 && this.direcciones[0].tipoDireccion === DireccionPersona.TIPOS_DIRECCION.ENVIO) {
                this.direcciones[0].calle = value.calle;
                this.direcciones[0].demarcacion = value.demarcacion;
                this.direcciones[0].poblacion = value.poblacion;
                this.direcciones[0].pais = value.pais;
                this.direcciones[0].cp = value.cp;
                this.direcciones[0].tipoDireccion = DireccionPersona.TIPOS_DIRECCION.ENVIO;
            } else if (this.direcciones.length === 1 && this.direcciones[0].tipoDireccion !== DireccionPersona.TIPOS_DIRECCION.ENVIO) {
                value.tipoDireccion = DireccionPersona.TIPOS_DIRECCION.ENVIO;
                this.direcciones.push(value)
            } else {
                const pos = this.direcciones[0].tipoDireccion === DireccionPersona.TIPOS_DIRECCION.ENVIO ? 0 : 1;
                this.direcciones[pos].calle = value.calle;
                this.direcciones[pos].demarcacion = value.demarcacion;
                this.direcciones[pos].poblacion = value.poblacion;
                this.direcciones[pos].pais = value.pais;
                this.direcciones[pos].cp = value.cp;
                this.direcciones[pos].tipoDireccion = DireccionPersona.TIPOS_DIRECCION.ENVIO;
            }
        } else {
            this.direcciones = this.direcciones.filter(d => d.tipoDireccion !== DireccionPersona.TIPOS_DIRECCION.ENVIO);
        }
    }

    get direccionFacturacion() {
        return this.direcciones ? this.direcciones.find(d => d && d.tipoDireccion === DireccionPersona.TIPOS_DIRECCION.FACTURACION) : null;
    }

    set direccionFacturacion(value) {
        this._direccionFacturacion = value;
        if (value) {
            if (this.direcciones.length === 0) {
                value.tipoDireccion = DireccionPersona.TIPOS_DIRECCION.FACTURACION;
                this.direcciones.push(value)
            } else if (this.direcciones.length === 1 && this.direcciones[0].tipoDireccion === DireccionPersona.TIPOS_DIRECCION.FACTURACION) {
                this.direcciones[0].calle = value.calle;
                this.direcciones[0].demarcacion = value.demarcacion;
                this.direcciones[0].poblacion = value.poblacion;
                this.direcciones[0].pais = value.pais;
                this.direcciones[0].cp = value.cp;
                this.direcciones[0].tipoDireccion = DireccionPersona.TIPOS_DIRECCION.FACTURACION;
            } else if (this.direcciones.length === 1 && this.direcciones[0].tipoDireccion !== DireccionPersona.TIPOS_DIRECCION.FACTURACION) {
                value.tipoDireccion = DireccionPersona.TIPOS_DIRECCION.FACTURACION;
                this.direcciones.push(value)
            } else {
                const pos = this.direcciones[0].tipoDireccion === DireccionPersona.TIPOS_DIRECCION.FACTURACION ? 0 : 1;
                this.direcciones[pos].calle = value.calle;
                this.direcciones[pos].demarcacion = value.demarcacion;
                this.direcciones[pos].poblacion = value.poblacion;
                this.direcciones[pos].pais = value.pais;
                this.direcciones[pos].cp = value.cp;
                this.direcciones[pos].tipoDireccion = DireccionPersona.TIPOS_DIRECCION.FACTURACION;
            }
        } else {
            this.direcciones = this.direcciones.filter(d => d.tipoDireccion !== DireccionPersona.TIPOS_DIRECCION.FACTURACION);
        }
    }

    get mandato() {
        return this._mandato;
    }

    set mandato(value) {
        this._mandato = value;
    }

    get formasPagoDisponibles() {
        return this._formasPagoDisponibles;
    }

    set formasPagoDisponibles(value) {
        this._formasPagoDisponibles = value;
    }

    get cuentasBancariasCliente() {
        return this._cuentasBancariasCliente;
    }

    set cuentasBancariasCliente(value) {
        this._cuentasBancariasCliente = value;
    }

    get formaPagoClientePorDefecto() {
        return this._formaPagoClientePorDefecto;
    }

    set formaPagoClientePorDefecto(value) {
        this._formaPagoClientePorDefecto = value;
    }

    get baja(): boolean {
        return this._baja;
    }

    set baja(value: boolean) {
        this._baja = value;
    }

    get diaMesParaCargo(): number {
        return this._diaMesParaCargo;
    }

    set diaMesParaCargo(value: number) {
        this._diaMesParaCargo = value;
    }

    get facturaDetallada(): boolean {
        return this._facturaDetallada;
    }

    set facturaDetallada(value: boolean) {
        this._facturaDetallada = value;
    }
}

DescuentoCliente.MAP_CLIENTE.type = PropertyType.Uri(Cliente);
Comercial.CLIENTE_MAP.type = PropertyType.Uri(Cliente);
FormaPagoCliente.MAP_CLIENTE.type = PropertyType.Uri(Cliente);
Mandato.MAP_CLIENTE.type = PropertyType.Uri(Cliente);
CuentaBancariaCliente.CLIENTE_MAP.type = PropertyType.Uri(Cliente);
