import {Component} from '@angular/core';
import {
    BasicEntityTableColumnDescriptionOrString,
    ColumnJustification
} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Cliente} from "../../../model/personas/cliente.model";
import {ClientesEditionComponent} from "./clientes-edition/clientes-edition.component";
import {Pedido} from "../../../model/pedidos/pedido.model";
import {Usuario} from "../../../model/personas/usuario.model";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {UsuarioService} from "../../../services/usuario.service";
import {BehaviorSubject} from "rxjs";
import {Incidencia} from "../../../model/incidencias/incidencia.model";
import {SimpleDialogService} from "../../../basic-entity-front/dialog-shell/simple-dialog.service";

@Component({
    selector: 'app-clientes',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model" [editionDialog]="dialog" [rowClass]="clase" [reloadDataSource]="reload" id="clientes"></be-table>',
    styles: [
        `::ng-deep.baja {
            background-color: rgba(248, 134, 134, 0.46) !important;
        }`
    ]
})
export class ClientesComponent {
    public model = Cliente;
    public dialog = ClientesEditionComponent;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        {key: 'id', hiddenDisplayable: true},
        // {key: 'codigo', weight: 1, hiddenInEdition: true},
        {key: 'baja', weight: 1},
        {key: 'empresa', weight: 1},
        {key: 'fechaCreacion', hiddenDisplayable: true, weight: 2},
        {key: 'fechaModificacion', weight: 2},
        {key: 'nombreCompletoCliente', hiddenInEdition: true, weight: 4},
        {key: 'nombre', hiddenDisplayable: true, weight: 2},
        {key: 'apellido1', hiddenDisplayable: true, weight: 2},
        {key: 'apellido2', hiddenDisplayable: true, weight: 2},
        {key: 'denominacionSocial', weight: 2},
        {key: 'cifNif', hiddenDisplayable: true, weight: 2},
        {key: 'email', weight: 4},
        {key: 'comercial', weight: 1},
        {key: 'idioma', weight: 1, justification: ColumnJustification.Center},
        {key: 'direccionEnvio', hiddenDisplayable: true},
        {key: 'direccionFacturacion', hiddenDisplayable: true},
        {key: 'telefonos', hiddenDisplayable: true},
        {key: 'descuentos', hiddenDisplayable: true},
        {key: 'diaMesParaCargo', weight: 1, hidden: true},
        {key: 'facturaDetallada', weight: 1, hidden: true},
        {key: 'formasPagoDisponibles', hidden: true, hiddenDisplayable: true},
        {key: 'cuentasBancariasCliente', hidden: true, hiddenDisplayable: true},
        {key: 'mandato', hidden: true, hiddenDisplayable: true},
        {
            key: 'usuario',
            justification: ColumnJustification.Center,
            shownAsButton: true,
            buttonData: {
                text: this._getTextoActivado.bind(this),
                action: this._activarUsuario.bind(this),
                class: this._getClassActivado.bind(this),
                disabled: () => false
            },
            weight: 2
        },
    ];
    reload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private _usuarioService: UsuarioService, private _simpleDialog: SimpleDialogService) {
    }

    public readonly clase = [
        {
            class: this._getClassRow.bind(this)
        }
    ];

    _getClassRow(cliente: Cliente) {
        if (cliente.baja) {
            return 'baja';
        }
    }

    _getTextoActivado(usuario: Usuario) {
        return usuario.enabled ? 'Activado' : 'No Activado';
    }

    _getClassActivado(usuario: Usuario) {
        return usuario.enabled ? 'activado' : 'no-activado';
    }

    _activarUsuario(usuario: Usuario) {
        this._simpleDialog.confirmationDialog('Activar cliente',
            `¿Seguro que desea activar el cliente <strong>${usuario.username}</strong>?`,
            ['Activar', 'check', 'primary'],
            'Cancelar',
            false,
            false,
            700
        )
            .then(ok => {
                if (ok) {
                    this._usuarioService.activarUsuario(usuario).subscribe(res => {
                        console.log(res)
                        this.reload.next(true)
                    })
                }
            });
    }
}
