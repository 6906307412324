import {Component, Inject, OnInit} from '@angular/core';
import {EditionDialogComponent} from "../../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BaseDialogData} from "../../../../basic-entity-front/basic-entity-table/base-dialog-data";
import {ApiModuleFactory} from "../../../../api/api-module-factory.service";
import {InterfaceProviderService} from "../../../../basic-entity-back/services/interface-provider.service";
import {UntypedFormBuilder} from "@angular/forms";
import {ErrorDisplayService} from "../../../../basic-entity-front/services/error-display.service";
import {SimpleDialogService} from "../../../../basic-entity-front/dialog-shell/simple-dialog.service";
import {ApiService} from "../../../../api/api.service";
import {TokenService} from "../../../../api/session/token.service";
import {SessionService} from "../../../../session/session.service";
import {DireccionPersona} from "../../../../model/personas/direccion-persona.model";

@Component({
    selector: 'app-direcciones-edition',
    templateUrl: './direcciones-edition.component.html',
    styleUrls: ['./direcciones-edition.component.scss']
})
export class DireccionesEditionComponent extends EditionDialogComponent<DireccionPersona> {

    constructor(@Inject(MAT_DIALOG_DATA) private data: BaseDialogData<DireccionPersona>, private dialogRef: MatDialogRef<EditionDialogComponent<DireccionPersona>>,
                apiFactory: ApiModuleFactory, public interfaceProvider: InterfaceProviderService, fb: UntypedFormBuilder, private _myErrorDisplay: ErrorDisplayService,
                private _simpleDialog: SimpleDialogService, private _api: ApiService, private _dialogService: MatDialog, private _tokenService: TokenService,
                private _sessionService: SessionService) {
        super(data, dialogRef, apiFactory, interfaceProvider, fb, _myErrorDisplay);
        data.shouldManageSaving = true;
    }

    ngOnInit() {
    }
}
