<mat-card [class.loading]="loading$|async">
    <div class="mat-title">
        Perfil
    </div>
    <div class="debug-info" *ngIf="debugMode">
        <table>
            <tr>
                <td>Logged:</td>
                <td>{{sessionService.isLogged() ? 'Yes' : 'No'}}</td>
            </tr>
            <ng-container *ngIf="sessionService.token">
                <tr>
                    <td>Token is valid:</td>
                    <td>{{sessionService.token.isValid() ? 'Yes' : 'No'}}</td>
                </tr>
                <tr>
                    <td>Token:</td>
                    <td>{{sessionService.token.token}}</td>
                </tr>
                <tr>
                    <td>Roles:</td>
                    <td>{{sessionService.token.roles.join(', ')}}</td>
                </tr>
                <tr>
                    <td>Token expiration:</td>
                    <td>{{sessionService.token.expiration.format('l')}}</td>
                </tr>
            </ng-container>
        </table>
    </div>
    <form [formGroup]="formGroup" (submit)="$event.preventDefault()">
        <mat-form-field appearance="outline" floatLabel="auto">
            <label>Username:</label>
            <input matInput formControlName="username">
            <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="auto">
            <label>Idioma:</label>
            <be-cached-input formControlName="idioma" [onlyUri]="true"
                             placeholder="Idioma..." [modelTypeOrParent]="idiomaModel"></be-cached-input>
            <mat-icon matSuffix>language</mat-icon>
        </mat-form-field>
        <ng-container *ngIf="cambiarPassword">
            <mat-form-field appearance="outline" floatLabel="auto">
                <label>Contraseña actual:</label>
                <input matInput type="password" placeholder="Contraseña actual..." formControlName="plainOldPassword">
                <mat-icon matSuffix>vpn_key</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="auto">
                <label>Nueva contraseña:</label>
                <input matInput type="password" placeholder="Nueva contraseña..." formControlName="password">
                <mat-icon matSuffix>vpn_key</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="auto">
                <label>Repetir contraseña:</label>
                <input matInput type="password" placeholder="Repetir contraseña..." formControlName="repeatPassword">
                <mat-icon matSuffix>vpn_key</mat-icon>
                <mat-error *ngIf="formGroup.get('repeatPassword').hasError('differentPasswords')">
                    Las contraseñas no coinciden.
                </mat-error>
            </mat-form-field>
        </ng-container>
        <button mat-stroked-button color="primary"
                (click)="cambiarPassword = true" *ngIf="!cambiarPassword">
            Cambiar contraseña
        </button>
        <button mat-stroked-button color="accent"
                (click)="cambiarPassword = false" *ngIf="cambiarPassword">
            Cancelar
        </button>
        <button mat-flat-button color="primary"
                *ngIf="(!cambiarPassword && hayCambios) || formGroup.get('repeatPassword').valid"
                (click)="guardar()">
            Guardar cambios
        </button>
    </form>
    <mat-spinner color="primary" [diameter]="160"></mat-spinner>
</mat-card>
