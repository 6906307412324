<div class="filtering" *ngFor="let filter of filters">
    <ng-container [ngSwitch]="filter.controller">
        <be-default-filtering *ngSwitchCase="0" [control]="filter.control" [property]="filter.property"
                              [filter]="filter.filter" [class]="readonly(filter) ? 'readonly' : '' ">
        </be-default-filtering>
        <be-range-filtering *ngSwitchCase="1" [formControl]="filter.control" [property]="filter.property"
                            [class]="readonly(filter) ? 'readonly' : '' ">
        </be-range-filtering>
        <be-exists-filtering *ngSwitchCase="2" [formControl]="filter.control" [property]="filter.property"
                             [class]="readonly(filter) ? 'readonly' : '' ">
        </be-exists-filtering>
        <be-date-filtering *ngSwitchCase="4" [formControl]="filter.control" [property]="filter.property"
                           [class]="readonly(filter) ? 'readonly' : '' ">
        </be-date-filtering>
    </ng-container>
</div>
