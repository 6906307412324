<div class="branding">
    <img src="assets/Logo_Blanco.svg" alt="" class="app-logo">
</div>
<span class="admin">PANEL ADMINISTRACIÓN</span>
<div class="app-user">
    <div class="app-user-photo">
        <img src="assets/images/face-7.jpg" class="mat-elevation-z1" alt="">
        <!--        <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>-->
    </div>
    <span class="app-user-name mb-8">
        <mat-icon class="icon-xs text-muted">lock</mat-icon>
        {{profileName}}
    </span>
    <!-- Small buttons -->
    <div class="app-user-controls">
        <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Settings"
                [matMenuTriggerFor]="appUserMenu">
            <mat-icon>settings</mat-icon>
        </button>
        <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Inbox"
                routerLink="/inbox">
            <mat-icon>email</mat-icon>
        </button>
        <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Cerrar sesión"
                (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
        </button>
        <mat-menu #appUserMenu="matMenu">
            <button mat-menu-item routerLink="/profile/overview">
                <mat-icon>account_box</mat-icon>
                <span>Profile</span>
            </button>
            <button mat-menu-item routerLink="/profile/settings">
                <mat-icon>settings</mat-icon>
                <span>Account Settings</span>
            </button>
            <button mat-menu-item routerLink="/calendar">
                <mat-icon>date_range</mat-icon>
                <span>Calendar</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Sign out</span>
            </button>
        </mat-menu>
    </div>
</div>



