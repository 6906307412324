import {Component, Inject} from '@angular/core';
import {EditionDialogComponent} from "../../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {Cliente} from "../../../../model/personas/cliente.model";
import {
    InternalPropertyMap,
    IRI_PROPERTY,
    TYPE_PROPERTY
} from "../../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {Usuario} from "../../../../model/personas/usuario.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BaseDialogData} from "../../../../basic-entity-front/basic-entity-table/base-dialog-data";
import {ApiModuleFactory} from "../../../../api/api-module-factory.service";
import {InterfaceProviderService} from "../../../../basic-entity-back/services/interface-provider.service";
import {UntypedFormBuilder} from "@angular/forms";
import {ErrorDisplayService} from "../../../../basic-entity-front/services/error-display.service";
import {SimpleDialogService} from "../../../../basic-entity-front/dialog-shell/simple-dialog.service";
import {filter, map} from "rxjs/operators";
import {ApiService} from "../../../../api/api.service";
import {DescuentoCliente} from "../../../../model/trabajos/descuento-cliente.model";
import {BaseDialog} from "../../../../basic-entity-front/basic-entity-table/base-dialog";
import {NestedBehavior, NestedModelTypeInterface} from "../../../../basic-entity-back/property-type/nested-model-type";
import {PropertyType} from "../../../../basic-entity-back/property-type/property-type";
import {TokenService} from "../../../../api/session/token.service";
import {ApiInterface} from "../../../../api/api-interface";
import {SessionService} from "../../../../session/session.service";
import {DireccionPersona} from "../../../../model/personas/direccion-persona.model";
import {Mandato} from "../../../../model/tesoreria/mandatos/mandato.model";
import {MandatosEditionComponent} from "../../../tesoreria/mandatos-edition/mandatos-edition.component";
import {FilterAndData} from "../../../../api/filter-list";
import {ExactSearchFilter} from "../../../../basic-entity-back/filters/search-filter";
import {FormaPagoCliente} from "../../../../model/tesoreria/formas-pago/forma-pago-cliente.model";

@Component({
    selector: 'app-clientes-edition',
    templateUrl: './clientes-edition.component.html',
    styleUrls: ['./clientes-edition.component.scss']
})
export class ClientesEditionComponent extends EditionDialogComponent<Cliente> {
    dataSource: any[];
    columns;
    nombreORazonSocialValidos = true;
    private static readonly _dictionary = "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz23456789@#!?+&%$*";
    clienteFilter: any[];

    public get cliente(): Cliente {
        return this.model as Cliente;
    }

    public get fechaCreacion(): string {
        return this.interfaceProvider
            .interfaceForModel(Cliente).serialiser
            .getValueAsString(this.model, 'fechaCreacion');
    }

    public get fechaModificacion(): string {
        return this.interfaceProvider
            .interfaceForModel(Cliente).serialiser
            .getValueAsString(this.model, 'fechaModificacion');
    }

    constructor(@Inject(MAT_DIALOG_DATA) private data: BaseDialogData<Cliente>, dialogRef: MatDialogRef<EditionDialogComponent<Cliente>>,
                apiFactory: ApiModuleFactory, public interfaceProvider: InterfaceProviderService, fb: UntypedFormBuilder, private _myErrorDisplay: ErrorDisplayService,
                private _simpleDialog: SimpleDialogService, private _api: ApiService, private _dialogService: MatDialog, private _tokenService: TokenService,
                private _sessionService: SessionService) {
        super(data, dialogRef, apiFactory, interfaceProvider, fb, _myErrorDisplay);
        this.columns = ['metodoFabricacion', 'material', 'trabajo', 'subTrabajo', 'tipoMaterial', 'opcion', 'importeTarifa', 'descuento', 'importeFinal', 'delete'];

        this._loadDataSourceDescuentos();

        if (this._tokenService.token.roles.includes(ApiInterface.ROLES.COMERCIAL)) {
            this.formGroup.get('comercial').setValue(_sessionService.profile);
        }

        this.formGroup.valueChanges.subscribe(res => {
            this.nombreORazonSocialValidos = res.nombre !== '' || res.denominacionSocial !== '';
        });

        const clienteProp = this.interfaceProvider.interfaceForModel(FormaPagoCliente).mappingModelToApi['cliente'];
        this.clienteFilter = [new FilterAndData(ExactSearchFilter, this.data.model, clienteProp)];
    }

    public prop(key: string): InternalPropertyMap | undefined {
        return this.properties.find(p => p.modelKey === key);
    }

    ngOnInit() {
        this.formGroup.get('cuentasBancariasCliente').valueChanges.subscribe(res => {
            this.save();
        });
        this.formGroup.get('formasPagoDisponibles').valueChanges.subscribe(res => {
            console.log(res)
            this.save();
        });

    }

    public crearUsuario() {
        const usuario = new Usuario();
        usuario.username = this.formGroup.get('email').value;
        usuario.enabled = true;
        usuario.plainPassword = this._generateRandomPassword(8);
        this.formGroup.get('usuario').setValue(usuario);
        this._simpleDialog.simpleDialog('Contraseña generada', `La contraseña generada es: <strong>${usuario.plainPassword}</strong>`,
            [{text: 'Aceptar', value: null}]);
    }

    public activarUsuario() {
        const email = this.formGroup.get('email').value;
        this._simpleDialog.simpleDialog(
            'Confirmar datos',
            `Para activar el usuario, por favor confirma con el cliente que el correo es válido:<br><strong>${email}</strong>`,
            [
                {text: 'Cancelar', color: "accent", value: false},
                {text: 'Confirmar', color: "primary", value: true, icon: 'done'}
            ]
        ).afterClosed().pipe(filter(v => v)).subscribe(() => {
            const serialiser = this.interfaceProvider.interfaceForModel(Usuario).serialiser;
            this._api
                .put(`${this.model.usuario.iri}/force_activation`, {})
                .pipe(
                    ApiService.sTakeBody(),
                    map(obj => serialiser.fromApi(obj))
                )
                .subscribe((receivedUser) => {
                        serialiser.copyInto(this.model.usuario, receivedUser);
                        this.formManager.setValue(this.prop('usuario'), this.model.usuario);
                    },
                    err => this._myErrorDisplay.displayError(err),
                );
        });
    }

    public borrarUsuario() {
        this.formGroup.get('usuario').setValue(null);
    }

    private _generateRandomPassword(length: number) {
        return Array.apply(null, {length: length})
            .map(() => {
                const randomIndex =
                    ClientesEditionComponent._getRandomByte() %
                    ClientesEditionComponent._dictionary.length;
                return ClientesEditionComponent._dictionary[randomIndex];
            }).join('');
    }

    private static _getRandomByte() {
        // http://caniuse.com/#feat=getrandomvalues
        if (window.crypto && window.crypto.getRandomValues) {
            const resultado = new Uint8Array(1);
            window.crypto.getRandomValues(resultado);
            return resultado[0];
        } else if ((window as any).msCrypto && (window as any).msCrypto.getRandomValues) {
            const resultado = new Uint8Array(1);
            (window as any).msCrypto.getRandomValues(resultado);
            return resultado[0];
        } else {
            return Math.floor(Math.random() * 256);
        }
    }

    _loadDataSourceDescuentos() {
        if (this.cliente.isTemporalEntity) {
            return;
        }
        this.interfaceProvider.managerForModel(Cliente).loader.findById(this.cliente.id).subscribe(cliente => {
            this.dataSource = cliente.descuentos
        });
    }

    anadirItem() {
        const manager = this.interfaceProvider.managerForModel(DescuentoCliente);
        const intrf = manager.loader.entityInterface;
        const newDescuento = intrf.serialiser.getEmptyModel();
        newDescuento.cliente = this.model.iri;
        const ref = this._dialogService.open<BaseDialog<DescuentoCliente>, BaseDialogData<DescuentoCliente>, any>(EditionDialogComponent, {
            disableClose: true,
            autoFocus: true,
            restoreFocus: true,
            data: {
                model: newDescuento,
                allowIdEdition: !intrf.autogeneratedId,
                columns: Object.values(intrf.mappingModelToApi).filter(p => ![IRI_PROPERTY, TYPE_PROPERTY, 'cliente'].includes(p.modelKey)),
                showSaveAndCancelButtons: !intrf.readonlyInterface,
                isNew: true,
                shouldManageSaving: true
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this._loadDataSourceDescuentos();
            }
        });
    }


    editarItem(item) {
        const transferIntf = this.interfaceProvider.interfaceForModel(DescuentoCliente);
        const fakeType: NestedModelTypeInterface<DescuentoCliente> =
            PropertyType.NestedModel(DescuentoCliente, 'cliente', NestedBehavior.CreateOnly, [IRI_PROPERTY, TYPE_PROPERTY, 'cliente']).asNestedModel();
        const dialog = this._dialogService.open(
            EditionDialogComponent,
            {
                data: fakeType.baseDialogDataForModel(
                    transferIntf,
                    item,
                    false,
                    true,
                    false
                )
            }
        );
        dialog.afterClosed().subscribe(result => {
            if (result === BaseDialog.RESULT_SHOULD_RELOAD) {
                this._loadDataSourceDescuentos();
            }
        });
    }

    eliminarItem(element) {
        this._simpleDialog
            .confirmationDialog(
                'Eliminar',
                `¿Seguro que deseas eliminar el registro?`,
                ['Eliminar', 'delete_forever', 'warn']
            )
            .then(del => {
                if (del) {
                    this.interfaceProvider.managerForModel(DescuentoCliente).remove(element).subscribe(res => {
                        this._loadDataSourceDescuentos();
                    });
                }
            });
    }

    direccionesIguales() {
        const dirFact = this.formGroup.get('direccionFacturacion');
        const dirEnvio = this.formGroup.get('direccionEnvio');
        if (dirEnvio.value) {
            const fact = new DireccionPersona();
            fact.calle = dirEnvio.value.calle;
            fact.demarcacion = dirEnvio.value.demarcacion;
            fact.poblacion = dirEnvio.value.poblacion;
            fact.cp = dirEnvio.value.cp;
            fact.pais = dirEnvio.value.pais;
            dirFact.setValue(fact);
        } else if (dirFact.value) {
            const env = new DireccionPersona();
            env.calle = dirFact.value.calle;
            env.demarcacion = dirFact.value.demarcacion;
            env.poblacion = dirFact.value.poblacion;
            env.cp = dirFact.value.cp;
            env.pais = dirFact.value.pais;
            dirEnvio.setValue(env);
        }
    }

    mostrarBotonDireccionesIguales() {
        const dirFact = this.formGroup.get('direccionFacturacion').value;
        const dirEnvio = this.formGroup.get('direccionEnvio').value;
        return dirFact || dirEnvio;
    }

    generarMandato() {
        if (!this.data.model.mandato || this.data.model.mandato.isTemporalEntity) {
            const manager = this.interfaceProvider.managerForModel(Mandato);
            const intrf = manager.loader.entityInterface;
            const newMandato = intrf.serialiser.getEmptyModel();
            newMandato.cliente = this.model.iri;
            const ref = this._dialogService.open<BaseDialog<Mandato>, BaseDialogData<Mandato>, any>(MandatosEditionComponent, {
                disableClose: true,
                autoFocus: true,
                restoreFocus: true,
                data: {
                    model: newMandato,
                    allowIdEdition: !intrf.autogeneratedId,
                    columns: Object.values(intrf.mappingModelToApi).filter(p => ![IRI_PROPERTY, TYPE_PROPERTY, 'cliente'].includes(p.modelKey)),
                    showSaveAndCancelButtons: !intrf.readonlyInterface,
                    isNew: true,
                    shouldManageSaving: true
                },
                width: '800px'
            });
            ref.afterClosed().subscribe(result => {
                if (result) {
                    console.log(newMandato)
                    this.model.mandato = newMandato;
                }
            });
        } else {
            const transferIntf = this.interfaceProvider.interfaceForModel(Mandato);
            const fakeType: NestedModelTypeInterface<Mandato> =
                PropertyType.NestedModel(Mandato, 'cliente', NestedBehavior.CreateOnly, [IRI_PROPERTY, TYPE_PROPERTY, 'cliente']).asNestedModel();
            this.data.model.mandato.cliente = this.model.iri;
            const dialog = this._dialogService.open(
                MandatosEditionComponent,
                {
                    data: fakeType.baseDialogDataForModel(
                        transferIntf,
                        this.data.model.mandato,
                        false,
                        true,
                        false
                    )
                }
            );
            dialog.afterClosed().subscribe(result => {

                console.log(result)
            });
        }
    }
}
