import {Component, Input, OnInit} from '@angular/core';
import {DireccionPersona} from "../../../../../model/personas/direccion-persona.model";
import {MatDialog} from "@angular/material/dialog";
import {InterfaceProviderService} from "../../../../../basic-entity-back/services/interface-provider.service";
import {BaseDialog} from "../../../../../basic-entity-front/basic-entity-table/base-dialog";
import {BaseDialogData} from "../../../../../basic-entity-front/basic-entity-table/base-dialog-data";
import {EditionDialogComponent} from "../../../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {NestedBehavior, NestedModelTypeInterface} from "../../../../../basic-entity-back/property-type/nested-model-type";
import {PropertyType} from "../../../../../basic-entity-back/property-type/property-type";
import {BasicEntityInputComponent} from "../../../../../basic-entity-front/basic-entity-input/basic-entity-input.component";

@Component({
    selector: 'app-proveedor-direcciones',
    templateUrl: './proveedor-direcciones.component.html',
    styleUrls: ['./proveedor-direcciones.component.scss']
})
export class ProveedorDireccionesComponent {

    @Input() formGroup: UntypedFormGroup;

    constructor(private _dialogService: MatDialog, private _intf: InterfaceProviderService) {
    }

    addEdit(direccion?: DireccionPersona, index?: number) {
        const direccionIntf = this._intf.interfaceForModel(DireccionPersona);
        const fakeType: NestedModelTypeInterface<DireccionPersona> = PropertyType.NestedModel(DireccionPersona, 'uriPersona', NestedBehavior.CreateOnly).asNestedModel();
        const nuevaDireccion = direccionIntf.serialiser.getEmptyModel();
        this._dialogService.open<BaseDialog<DireccionPersona>, BaseDialogData<DireccionPersona>, any>(EditionDialogComponent, {
            disableClose: true,
            autoFocus: true,
            restoreFocus: true,
            data: fakeType.baseDialogDataForModel(
                direccionIntf,
                direccion ?? nuevaDireccion,
                !direccion,
                true,
                false
            )
        }).afterClosed().subscribe(result => {
            if (result === EditionDialogComponent.RESULT_SHOULD_RELOAD) {
                const arr = {};
                if(direccion) {
                    arr[BasicEntityInputComponent.UNIQUE_CONTROL_NAME] = direccion;
                    this.direcciones.at(index).setValue(arr);
                } else {
                    arr[BasicEntityInputComponent.UNIQUE_CONTROL_NAME] = nuevaDireccion;
                    this.direcciones.push(new UntypedFormControl(arr));
                }
            }
        });
    }

    get direcciones() {
        return this.formGroup.get('direcciones') as UntypedFormArray;
    }

    delete(dir: DireccionPersona, i: number) {
        this.direcciones.removeAt(i);
    }

    get direccionesArray() {
        return this.direcciones.value.map(v => v[BasicEntityInputComponent.UNIQUE_CONTROL_NAME]) as DireccionPersona[];
    }
}
