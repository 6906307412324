import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Pais} from "./pais.model";
import {Uri} from "../../api/uri";
import {Comunidad} from "./comunidad.model";
import {Zona} from "./zona.model";
import {ManagerCachingStrategy, ReadWrite, SearchType} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Festivo} from "./festivo.model";

@BasicEntity({
    name: 'Provincias',
    endpoint: 'provincias',
    isPaginated: true,
    paginationSizes: [20, 40],
    autogeneratedId: false,
    modelNameProperty: 'nombre',
    acceptsBulkIriSearch: true,
    type: 'Provincia',
    managerCaching: ManagerCachingStrategy.PrecacheOnStart
})
export class Provincia extends Resource {
    @BasicProperty({
        name: 'Código',
        isId: true,
        searchable: SearchType.Exact,
        sortable: true,
        type: PropertyType.String()
    })
    private _codigoProvincia: string;

    @BasicProperty({
        name: 'País',
        isId: true,
        searchable: SearchType.Exact,
        sortable: true,
        type: PropertyType.Uri(Pais)
    })
    private _pais: Uri;

    @BasicProperty({
        name: 'Comunidad',
        nullable: true,
        searchable: SearchType.Exact,
        sortable: true,
        type: PropertyType.Uri(Comunidad)
    })
    private _comunidad: Uri;

    @BasicProperty({
        keyInApi: 'nombreProvincia',
        name: 'Nombre',
        type: PropertyType.String()
    })
    private _nombre: string;

    @BasicProperty({
        name: 'Código postal',
        searchable: SearchType.Exact,
        sortable: true,
        nullable: true,
        type: PropertyType.String()
    })
    private _cp: string;

    @BasicProperty({
        name: 'Prefijo',
        searchable: SearchType.Start,
        sortable: true,
        nullable: true,
        type: PropertyType.String()
    })
    private _prefijo: string;

    @BasicProperty({
        name: 'Zona propia',
        nullable: true,
        type: PropertyType.Uri(Zona)
    })
    private _zonaPropia: Uri;

    @BasicProperty({
        name: 'Zona',
        nullable: true,
        readWrite: ReadWrite.ReadOnly,
        type: PropertyType.Uri(Zona),
        sortable: true,
    })
    private _zona;

    @BasicProperty({
        tag: 'Festivos',
        name: 'Festivos propios',
        type: PropertyType.NestedModel(Festivo),
        array: true
    })
    private _festivosPropios: object = [];

    @BasicProperty({
        tag: 'Festivos',
        name: 'Festivos heredados',
        type: PropertyType.NestedModel(Festivo),
        readWrite: ReadWrite.ReadOnly,
        array: true
    })
    private _festivosHeredados: object = [];

    get pais(): Uri {
        return this._pais;
    }

    set pais(value: Uri) {
        this._pais = value;
    }

    get codigoProvincia(): string {
        return this._codigoProvincia;
    }

    set codigoProvincia(value: string) {
        this._codigoProvincia = value;
    }

    get comunidad(): Uri {
        return this._comunidad;
    }

    set comunidad(value: Uri) {
        this._comunidad = value;
    }

    get nombre(): string {
        return this._nombre;
    }

    set nombre(value: string) {
        this._nombre = value;
    }

    get cp(): string {
        return this._cp;
    }

    set cp(value: string) {
        this._cp = value;
    }

    get prefijo(): string {
        return this._prefijo;
    }

    set prefijo(value: string) {
        this._prefijo = value;
    }

    get zonaPropia(): Uri {
        return this._zonaPropia;
    }

    set zonaPropia(value: Uri) {
        this._zonaPropia = value;
    }

    get zona() {
        return this._zona;
    }

    set zona(value) {
        this._zona = value;
    }

    get festivosPropios(): object {
        return this._festivosPropios;
    }

    set festivosPropios(value: object) {
        this._festivosPropios = value;
    }

    get festivosHeredados(): object {
        return this._festivosHeredados;
    }

    set festivosHeredados(value: object) {
        this._festivosHeredados = value;
    }
}
