import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {LineaPedidoOpcion} from "../pedidos/linea-pedido-opcion.model";
import {LineaPedido} from "../pedidos/linea-pedido.model";
import {TarifaOpcion} from "./tarifa-opcion.model";

@BasicEntity({
    name: "Opción",
    endpoint: "opcions",
    isPaginated: false,
    type: "Opcion",
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNameProperty: 'descripcion'
})
export class Opcion extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
        sortable: true
    })
    private _id: number;

    @BasicProperty({
        name: 'Descripción',
        type: PropertyType.String()
    })
    private _descripcion: string;

    @BasicProperty({
        name: 'Unitaria',
        type: PropertyType.Boolean()
    })
    private _unitaria: boolean = false;

    @BasicProperty({
        name: 'Excluyente',
        type: PropertyType.Boolean()
    })
    private _excluyente: boolean;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get descripcion(): string {
        return this._descripcion;
    }

    set descripcion(value: string) {
        this._descripcion = value;
    }

    get unitaria(): boolean {
        return this._unitaria;
    }

    set unitaria(value: boolean) {
        this._unitaria = value;
    }

    get excluyente(): boolean {
        return this._excluyente;
    }

    set excluyente(value: boolean) {
        this._excluyente = value;
    }
}
