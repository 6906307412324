import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DeviceDetectorService {
    private isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 900px)')
        .pipe(map(result => result.matches));

    constructor(public breakpointObserver: BreakpointObserver) {
    }

    public isHandset(): Observable<boolean> {
        return this.isHandset$;
    }
}
