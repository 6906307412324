<be-dialog-shell [title]="title" (close)="closeDialog()" [closeButton]="!saving" [showFooter]="showSaveAndCancelButtons"
                 [className]="tags.size > 1 ? 'fixed-size' : null">
    <form autocomplete="off" (submit)="$event.preventDefault();" [formGroup]="formGroup">

        <!--        <be-edition-row [class]="'dialog-row cien'"-->
        <!--                        [property]="prop('cliente')" [formGroup]="formGroup"-->
        <!--                        [formManager]="formManager">-->
        <!--        </be-edition-row>-->

        <be-edition-row [class]="'dialog-row cien'"
                        [property]="prop('descripcion')" [formGroup]="formGroup"
                        [formManager]="formManager">
        </be-edition-row>

        <be-edition-row [class]="'dialog-row cien'"
                        [property]="prop('formaPago')" [formGroup]="formGroup"
                        [formManager]="formManager">
        </be-edition-row>

        <be-edition-row [class]="'dialog-row cien'"
                        [property]="prop('cuentaBancariaCliente')" [formGroup]="formGroup"
                        [formManager]="formManager" [filters]="filtrosCuentaBancaria"
                        [selectedValue]="cuentaBancaria" [autoSelectFirst]="false">
        </be-edition-row>

        <be-edition-row [class]="'dialog-row cien'"
                        [property]="prop('porDefecto')" [formGroup]="formGroup"
                        [formManager]="formManager">
        </be-edition-row>

        <be-edition-row [class]="'dialog-row cien'"
                        [property]="prop('vigente')" [formGroup]="formGroup"
                        [formManager]="formManager">
        </be-edition-row>
    </form>
    <dialog-actions>
        <button mat-button (click)="cancel()" [disabled]="saving">Cancelar</button>
        <button mat-flat-button *ngIf="modified && !cargando" (click)="aceptar()" color="primary"
                [disabled]="!formGroup.valid || saving">
            <mat-icon>save</mat-icon>
            Guardar
        </button>
        <button mat-button *ngIf="!(modified)" (click)="aceptar()"
                [disabled]="modified && !formGroup.valid">Aceptar
        </button>
    </dialog-actions>
</be-dialog-shell>
