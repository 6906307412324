import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from "./session.service";

@Injectable({
    providedIn: 'root'
})
export class SessionGuard implements CanActivate {
    constructor(private _router: Router, private _sessionService: SessionService, private route: ActivatedRoute) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this._sessionService.isLogged()) {
            if (!next.data.expectedRole || this._sessionService.hasRole(next.data.expectedRole)) {
                return true;
            }
        }
        this._router.navigateByUrl('/login', {}).then();
        return false;
    }
}
