import {InterfaceProviderService} from "../services/interface-provider.service";
import {Caster} from "../casters/caster";
import {TypeStr} from "../property-type/type-str";
import {NumberCaster} from "../casters/number.caster";
import {MomentCaster} from "../casters/moment-caster";
import {DecimalCaster} from "../casters/decimal-caster";
import {UriCaster} from "../casters/uri-caster";
import {NestedModelCaster} from "../casters/nested-model-caster";
import {ObjectCaster} from "../casters/object-caster";
import {NestedModelStringCaster} from "../casters/nested-model-string-caster";
import {InternalObjectPropertyMap} from "./mapping-internal";

export abstract class CasterFactory {
    public static sDefaultCaster(interfaceProvider: InterfaceProviderService, property: InternalObjectPropertyMap): Caster<any, any> | null {
        switch (property.type.toString()) {
            case TypeStr.Integer:
            case TypeStr.Float:
                return new NumberCaster();
            case TypeStr.Date:
            case TypeStr.Time:
            case TypeStr.DateTime:
                return new MomentCaster();
            case TypeStr.Decimal:
                return new DecimalCaster(',');
            case TypeStr.Uri:
                return new UriCaster('property ' + property.modelKey, interfaceProvider.allAcceptedInterfaces(property.type.asUri().modelTypeOrParent));
            case TypeStr.NestedModel:
                return new NestedModelCaster(interfaceProvider.interfaceForModel(property.type.asNestedModel().modelType), property.type.asNestedModel().behavior);
            case TypeStr.Object:
                return property.type.asObject().objectModel ? new ObjectCaster(property.type.asObject().objectModel, interfaceProvider.getObjectProperties(property.type.asObject().objectModel)) : null;
        }
        return null;
    }

    public static sStringCaster(interfaceProvider: InterfaceProviderService,
                                property: InternalObjectPropertyMap): Caster<any, string> | null {
        switch (property.type.toString()) {
            case TypeStr.Uri:
                return new UriCaster('property ' + property.modelKey, interfaceProvider.allAcceptedInterfaces(property.type.asUri().modelTypeOrParent), true);
            case TypeStr.NestedModel:
                return new NestedModelStringCaster(interfaceProvider.interfaceForModel(property.type.asNestedModel().modelType));
        }
        return null;
    }
}
