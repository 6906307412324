import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from "../api/api.service";
import {InterfaceProviderService} from "../basic-entity-back/services/interface-provider.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {SolicitudEscaneo} from "../model/solicitudes/solicitud-escaneo.model";
import {SolicitudIntraoral} from "../model/solicitudes/solicitud-intraoral.model";
import {Usuario} from "../model/personas/usuario.model";

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    _finalizadoEmitter = new EventEmitter<boolean>();

    constructor(private apiService: ApiService, private _interfaceProvider: InterfaceProviderService) {
    }

    activarUsuario(usuario: Usuario): Observable<Usuario> {
        const endpoint = this._interfaceProvider.interfaceForModel(Usuario).endpointFor(usuario);
        return this.apiService.put(endpoint + '/force_activation', usuario).pipe(
            ApiService.sTakeBody(),
            map(obj => this._interfaceProvider.interfaceForModel(Usuario).serialiser.fromApi(obj)))
    }

    get finalizadoEmitter() {
        return this._finalizadoEmitter;
    }
}
