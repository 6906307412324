import { Inject, Type, Directive } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {EntityLoader} from "../../api/entity-loader";
import {Resource} from "../../api/resource";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {BaseDialogData} from "../../basic-entity-front/basic-entity-table/base-dialog-data";
import {EntityNameService} from "../../basic-entity-back/services/entity-name.service";
import {InterfaceProviderService} from "../../basic-entity-back/services/interface-provider.service";
import {BasicEntityInterface} from "../../basic-entity-back/basic-entity-interface/basic-entity-interface";
import {Uri} from "../../api/uri";
import {BaseDialog} from "../../basic-entity-front/basic-entity-table/base-dialog";
import {Section} from "../../basic-entity-front/multi-model-list/multi-model-list.component";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {SimpleDialogService} from "../../basic-entity-front/dialog-shell/simple-dialog.service";
import {ErrorDisplayService} from "../../basic-entity-front/services/error-display.service";
import {BasicEntityInputComponent} from "../../basic-entity-front/basic-entity-input/basic-entity-input.component";
import {InternalPropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-internal";
import {ApiModuleFactory} from "../../api/api-module-factory.service";
import {FormManager} from "../../basic-entity-front/edition-dialog/edition-row/edition-row.component";
import {EditionDialogFormManager} from "../../basic-entity-front/edition-dialog/edition-dialog-form-manager";
import {EntityManager} from "../../basic-entity-back/entity-manager/entity-manager";
import {MultipleSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {TypeStr} from "../../basic-entity-back/property-type/type-str";
import {FilterAndData} from "../../api/filter-list";
import {BloqueadorService} from "../../services/bloqueador.service";

export interface SectionModel {
    name: string;
    property: string;
    model: Type<Resource>;
}

export interface SectionAndProperty<T extends Resource> extends Section<T> {
    property: string;
    type: 'uri' | 'model';
}

@Directive()
export abstract class EditionListDialogComponent<T extends Resource> extends BaseDialog<T> implements FormManager {
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public editableProperties: InternalPropertyMap[];
    public arrayCols: InternalPropertyMap[];
    public title;
    public formGroupInfo: UntypedFormGroup;
    public formGroupAddElement: UntypedFormGroup;
    public loading$: Observable<boolean> = this._loading.asObservable();
    public entityLoader: EntityLoader<T, BasicEntityInterface<T>> = null;
    public addHint: string = null;
    public saved = false;
    public info = '';
    public listSections: SectionAndProperty<Resource>[] = [];

    private _formManager: EditionDialogFormManager<T>;
    public get formManager(): EditionDialogFormManager<T> {
        return this._formManager;
    }

    public get entity() {
        return this.model;
    }

    public get entityInterface(): BasicEntityInterface<T> {
        return this.entityLoader.entityInterface;
    }

    constructor(
        name: string,
        sectionModels: SectionModel[],
        @Inject(MAT_DIALOG_DATA) data: BaseDialogData<T>,
        dialogRef: MatDialogRef<EditionListDialogComponent<T>>,
        apiFactory: ApiModuleFactory,
        private fb: UntypedFormBuilder,
        private _nameProvider: EntityNameService,
        private _confirmationDialog: SimpleDialogService,
        private _interfaceProvider: InterfaceProviderService,
        private _errorDisplay: ErrorDisplayService
    ) {
        super(data, dialogRef);
        if (this.allowIdEdition) {
            this.editableProperties = this.properties.filter(col => !col.array);
        } else {
            this.editableProperties = this.properties.filter(col => !col.isId && !col.array);
        }
        const manager: EntityManager<T> = this._interfaceProvider.managerForModel(this.model.modelType) as EntityManager<T>;
        this._formManager = new EditionDialogFormManager(this.allFieldsDisabled, this.allowIdEdition, fb, manager, this._interfaceProvider);
        this.arrayCols = this.properties.filter(col => col.array);
        this.entityLoader = manager.loader;
        this.title = 'Edición de ' + name;
        if (!this.model.isTemporalEntity) {
            this.title += ' - #' + this.model.iri.id.join(';');
        }
        this._initGroupInfo(fb);
        this._generateListSections(sectionModels);
        this.formGroupAddElement = fb.group({
            picker: this.listSections[0]
        });
        this._initSections(fb);
    }

    private _initGroupInfo(fb: UntypedFormBuilder) {
        this.formGroupInfo = fb.group({});
        for (const property of this.editableProperties) {
            this.formGroupInfo.addControl(property.modelKey, fb.control(
                this.entityInterface.serialiser.getValue(this.model, property.modelKey),
                this.entityInterface.mappingModelToApi[property.modelKey].nullable ? undefined : Validators.required
            ));
        }
        for (const col of this.arrayCols) {
            let val = this.entityInterface.serialiser.getValue(this.model, col.modelKey) || [];
            this.formGroupInfo.addControl(col.modelKey, fb.array(
                val.map((value, idx) =>
                    BasicEntityInputComponent
                        .sFormControlForColumn(fb, col, value, this._formManager.shouldPropBeDisabled(col), idx)
                )
            ));
        }
        this.formGroupInfo.valueChanges.subscribe(() => {
            const value = this.formGroupInfo.value;
            this.modified = false;
            for (const property of this.editableProperties) {
                const valInModel = this.entityInterface.serialiser.getValue(this.model, property.modelKey);
                this.modified = this.modified || valInModel !== value[property.modelKey];
                if (this.modified) {
                    break;
                }
            }
            for (const col of this.arrayCols) {
                const valInModel = this.entityInterface.serialiser.getValue(this.model, col.modelKey);
                this.modified = this.modified || valInModel !== value[col.modelKey];
                if (this.modified) {
                    break;
                }
            }
        });
    }

    public createControl(property: InternalPropertyMap, val: any, index: number | null = null) {
        if (property.type.toString() === TypeStr.NestedModel) {
            val = this._interfaceProvider
                .interfaceForModel(property.type.asNestedModel().modelType)
                .serialiser.clone(val);
        }
        return BasicEntityInputComponent.sFormControlForColumn(
            this.fb,
            property,
            val,
            this._formManager.shouldPropBeDisabled(property),
            index,
            index === null ? null : true
        );
    }

    private _generateListSections(modelos: SectionModel[]) {
        for (const section of modelos) {
            const manager = this._interfaceProvider.managerForModel(section.model);
            const propertyMap = (manager.loader.entityInterface as BasicEntityInterface<Resource>).mappingModelToApi[section.property];
            const typeStr = propertyMap.type.toString();
            this.listSections.push({
                name: section.name,
                manager: manager,
                property: section.property,
                type: typeStr === TypeStr.Uri ? 'uri' : 'model',
                list: []
            });
        }
    }

    private _initSections(fb: UntypedFormBuilder) {
        let pendent = 0;
        for (const section of this.listSections) {
            this.formGroupAddElement.addControl(section.name, fb.control(null));

            if (!this.model.isTemporalEntity) {
                pendent++;
                const map = section.manager.loader.entityInterface.mappingModelToApi[section.property];
                section.manager.loader
                    .findAndFollow([new FilterAndData(MultipleSearchFilter, this.model, map)])
                    .subscribe(paginated => {
                        section.list = section.list.concat(paginated.member);

                        if (paginated.canBeInOrOverLastPage() && --pendent === 0) {
                            this._loading.next(false);
                        }
                    }, err => this._errorDisplay.displayError(err));
            } else {
                this._loading.next(false);
            }
        }
    }

    public resetAddValue() {
        const newValue = {};
        for (const model of this.listSections) {
            newValue[model.name] = null;
        }
        // We use patch because we don't put the picker control value
        this.formGroupAddElement.patchValue(newValue);
    }

    /**
     * Guarda los datos del modelo principal
     */
    public guardar() {
        if (this.formGroupInfo.valid) {
            const val = this.formGroupInfo.value;
            for (const property of this.editableProperties) {
                this.entityInterface.serialiser
                    .setValue(this.model, property.modelKey,
                        val[property.modelKey]);
            }
            for (const col of this.arrayCols) {
                this.entityInterface.serialiser
                    .setValue(this.model, col.modelKey,
                        val[col.modelKey].map(elem => elem[BasicEntityInputComponent.UNIQUE_CONTROL_NAME]));
            }
            if (!this.model.isTemporalEntity) {
                this.entityLoader.update(this.model).subscribe(
                    () => this.modified = false, err => this._errorDisplay.displayError(err)
                );
            } else {
                this.entityLoader.add(this.model).subscribe((newModel) => {
                    this.model.iri = newModel.iri;
                    this.model.entityType = newModel.entityType;
                    for (const property of this.editableProperties) {
                        this.entityInterface.serialiser.setValue(this.model, property.modelKey,
                            this.entityInterface.serialiser.getValue(newModel, property.modelKey));
                    }
                    this.title = this.title += ' - #' + newModel.iri.id.join(';');
                    this.modified = false;
                }, err => {
                    this.saved = false;
                    this._errorDisplay.displayError(err);
                });
            }
            this.saved = true;
        }
    }

    /**
     * Devuelve true cuando no podemos añadir el elemento seleccionado actualmente
     * a la zona. Esto sucede si ya está asignado a la zona o si es null.
     */
    public get cantAdd(): boolean {
        const value = this.formGroupAddElement.value;
        const picked: SectionAndProperty<any> = value.picker;
        const model = value[picked.name];
        if (!model) {
            this.addHint = 'Elije un elemento';
            return true;
        }
        const invalido = this._estaRepetido(picked, model);
        this.addHint = invalido ? 'El elemento ya está en la lista.' : 'Pulsa añadir para añadir el elemento a la lista.';
        return invalido;
    }

    private _estaRepetido(picked: SectionAndProperty<any>, model: Resource): boolean {
        const interf = picked.manager.loader.entityInterface as BasicEntityInterface<Resource>;
        if (picked.type === 'uri') {
            const actual: Uri | Uri[] = interf.serialiser.getValue(model, picked.property);
            if (!actual) {
                return false;
            } else if (actual instanceof Array) {
                return actual.find(uri => uri.isTheSame(this.model.iri)) !== undefined;
            } else {
                return this.model.iri.isTheSame(actual);
            }
        } else {
            const actual: Resource | Resource[] = interf.serialiser.getValue(model, picked.property);
            if (!actual) {
                return false;
            } else if (actual instanceof Array) {
                return actual.find(model => model.isTheSame(this.model)) !== undefined;
            } else {
                return actual.isTheSame(this.model);
            }
        }
    }

    /**
     * Añade un elemento a la lista
     */
    public addCurrentElement() {
        const value = this.formGroupAddElement.value;
        const picked: SectionAndProperty<any> = value.picker;
        const interf = picked.manager.loader.entityInterface as BasicEntityInterface<Resource>;
        const model = value[picked.name];
        if (model) {
            const propertyMap = interf.mappingModelToApi[picked.property];
            const actual: any = interf.serialiser.getValue(model, picked.property);
            if (actual && !propertyMap.array) {
                if (picked.type === 'uri') {
                    this._nameProvider.get(actual as Uri, false).then(name => {
                        this._askForConfirmationToAdd(name, picked, model);
                    });
                } else {
                    const thisInterface = this.entityLoader.entityInterface as BasicEntityInterface<T>;
                    this._askForConfirmationToAdd(thisInterface.getName(actual), picked, model);
                }
            } else {
                this._confirmAddElement(picked, model);
            }
        }
    }

    private _askForConfirmationToAdd(name: string, section: SectionAndProperty<any>, model: Resource) {
        const interf = section.manager.loader.entityInterface as BasicEntityInterface<any>;
        const modelName = interf.getName(model);
        this._confirmationDialog.confirmationDialog(
            `Añadir ${modelName}`,
            `El elemento <strong>${modelName}</strong> ya está asociado a <strong>${name}</strong>. Si continúa, esta asociación será reemplazada por la nueva. ¿Está seguro de que desea reemplazar la asociación?`,
            ['Sí, reemplazar', null, 'primary']
        ).then(
            decision => decision && this._confirmAddElement(section, model)
        );
    }

    private _confirmAddElement(section: SectionAndProperty<any>, model: Resource) {
        this._modelAssign(section, model);
        this._loading.next(true);
        this.resetAddValue();
        section.manager.update(model).subscribe(updated => {
            this._loading.next(false);
            section.list.push(updated);
            this.info = (section.manager.loader.entityInterface as BasicEntityInterface<any>)
                .getName(updated) + ' añadido. Cambios guardados.';
        }, err => this._errorDisplay.displayError(err));
    }

    /**
     * Asigna en el modelo indicado la id del modelo actual en la propiedad correspondiente
     * según su sección. Este método funciona para propiedades de tipo array y simples.
     * @param section
     * @param model
     * @private
     */
    private _modelAssign(section: SectionAndProperty<any>, model: Resource) {
        const interf = section.manager.loader.entityInterface as BasicEntityInterface<any>;
        const propertyMap = interf.mappingModelToApi[section.property];
        let value: any = this.model;
        if (propertyMap.array) {
            value = interf.serialiser.getValue(model, section.property);
            if (value instanceof Array) {
                value.push(this.model);
            } else {
                value = [this.model];
            }
        }
        if (propertyMap.type.toString() === TypeStr.Uri) {
            if (value instanceof Array) {
                value = value.map(val => val instanceof Resource ? val.iri : val);
            } else {
                value = value instanceof Resource ? value.iri : value;
            }
        }
        interf.serialiser.setValue(model, section.property, value);
    }

    /**
     * Elimina un elemento de la list de la zona
     * @param entity
     * @param section
     */
    public deleteElement(entity: Resource, section: SectionAndProperty<Resource>) {
        this._modelRemove(section, entity);
        this._loading.next(true);
        section.manager.update(entity).subscribe(updated => {
            this._loading.next(false);
            const interf = section.manager.loader.entityInterface as BasicEntityInterface<Resource>;
            const idx = section.list.findIndex(element => element.isTheSame(updated));
            if (idx >= 0) {
                section.list.splice(idx, 1);
            }
            this.info = interf.getName(updated) + ' eliminado de la lista. Cambios guardados.';
        }, err => this._errorDisplay.displayError(err));
    }

    /**
     * Elimina el modelo actual de la propiedad correspondiente a la sección de un modelo perteneciente a esa
     * sección. Nótese que funciona para arrays y uris simples.
     * @param section
     * @param entity
     * @private
     */
    private _modelRemove(section: SectionAndProperty<any>, entity: Resource) {
        const interf = section.manager.loader.entityInterface as BasicEntityInterface<Resource>;
        let value = null;
        if (interf.mappingModelToApi[section.property].array) {
            value = interf.serialiser.getValue(entity, section.property);
            if (!(value instanceof Array)) {
                return;
            }
            let search = model => this.model.isTheSame(model);
            if (section.type === "uri") {
                search = uri => this.model.iri.isTheSame(uri);
            }
            const idx = value.findIndex(search);
            if (idx >= 0) {
                value.splice(idx, 1);
            }
        }
        interf.serialiser.setValue(entity, section.property, value);
    }

    public fakeNestedModelType(option: SectionAndProperty<any>) {
        return PropertyType.NestedModel((option.manager.loader.entityInterface as BasicEntityInterface<any>).serialiser.model);
    }
}
