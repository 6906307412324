import {EntityInterface} from '../../api/entity-interface';
import {Resource} from '../../api/resource';
import {InterfaceProviderService} from '../services/interface-provider.service';
import {Type} from '@angular/core';
import {BasicEntityInterfaceDescription, ManagerCachingStrategy} from "./mapping-external";
import {InterfaceMapping, InternalPropertyMap, IRI_PROPERTY, TYPE_PROPERTY} from "./mapping-internal";
import {BasicEntitySerialiser} from "./basic-entity-serialiser";
import {BasicEntityFilterManager} from "./basic-entity-filter-manager";
import {Uri} from "../../api/uri";
import {FilterList} from "../../api/filter-list";
import {PropertyType} from "../property-type/property-type";
import {CasterFactory} from "./caster-factory";

/**
 * Central point of the BasicEntityBackModule, the BasicEntityInterface encapsulates
 * all the knowledge about how the entity works with the API and what the API
 * allows over the entity.
 * It will manage correctly any entity with a stable interface on a single endpoint
 * with a non-compound primary key (the kind of endpoint it will manage is somename/:id).
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class BasicEntityInterface<ModelType extends Resource> implements EntityInterface<ModelType> {
    /** Name for the interface */
    public readonly name: string;
    /** Is the entity paginated? */
    public readonly isPaginated: boolean;
    /** Which page sizes are allowed for this entity? */
    public readonly paginationSizes: number[];
    /** What is the endpoint in the API for this entity? */
    public readonly endpoint: string;
    /** Can this interface be written or not? */
    public readonly readonlyInterface: boolean;
    /** Mappings of this interface to the API */
    public readonly mappingModelToApi: InterfaceMapping;
    /** Is the id of this interface autogenerated? */
    public readonly autogeneratedId: boolean;
    public readonly acceptsBulkIriSearch: boolean;
    public readonly serialiser: BasicEntitySerialiser<ModelType>;
    public readonly filterManager: BasicEntityFilterManager<ModelType>;
    public readonly idProperties: string[] = [];
    public readonly managerCaching: ManagerCachingStrategy;

    /** Which of the mapped properties should be used to show the object when nested or referenced by Uri? */
    private readonly modelNameProperty: string;
    /** Pattern for the name */
    private readonly modelNamePattern: string | null;

    /**
     * Given the mapping of the properties, clones it and checks they are setteable.
     */
    public constructor(description: BasicEntityInterfaceDescription<ModelType>,
                       private _interfaceProvider: InterfaceProviderService) {
        this.isPaginated = description.isPaginated;
        this.name = description.name;
        this.acceptsBulkIriSearch = !!description.acceptsBulkIriSearch;
        this.paginationSizes = description.paginationSizes;
        this.endpoint = description.endpoint;
        this.autogeneratedId = description.autogeneratedId !== false; // true by default
        this.readonlyInterface = !!description.readOnlyInterface;
        this.managerCaching = description.managerCaching || ManagerCachingStrategy.NoCache;

        this.mappingModelToApi = this._modelBaseMappings(description.model);
        // We need to make sure each of the maps is cloned so casters are not shared between shared mappings :)
        for (const [key, map] of Object.entries(description.mappingModelToApi)) {
            this.mappingModelToApi[key] = new InternalPropertyMap(key, map, this.readonlyInterface);
            if (this.mappingModelToApi[key].isId) {
                this.idProperties.push(key);
            }
        }
        if (this.idProperties.length === 0) {
            throw new Error(`An entity interface needs at least one ID property to work! ${description.endpoint} has no ID.`);
        }
        this.modelNameProperty = description.modelNameProperty || this.idProperties[0];
        this.modelNamePattern = description.modelNamePattern || null;
        this.serialiser = new BasicEntitySerialiser<ModelType>(
            _interfaceProvider, this.mappingModelToApi, description.model, description.type);
        this.filterManager = new BasicEntityFilterManager<ModelType>(this.mappingModelToApi);
    }

    /**
     * Call this method just after construction to create the casters applying the default ones,
     * this cannot be done in the constructor because of infinite recursion in circular dependencies.
     */
    public createCasters() {
        for (const inApi of Object.values(this.mappingModelToApi)) {
            inApi.initialiseCasters(this._interfaceProvider, CasterFactory.sDefaultCaster(this._interfaceProvider, inApi), CasterFactory.sStringCaster(this._interfaceProvider, inApi));
        }
    }

    /**
     * Base mappings which every model has
     */
    private _modelBaseMappings(model: Type<ModelType>): { [s: string]: InternalPropertyMap } {
        return {
            [IRI_PROPERTY]: new InternalPropertyMap(IRI_PROPERTY, {
                keyInApi: '@id',
                name: 'Iri',
                nullable: true,
                type: PropertyType.Uri(model),
                array: false
            }, this.readonlyInterface),
            [TYPE_PROPERTY]: new InternalPropertyMap(TYPE_PROPERTY, {
                keyInApi: '@type',
                name: 'Type',
                nullable: true,
                type: PropertyType.String(),
                array: false
            }, this.readonlyInterface)
        };
    }

    /**
     * Gets the pattern for the URI of an element of the interface
     */
    public get uriPattern(): string {
        let patternKey = ':0';
        if (this.idProperties.length > 1) {
            patternKey = this.idProperties.map((p, i) => `${p}=:${i}`).join(';');
        }
        return `/${this.endpoint}/${patternKey}`;
    }

    endpointFor(model: ModelType): string {
        if (model.isTemporalEntity) {
            throw new Error('No endpoint for the model, it is a temporal entity!');
        }
        return model.iri.toString();
    }

    endpointForId(...values): string {
        return new Uri(values, this.uriPattern, this.serialiser.model).toString();
    }

    fromGetToModel(body: any): ModelType {
        return this.serialiser.fromApi(body);
    }

    fromPostToModel(body: any): ModelType {
        return this.serialiser.fromApi(body);
    }

    fromPutToModel(body: any): ModelType {
        return this.serialiser.fromApi(body);
    }

    forPost(model: ModelType): any {
        if (this.readonlyInterface) {
            throw new Error('This entity should not be POSTed, it is read-only (try BasicEntityInterface.toApi maybe?)!');
        }
        return this.serialiser.toApi(model);
    }

    forClone(model: ModelType): any {
        if (this.readonlyInterface) {
            throw new Error('This entity should not be POSTed, it is read-only (try BasicEntityInterface.toApi maybe?)!');
        }
        return this.serialiser.toApi(model, true);
    }

    forPut(model: ModelType): any {
        if (this.readonlyInterface) {
            throw new Error('This entity should not be PUTted, it is read-only (try BasicEntityInterface.toApi maybe?)!');
        }
        return this.serialiser.toApi(model);
    }

    canSortBy(modelKey?: string): boolean {
        return this.filterManager.canSortBy(modelKey);
    }

    paramToSort(modelKey?: string): string {
        return this.filterManager.paramToSort(modelKey);
    }

    filtersToParams(filters: FilterList): { [p: string]: string | string[] } {
        return this.filterManager.filtersToParams(filters);
    }

    public isIdProperty(propertyKey: string) {
        return this.idProperties.indexOf(propertyKey) >= 0;
    }

    public getName(model: ModelType): string | null {
        if (!model) {
            return '';
        }
        if (this.modelNamePattern) {
            return this.modelNamePattern.replace(/\{\{([a-z0-9_]+)\}\}/ig, (complete: string, param: string) => {
                if (this.mappingModelToApi[param]) {
                    return this.serialiser.getValueAsString(model, param);
                } else {
                    return param;
                }
            });
        } else {
            const name = model[this.modelNameProperty];
            if (name !== null && name !== undefined) {
                return name.toString();
            } else {
                return null;
            }
        }
    }

    public getId(model: ModelType): string | string[] {
        return model.iri.id;
    }
}
