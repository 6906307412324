
<form #form="ngForm" (ngSubmit)="login(form)">
    <mat-card class="login-card">
        <img class="login_logo" mat-card-image src="/assets/Logo_Blanco.svg" alt="logo CNC">
        <span>PANEL DE ADMINISTRACIÓN</span>
        <mat-card-header>

            <mat-card-title> </mat-card-title>
            <!--<mat-card-subtitle *ngIf="!sessionExpired">¡Hola! Inicia sesión con tu nombre de usuario</mat-card-subtitle>-->
            <mat-card-subtitle *ngIf="sessionExpired"><mat-icon>info</mat-icon> La sesión previa ha expirado</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="input_formulario">
               <label class="label_login">Usuario</label>
                <input class="campo_imput"  matInput name="user" id="input-user" ngModel placeholder="Usuario" required>
                <mat-error *ngFor="let error of errors.username">{{error}}</mat-error>
            </div>

            <div class="input_formulario">
                <label class="label_login">Contraseña</label>
                <input  class="campo_imput" matInput name="password" type="password" id="input-password"
                       ngModel placeholder="Contraseña" required>
                <mat-error *ngFor="let error of errors.password">{{error}}</mat-error>
            </div>

            <div class="info" *ngIf="capsLock">
                <mat-icon>warning</mat-icon>
                BloqMayús podría estar activado.
            </div>
            <div class="error" *ngFor="let error of errors.general"><mat-icon>error</mat-icon> {{error}}</div>
        </mat-card-content>

        <mat-card-actions>
            <button mat-flat-button type="submit"  class="boton_login">Login</button>
            <a href="#">Olvidó la contraseña?</a>

        </mat-card-actions>
</mat-card>
</form>
