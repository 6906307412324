import {Component} from "@angular/core";
import {Fabricante} from "../../../model/personas/fabricante.model";
import {EditionDialogComponent} from "../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {BasicEntityTableColumnDescriptionOrString, ColumnJustification} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";

@Component({
    selector: 'app-fabricantes',
    template: '<be-table [modelType]="model" [editionDialog]="dialog" [columnsToDisplay]="displayedColumns" id="fabricantes"></be-table>',
})
export class FabricantesComponent {
    public model = Fabricante;
    public dialog = EditionDialogComponent;
    displayedColumns: BasicEntityTableColumnDescriptionOrString[] = [
        {key: 'id', justification: ColumnJustification.Center, weight: 1},
        {key: 'denominacionSocial', justification: ColumnJustification.Center, weight: 4},
        {key: 'email', justification: ColumnJustification.Center, weight: 4}

    ];

}
