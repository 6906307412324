import {Component} from "@angular/core";
import {Proveedor} from "../../../model/personas/proveedor.model";
import {BasicEntityTableColumnDescriptionOrString, ColumnJustification} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {ProveedoresEditionComponent} from "./proveedores-edition/proveedores-edition.component";

@Component({
    selector: 'app-proveedores',
    template: '<be-table [modelType]="model" [editionDialog]="dialog" [columnsToDisplay]="displayedColumns" id="proveedores"></be-table>',
})
export class ProveedoresComponent {

    public model = Proveedor;
    public dialog = ProveedoresEditionComponent;
    displayedColumns: BasicEntityTableColumnDescriptionOrString[] = [
        { key: 'nombreCompleto', hiddenInEdition: true},
        {key: 'nombre', hidden: true},
        {key: 'apellido1', hidden: true},
        {key: 'apellido2', hidden: true},
        {key: 'isEmpresa', justification: ColumnJustification.Center},
        {key: 'cifNif', justification: ColumnJustification.Center},
        {key: 'fechaCreacion', hiddenInEdition: true, justification: ColumnJustification.Center},
        {key: 'fechaModificacion', hiddenInEdition: true, justification: ColumnJustification.Center},
        {key: 'direcciones', hidden: true},
        {key: 'telefonos', hidden: true},
        {key: 'todosLosTelefonos', hiddenInEdition: true},
        {key: 'idioma', justification: ColumnJustification.Center},
        {key: 'email'}
    ];
}
