import {Resource} from "../../api/resource";
import {Uri} from "../../api/uri";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Municipio} from "../localizacion/municipio.model";
import {Provincia} from "../localizacion/provincia.model";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Pais} from "../localizacion/pais.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";


@BasicEntity({
    name: "Dirección",
    endpoint: "direccion_centros",
    modelNameProperty: "lectura",
    isPaginated: false,
})
export class DireccionCentro extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Calle',
        type: PropertyType.String(),
        nullable: true
    })
    private _calle?: string;

    @BasicProperty({
        name: 'Código Postal',
        type: PropertyType.String(),
        nullable: true
    })
    private _cp?: string;

    @BasicProperty({
        name: 'Municipio',
        type: PropertyType.NestedModel(Municipio, null, NestedBehavior.SendIriOnly)
    })
    private _poblacion;

    @BasicProperty({
        name: 'Provincia',
        type: PropertyType.Uri(Provincia),
        readWrite: ReadWrite.ReadOnly
    })
    private _provincia: Uri;

    @BasicProperty({
        name: 'País',
        type: PropertyType.NestedModel(Pais, null, NestedBehavior.SendIriOnly)
    })
    private _pais: Pais;

    public static readonly CENTRO_MAP: PropertyMap = {
        name: 'Centro',
        type: PropertyType.FutureUri('centro-produccion.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    private _centro: Uri;

    public get lectura(): string {
        return `${this._calle}, ${this._cp}, ${this._poblacion.nombre}, ${this.pais.nombreDefault}.`;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get calle(): string {
        return this._calle;
    }

    set calle(value: string) {
        this._calle = value;
    }

    get cp(): string {
        return this._cp;
    }

    set cp(value: string) {
        this._cp = value;
    }

    get poblacion() {
        return this._poblacion;
    }

    set poblacion(value) {
        this._poblacion = value;
    }

    get provincia(): Uri {
        return this._provincia;
    }

    set provincia(value: Uri) {
        this._provincia = value;
    }

    get pais(): Pais {
        return this._pais;
    }

    set pais(value: Pais) {
        this._pais = value;
    }

    get centro(): Uri {
        return this._centro;
    }

    set centro(value: Uri) {
        this._centro = value;
    }
}
