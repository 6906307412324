import {
    Component,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Renderer2,
    SkipSelf,
    Type,
    ViewChild
} from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    UntypedFormControl,
    NG_VALUE_ACCESSOR
} from "@angular/forms";
import {EntityDatasource} from "../../../api/entity.datasource";
import {Observable} from "rxjs";
import {Resource} from "../../../api/resource";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {BasicEntityInterface} from "../../../basic-entity-back/basic-entity-interface/basic-entity-interface";
import {NestedBehavior, NestedModelTypeInterface} from "../../../basic-entity-back/property-type/nested-model-type";
import {ApiModuleFactory} from "../../../api/api-module-factory.service";
import {FloatLabelType} from "@angular/material/form-field";
import {MatDialog} from "@angular/material/dialog";
import {MatInput} from "@angular/material/input";
import {EditionDialogComponent} from "../../edition-dialog/edition-dialog.component";
import {BaseDialog} from "../../basic-entity-table/base-dialog";
import {ErrorDisplayService} from "../../services/error-display.service";
import {GeneralSearchFilter} from "../../../basic-entity-back/filters/general-search-filter";
import {FilterAndData} from "../../../api/filter-list";
import {MatOptionModel} from './mat-option-model';
import {fadeInFadeOut} from '../../animations/animations';

/**
 * Notice the nested input allows only to choose from a list of existent objects, rather than creating a new one or editing it
 */
@Component({
    selector: "be-nested-input",
    templateUrl: "./nested-input.component.html",
    styleUrls: ["./nested-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NestedInputComponent),
            multi: true
        }
    ],
    animations: [fadeInFadeOut]
})
export class NestedInputComponent<T extends Resource>
    implements OnInit, OnDestroy, ControlValueAccessor {
    /** Whether the input is required or not */
    @Input() public required;
    /** Id for the input */
    @Input() public id: string;
    /** Title for the input */
    @Input() public title: string;
    /** A hint to put under the component */
    @Input() public hint: string;
    /** Name for the control to find the FormControl  */
    @Input() public formControlName;
    /** Interface of the entities referenced by the models stored here */
    @Input() public nestedModelType: NestedModelTypeInterface<T>;
    /** Placeholder */
    @Input() public placeholder: string = null;
    /** Float label */
    @Input() public floatLabel: FloatLabelType = "never";
    @Input() public contenido: MatOptionModel;
    @ViewChild(MatInput, {static: true}) public matInput: MatInput;

    public get modelType(): Type<T> {
        return this.nestedModelType.modelType;
    }

    public get cannotPick(): boolean {
        return this.nestedModelType.behavior === NestedBehavior.CreateOnly;
    }

    public get isPickAndCreate(): boolean {
        return this.nestedModelType.behavior === NestedBehavior.CreateAndPick;
    }

    /** Control which manages the input */
    public inputControl = new UntypedFormControl();
    /** Options displayed in the autocomplete */
    public options: Observable<T[]>;
    /** It is set to true when the component is disabled so all the components should be disabled */
    public buttonDisabled = false;

    /** Field to store the callback to perform when the input has been touched */
    private _onTouched;
    /** Field to store the callback to perform when the input has been changed */
    private _onChange;
    /** Abstract control for the element (do not mistake with the internal FormGroup) */
    private _control: AbstractControl;
    /** Data provider for the autocomplete */
    private _dataSource: EntityDatasource<T>;
    /** The interface for the model type given */
    private _interf: BasicEntityInterface<T>;
    /** Last accepted value, when it cannotPick is true this will replace the user input */
    public lastAcceptedValue: any = null;
    /** This timeout is used to avoid reloading the items on every type */
    private _reloadTimeout = 0;

    constructor(
        private _renderer: Renderer2,
        @Optional()
        @Host()
        @SkipSelf()
        private _controlContainer: ControlContainer,
        private _interfaceProvider: InterfaceProviderService,
        private _matDialog: MatDialog,
        private _errorDisplay: ErrorDisplayService,
        private _apiFactory: ApiModuleFactory
    ) {
        this.displayOption = this.displayOption.bind(this);
    }

    ngOnInit() {
        if (this._controlContainer && this.formControlName) {
            this._control = this._controlContainer.control.get(
                this.formControlName
            );
        }
        if (!this.modelType) {
            throw new Error("Model type required in nested-input component!");
        }
        this._interf = this._interfaceProvider.interfaceForModel(
            this.modelType
        ) as BasicEntityInterface<T>;
        this._initDatasource(this._interf);
        this.inputControl.valueChanges.subscribe(this._valueChanged.bind(this));
    }

    private _initDatasource(interf: BasicEntityInterface<T>) {
        this._dataSource = this._apiFactory.createDataSource(interf);
        this.options = this._dataSource.connect(null);
    }

    private _valueChanged(value: string | Resource) {
        if (this.cannotPick) {
            if (value !== this.lastAcceptedValue) {
                this.inputControl.setValue(this.lastAcceptedValue);
            }
            return;
        }

        if (typeof value === "string") {
            if (!value) {
                this._onValidValue(null);
            } else {
                this._setErrors({invalidSelection: true});
                clearTimeout(this._reloadTimeout);
                // @ts-ignore
                this._reloadTimeout = setTimeout(
                    () => this._filter(value),
                    300
                );
            }
        } else {
            // If it was a chosen Model, simply reset errors an trigger onChange
            this._onValidValue(value);
        }
        // Always touched
        if (this._onTouched) {
            this._onTouched();
        }
    }

    private _onValidValue(value: Resource) {
        this._setErrors(null);
        if (this._onChange) {
            this._onChange(value);
        }
    }

    private _setErrors(errors) {
        this.inputControl.setErrors(errors);
        if (this._control) {
            this._control.setErrors(errors);
        }
    }

    ngOnDestroy() {
        this._dataSource.disconnect(null);
    }

    _filter(value: string) {
        this._dataSource.filtros = [
            new FilterAndData(GeneralSearchFilter, value)
        ];
        this._dataSource.load();
    }

    displayOption(option: T) {
        if (option && this._interf) {
            if (this.contenido) {
                return this.getContenido(option).titulo;
            } else {
                if (this.getName(option)) {
                    return this.getName(option);
                } else {
                    return option.iri ? option.iri.id.join(";") : null;
                }
            }
        }
    }

    onFocus(event: FocusEvent) {
        if (!this.cannotPick) {
            const ipt = event.currentTarget as HTMLInputElement;
            ipt.selectionStart = 0;
            ipt.selectionEnd = ipt.value.length;
        }
    }

    onClick(event: MouseEvent) {
        this._editLastAcceptedValue();
    }

    onKeyDown(event: KeyboardEvent) {
        if (["Enter", "NumpadEnter"].indexOf(event.code) >= 0) {
            event.preventDefault();
            event.cancelBubble = true;
            this._editLastAcceptedValue();
        }
    }

    createOne() {
        const toCreate: T = this._interf.serialiser.getEmptyModel();
        console.log(this._interf.serialiser.getEmptyModel());
        this._matDialog.open(this.nestedModelType.creationDialog || EditionDialogComponent, {
            disableClose: true,
            autoFocus: true,
            restoreFocus: true,
            data: this.nestedModelType.baseDialogDataForModel(this._interf, toCreate, true, true, false)
        }).afterClosed().subscribe(result => {
            if (result === BaseDialog.RESULT_SHOULD_RELOAD) {
                this.writeValue(toCreate);
            }
        });
    }


    _editLastAcceptedValue() {
        if (!this.cannotPick) {
            return; // Only when we can't pick
        }
        if (this.lastAcceptedValue instanceof Resource || this.lastAcceptedValue === null || this.lastAcceptedValue === undefined) {
            const toEdit: T = this.lastAcceptedValue || this._interf.serialiser.getEmptyModel();
            this._matDialog.open(this.nestedModelType.creationDialog || EditionDialogComponent, {
                    disableClose: true,
                    autoFocus: true,
                    restoreFocus: true,
                    data: this.nestedModelType.baseDialogDataForModel(
                        this._interf,
                        toEdit,
                        this.lastAcceptedValue !== toEdit,
                        false,
                        this.inputControl.disabled
                    )
                }
            ).afterClosed().subscribe(
                result => {
                    if (result === BaseDialog.RESULT_SHOULD_RELOAD) {
                        this.writeValue(toEdit);
                        this._onValidValue(toEdit);
                    }
                },
                err => this._errorDisplay.displayError(err)
            );
        }
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.buttonDisabled = isDisabled;
        if (isDisabled) {
            this.inputControl.disable({emitEvent: false});
        } else {
            this.inputControl.enable({emitEvent: false});
        }
    }

    writeValue(obj: any): void {
        this.lastAcceptedValue = obj;
        this.inputControl.setValue(obj);
    }

    clear(event: MouseEvent) {
        event.preventDefault();
        event.cancelBubble = true;
        this.writeValue(null);
        this._onValidValue(null);
    }

    getName(model: T): string {
        return this._interf.getName(model);
    }

    getContenido(model: T): MatOptionModel {
        return new MatOptionModel(model[this.contenido.titulo], model[this.contenido.segundaLinea], model[this.contenido.matIcon],
            model[this.contenido.matIconColor]);
    }
}
