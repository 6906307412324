<mat-sidenav-container [ngClass]="!isLogged() ? 'sidenav-container-loggin' : 'sidenav-container'" [hasBackdrop]="false">

    <mat-sidenav #drawer class="sidenav" [attr.role]="isHandset ? 'dialog' : 'navigation'"
                 [mode]="isHandset ? 'over' : 'side'"
                 [opened]="menuItems && menuItems.length > 0 && !isHandset">
        <app-sidenavHeader></app-sidenavHeader>
        <app-menu [items]="menuItems" (itemClicked$)="menuItemClicked(drawer)"></app-menu>
    </mat-sidenav>
    <mat-sidenav-content>
        <header>
            <app-header [drawer]="drawer" [menuAvailable]="menuItems && menuItems.length > 0" [title]=""
                        [isHandset]="isHandset"></app-header>
        </header>
        <main [ngClass]="!isLogged() ? 'main-loggin' : 'main'">
            <router-outlet></router-outlet>
        </main>
        <footer>
           <!-- OCULTAMOS EL FOOTER: NO APORTA INFORMAICÓN AL PANEL
            <app-footer *ngIf="isLogged()"></app-footer>

                 OCULTAMOS EL FOOTER -->
        </footer>
    </mat-sidenav-content>
</mat-sidenav-container>
