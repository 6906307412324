<div class="container">
    <div class="listado_direcciones" *ngIf="direccionesArray.length > 0; else sindirecciones">
        <div class="direccion">
            <table>
                <th class="center" style="width: 15%">Tipo</th>
                <th style="width: 40%">Calle</th>
                <th style="width: 35%">Población</th>
                <th class="actions"></th>
                <tr *ngFor="let direccion of direccionesArray; let i = index">
                    <td>{{direccion.tipoDireccion}}</td>
                    <td class="overflow">{{direccion.calle}}</td>
                    <td class="overflow">{{direccion.poblacion}}</td>
                    <td class="botones">
                        <button mat-icon-button (click)="addEdit(direccion, i)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button (click)="delete(direccion, i)"><mat-icon>delete</mat-icon></button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <ng-template #sindirecciones>
        <span class="nodir">No existen direcciones por el momento</span>
    </ng-template>
    <div class="add_button">
        <button mat-raised-button (click)="addEdit()">Añadir</button>
    </div>
</div>
