<mat-toolbar [class.session-expiring]="sessionMinutesLeft < 5" color="primary"
             *ngIf="sessionService.isLogged()">
    <button type="button" aria-label="Toggle sidenav" class="logout" mat-icon-button matTooltip="Menu"
            (click)="drawer.toggle()" *ngIf="menuAvailable && isHandset">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <span class="title">{{title}}</span>
    <span class="clock" *ngIf="displayClock && mostrarToolBar">{{clock}}</span>
    <span class="fill-space"></span>
    <form  class="search-box" autocomplete="off" [formGroup]="searchGroup">
      <mat-form-field appearance="none" floatLabel="never" color="primary">
            <mat-label>Búsqueda</mat-label>
            <input  matInput type="text" formControlName="search">
            <mat-icon matSuffix >search</mat-icon>
       </mat-form-field>
    </form>
    <a
            aria-label="Login"
            mat-icon-button
            matTooltip="Login"
            routerLink="login"
            *ngIf="!sessionService.isLogged() && mostrarToolBar">
        <mat-icon aria-label="Side nav login icon">person</mat-icon>
        >
    </a>
    <a *ngIf="sessionService.hasRole('ROLE_ADMIN')"
       aria-label="Ver carpeta de validados"
       mat-icon-button
       matTooltip="Ver carpeta de validados"
       routerLink="pedidos/carpetas-validacion">
        <mat-icon aria-label="Ver carpeta de validados">account_tree</mat-icon>
    </a>
</mat-toolbar>
