import {Pais} from './model/localizacion/pais.model';
import {PaisComponent} from './pages/localizacion/pais/pais.component';
import {SessionGuard} from './session/session.guard';
import {Comunidad} from './model/localizacion/comunidad.model';
import {ComunidadComponent} from './pages/localizacion/comunidad/comunidad.component';
import {ZonaComponent} from './pages/localizacion/zona/zona.component';
import {Zona} from './model/localizacion/zona.model';
import {Provincia} from './model/localizacion/provincia.model';
import {ProvinciaComponent} from './pages/localizacion/provincia/provincia.component';
import {MunicipioComponent} from './pages/localizacion/municipio/municipio.component';
import {Municipio} from './model/localizacion/municipio.model';
import {IdiomaComponent} from './pages/localizacion/idioma/idioma.component';
import {Idioma} from './model/localizacion/idioma.model';
import {FestivoComponent} from './pages/localizacion/festivo/festivo.component';
import {Festivo} from './model/localizacion/festivo.model';
import {Usuario} from './model/personas/usuario.model';
import {EmpleadosComponent} from './pages/personas/empleados/empleados.component';
import {Empleado} from './model/personas/empleado.model';
import {ClientesComponent} from './pages/personas/clientes/clientes.component';
import {Cliente} from './model/personas/cliente.model';
import {RegistrosEmpleadosComponent} from './pages/personas/registros-empleados/registros-empleados.component';
import {RegistroEmpleado} from './model/personas/registro-empleado.model';
import {UserGroupsComponent} from "./pages/admin/user-groups/user-groups.component";
import {UserGroup} from "./model/personas/user-group.model";
import {ProveedoresComponent} from "./pages/personas/proveedores/proveedores.component";
import {Proveedor} from "./model/personas/proveedor.model";
import {UsuariosComponent} from "./pages/personas/usuarios/usuarios.component";
import {Comercial} from "./model/personas/comercial.model";
import {ComercialesComponent} from "./pages/personas/comerciales/comerciales.component";

const PERSONAS = [
    {
        path: 'usuarios',
        component: UsuariosComponent,
        model: Usuario,
        canActivate: [SessionGuard],
        data: {expectedRole: 'ROLE_ADMIN', title: 'Usuarios'}
    },
    {
        path: 'empleados',
        component: EmpleadosComponent,
        model: Empleado,
        canActivate: [SessionGuard],
        data: {expectedRole: 'ROLE_ADMIN', title: 'Empleados'}
    },
    {
        path: 'comerciales',
        component: ComercialesComponent,
        model: Comercial,
        canActivate: [SessionGuard],
        data: {expectedRole: 'ROLE_ADMIN', title: 'Comerciales'}
    },
    {
        path: 'clientes',
        component: ClientesComponent,
        model: Cliente,
        canActivate: [SessionGuard],
        data: {expectedRole: 'ROLE_EMPLEADO, ROLE_COMERCIAL', title: 'Clientes'}
    },
    {
        path: 'proveedores',
        component: ProveedoresComponent,
        model: Proveedor,
        canActivate: [SessionGuard],
        data: {expectedRole: 'ROLE_EMPLEADO', title: 'Proveedores'}
    },
    {
        path: 'user-groups',
        component: UserGroupsComponent,
        model: UserGroup,
        canActivate: [SessionGuard],
        data: {expectedRole: 'ROLE_ADMIN', title: 'Grupos de usuarios'}
    },
    {
        path: 'registros-empleados',
        component: RegistrosEmpleadosComponent,
        model: RegistroEmpleado,
        canActivate: [SessionGuard],
        data: {title: 'Registros'}
    },
];

const LOCALIZACIONES = [
    {
        path: 'paises',
        component: PaisComponent,
        model: Pais,
        canActivate: [SessionGuard],
        data: {title: 'Países'}
    },
    {
        path: 'comunidades',
        component: ComunidadComponent,
        model: Comunidad,
        canActivate: [SessionGuard],
        data: {title: 'Comunidades'}
    },
    {
        path: 'provincias',
        component: ProvinciaComponent,
        model: Provincia,
        canActivate: [SessionGuard],
        data: {title: 'Provincias'}
    },
    {
        path: 'municipios',
        component: MunicipioComponent,
        model: Municipio,
        canActivate: [SessionGuard],
        data: {title: 'Municipios'}
    },
    {
        path: 'zonas',
        component: ZonaComponent,
        model: Zona,
        canActivate: [SessionGuard],
        data: {title: 'Zonas'}
    },
    {
        path: 'festivos',
        component: FestivoComponent,
        model: Festivo,
        canActivate: [SessionGuard],
        data: {title: 'Festivos'}
    },
    {
        path: 'idiomas',
        component: IdiomaComponent,
        model: Idioma,
        canActivate: [SessionGuard],
        data: {title: 'Idioma'}
    }
];

export const TABLES = [
    ...PERSONAS,
    ...LOCALIZACIONES
];

