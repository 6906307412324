import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocalizacionModule} from './pages/localizacion/localizacion.module';
import {PersonasModule} from './pages/personas/personas.module';
import {AdminModule} from './pages/admin/admin.module';
import {MaterialModule} from './material/material.module';
import {ShellModule} from './shell/shell.module';
import {SessionModule} from './session/session.module';
import {InicioComponent} from './pages/inicio/inicio.component';
import {ProfileComponent} from './profile/profile.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PortalModule} from '@angular/cdk/portal';
import {BasicEntityFrontModule} from './basic-entity-front/basic-entity-front.module';
import {TABLES} from './tables';
import {INTERFACE_PROVIDER_PATHS} from './basic-entity-back/services/interface-provider.service';
import {TestWidget2Component} from './pages/inicio/test-widget2/test-widget2.component';

import {MAT_DATE_LOCALE} from '@angular/material/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoadingInterceptor} from './shell/loading/loading.interceptor';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatDialogModule} from "@angular/material/dialog";
import {MatRadioModule} from "@angular/material/radio";
import {FabricantesComponent} from "./pages/personas/fabricantes/fabricantes.component";
import {UsuariosComponent} from "./pages/personas/usuarios/usuarios.component";
import {ServiceLocator} from "./services/ServiceLocator";
import {VisorStlComponent} from './pages/visor-stl/visor-stl.component';


@NgModule({
    declarations: [
        AppComponent,
        InicioComponent,
        ProfileComponent,
        TestWidget2Component,
        FabricantesComponent,
        UsuariosComponent,
        VisorStlComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LocalizacionModule,
        PersonasModule,
        AdminModule,
        MaterialModule,
        ShellModule,
        SessionModule,
        FormsModule,
        ReactiveFormsModule,
        PortalModule,
        BasicEntityFrontModule,
        MatInputModule,
        MatDialogModule,
        MatDatepickerModule,
        MatRadioModule
    ],

    providers: [
        {provide: INTERFACE_PROVIDER_PATHS, useValue: TABLES},
        {provide: MAT_DATE_LOCALE, useValue: 'es'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }
    ],
    exports: [MatDatepickerModule],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private injector: Injector) {
        ServiceLocator.injector = injector;
    }
}
