import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Archivo} from "./archivo.model";

@BasicEntity({
    name: 'Archivo mandato SEPA',
    endpoint: 'archivo_mandato_sepas',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    type: "ArchivoMandatoSepa",
    modelNameProperty: 'nombreFichero'
})
export class ArchivoMandatoSepa extends Archivo {

    public static readonly MANDATO_MAP: PropertyMap = {
        name: 'Mandato',
        type: PropertyType.FutureUri('mandato.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    @BasicProperty(ArchivoMandatoSepa.MANDATO_MAP)
    private _mandato;

    get mandato() {
        return this._mandato;
    }

    set mandato(value) {
        this._mandato = value;
    }
}
