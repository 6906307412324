import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {ObjViewerService} from "../../services/obj-viewer.service";
import {environment} from "../../../environments/environment";
import {ArchivoSTL} from "../../model/archivos/archivo-stl.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as url from "url";
import {InterfaceProviderService} from "../../basic-entity-back/services/interface-provider.service";

class DialogData {
    archivo: ArchivoSTL
}

@Component({
    selector: 'app-visor-stl',
    templateUrl: './visor-stl.component.html',
    styleUrls: ['./visor-stl.component.scss']
})
export class VisorStlComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('objViewer') objViewer: ElementRef;

    constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private readonly _objViewerService: ObjViewerService,
                private readonly _renderer: Renderer2, private _interfaceProvider: InterfaceProviderService) {
    }

    ngOnInit(): void {
        // console.log(this.data.archivo)
    }

    ngAfterViewInit() {
        this._objViewerService.iniciarEscena(this._renderer, this.objViewer.nativeElement);
        const url = environment.apiUrl + '/' + this._interfaceProvider.interfaceForModel(this.data.archivo.modelType).endpoint + '/' + this.data.archivo.id + '/descargar';
        //const url = environment.uploaderUrl + '/' + this.data.archivo.id + '/descargar';
        this._objViewerService.loadObj(url, this.data.archivo.nombreFichero)
    }

    ngOnDestroy(): void {
        this._objViewerService.limpiarObj();
    }
}
