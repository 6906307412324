import {Resource} from '../../api/resource';
import {BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {ReadWrite, SearchType} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Usuario} from './usuario.model';
import {Idioma} from '../localizacion/idioma.model';
import {DireccionPersona} from './direccion-persona.model';
import {TelefonoPersona} from './telefono-persona.model';
import {NestedBehavior} from '../../basic-entity-back/property-type/nested-model-type';
import {Uri} from '../../api/uri';
import {ExactSearchFilter, PartialSearchFilter} from '../../basic-entity-back/filters/search-filter';

export abstract class Persona extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String(),
        sortable: true,
        nullable: true,
        order: 1,
        filters: [PartialSearchFilter]
    })
    private _nombre;

    @BasicProperty({
        name: 'Primer Apellido',
        type: PropertyType.String(),
        nullable: true,
        order: 3,
        filters: [PartialSearchFilter]
    })
    private _apellido1;

    @BasicProperty({
        name: 'Segundo Apellido',
        type: PropertyType.String(),
        nullable: true,
        order: 5,
        filters: [PartialSearchFilter]
    })
    private _apellido2;

    @BasicProperty({
        name: 'CIF/NIF',
        type: PropertyType.String(),
        searchable: SearchType.Exact,
        sortable: true,
        nullable: true,
        order: 4
    })
    private _cifNif;

    @BasicProperty({
        name: 'Fecha de creación',
        type: PropertyType.Date(),
        readWrite: ReadWrite.ReadOnly,
        nullable: true,
        sortable: true,
        order: 1
    })
    private _fechaCreacion;

    @BasicProperty({
        name: 'Fecha de modificación',
        type: PropertyType.Date(),
        readWrite: ReadWrite.ReadOnly,
        nullable: true,
        sortable: true,
        order: 1
    })
    private _fechaModificacion;

    @BasicProperty({
        name: 'Email',
        type: PropertyType.Email(),
        nullable: false,
        searchable: SearchType.Start,
        sortable: true,
        order: 5
    })
    private _email;

    @BasicProperty({
        name: 'Direcciones',
        tag: 'Direcciones',
        type: PropertyType.NestedModel(DireccionPersona, 'uriPersona', NestedBehavior.CreateOnly),
        array: true,
        order: 6
    })
    private _direcciones;

    @BasicProperty({
        name: 'Teléfonos',
        tag: 'Teléfonos',
        type: PropertyType.NestedModel(TelefonoPersona, 'persona', NestedBehavior.SendIriOnly),
        array: true,
        order: 6
    })
    private _telefonos;

    @BasicProperty({
        name: 'Usuario',
        tag: 'Usuario',
        nullable: true,
        type: PropertyType.NestedModel(Usuario, null, NestedBehavior.CreateOnly),
        order: 2
    })
    private _usuario;

    @BasicProperty({
        name: 'Idioma',
        type: PropertyType.Uri(Idioma),
        order: 10
    })
    private _idioma: Uri;

    @BasicProperty({
        name: 'Empresa',
        type: PropertyType.Boolean(),
        nullable: false
    })
    private _isEmpresa: boolean;

    @BasicProperty({
        name: 'Razón Social / Nombre',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly
    })
    private _nombreCompleto;

    @BasicProperty({
        name: 'Teléfonos',
        type: PropertyType.String(),
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly
    })
    get todosLosTelefonos(): string {
        return this.telefonos.map(tel => "+(" + tel.codigoArea + ") " + tel.telefono).join(', ')
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get nombre() {
        return this._nombre;
    }

    set nombre(value) {
        this._nombre = value;
    }

    get apellido1() {
        return this._apellido1;
    }

    set apellido1(value) {
        this._apellido1 = value;
    }

    get apellido2() {
        return this._apellido2;
    }

    set apellido2(value) {
        this._apellido2 = value;
    }

    get cifNif() {
        return this._cifNif;
    }

    set cifNif(value) {
        this._cifNif = value;
    }

    get fechaCreacion() {
        return this._fechaCreacion;
    }

    set fechaCreacion(value) {
        this._fechaCreacion = value;
    }

    get fechaModificacion() {
        return this._fechaModificacion;
    }

    set fechaModificacion(value) {
        this._fechaModificacion = value;
    }

    get email() {
        return this._email;
    }

    set email(value) {
        this._email = value;
    }

    get direcciones() {
        return this._direcciones;
    }

    set direcciones(value) {
        this._direcciones = value;
    }

    get telefonos() {
        return this._telefonos;
    }

    set telefonos(value) {
        this._telefonos = value;
    }

    get usuario() {
        return this._usuario;
    }

    set usuario(value) {
        this._usuario = value;
    }

    get idioma() {
        return this._idioma;
    }

    set idioma(value) {
        this._idioma = value;
    }

    get isEmpresa(): boolean {
        return this._isEmpresa;
    }

    set isEmpresa(value: boolean) {
        this._isEmpresa = value;
    }

    get nombreCompleto() {
        return this._nombreCompleto;
    }

    set nombreCompleto(value) {
        this._nombreCompleto = value;
    }
}

TelefonoPersona.PERSONA_MAP.type = PropertyType.Uri(Persona);
DireccionPersona.PERSONA_MAP.type = PropertyType.Uri(Persona);
