import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemsComponent} from './items/items.component';
import {MaterialModule} from "../../material/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserGroupsComponent} from './user-groups/user-groups.component';
import {UserGroupEditionComponent} from "../edition-list-dialog/user-group-edition.component";
import {BasicEntityFrontModule} from "../../basic-entity-front/basic-entity-front.module";

@NgModule({
    declarations: [
        ItemsComponent,
        UserGroupsComponent,
        UserGroupEditionComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        BasicEntityFrontModule,
        FormsModule
    ]
})
export class AdminModule {
}
