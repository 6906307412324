import {Component, HostListener, OnInit} from '@angular/core';
import {SessionService} from '../session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public errors: { general: string[], username: string[], password: string[] } = {general: [], username: [], password: []};
    private _returnUrl = '/';
    private readonly _IS_MAC = /Mac/.test(navigator.platform);
    public capsLock = false;

    constructor(
        protected loginService: SessionService,
        protected router: Router,
        private _activatedRoute: ActivatedRoute
    ) {
    }

    @HostListener('window:keydown', ['$event'])
    public detectCapsLock(event: KeyboardEvent) {
        if (event.getModifierState) {
            this.capsLock = event.getModifierState('CapsLock');
        } else {
            const charCode = event.charCode;
            const shiftKey = event.shiftKey;

            if (charCode >= 97 && charCode <= 122) {
                this.capsLock = shiftKey;
            } else if (charCode >= 65 && charCode <= 90 && !(shiftKey && this._IS_MAC)) {
                this.capsLock = !shiftKey;
            }
        }
    }

    public get sessionExpired() {
        return this.loginService.token.isExpired();
    }

    ngOnInit() {
        this._activatedRoute.queryParams.subscribe(params => {
            this._returnUrl = params['return'] || this._returnUrl;
            if (this.loginService.isLogged()) {
                this.router
                    .navigateByUrl(this._returnUrl)
                    .catch(reason => console.error(reason));
            } else {
                // Si estamos aquí por expiración mejor decirlo
                const token = this.loginService.token;
                if (token.isNotEmpty() && !token.isNotExpired()) {
                    this.errors.general.push('La sesión previa ha expirado.');
                }
            }
        });
    }

    login(form) {
        this.errors.general = [];
        this.errors.username = [];
        this.errors.password = [];

        if (!form.value.user) {
            this.errors.username = ['Requerido'];
        }
        if (!form.value.password) {
            this.errors.password = ['Requerido'];
        }

        if (this.errors.username.length > 0 || this.errors.password.length > 0) {
            return;
        }

        this.loginService.login(form.value.user, form.value.password).then((result) => {
            if (this.loginService.token.isValid()) {
                this.router.navigateByUrl(this._returnUrl).catch(reason => console.error(reason));
            } else {
                this.errors.general.push('Usuario no válido.');
            }
        }).catch((error: HttpErrorResponse) => {
            if (error.error && error.error.message) {
                this.errors.general.push(error.error.message);
            } else if (error.status === 401) {
                this.errors.general.push('Usuario o contraseña incorrectos.');
            } else {
                throw error;
            }
        });
    }


}
