import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BasicEntityInputComponent} from "../../basic-entity-input/basic-entity-input.component";
import {TypeStr} from "../../../basic-entity-back/property-type/type-str";
import {BaseDialog} from "../../basic-entity-table/base-dialog";
import {Resource} from "../../../api/resource";
import {BaseDialogData, BaseDialogResult} from "../../basic-entity-table/base-dialog-data";
import {EditionDialogComponent} from "../edition-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {Moment} from "moment";
import {Subscription} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {NestedBehavior} from "../../../basic-entity-back/property-type/nested-model-type";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

export interface FormManager {
    createControl(property: InternalPropertyMap, val: any, index: number | null);
}

@Component({
    selector: 'be-edition-row',
    templateUrl: './edition-row.component.html',
    styleUrls: ['./edition-row.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EditionRowComponent implements OnInit, OnDestroy {
    public readonly UNIQUE_CONTROL_NAME = BasicEntityInputComponent.UNIQUE_CONTROL_NAME;

    /** The property managed in this row */
    @Input() public property: InternalPropertyMap;
    /** The formGroup complete */
    @Input() public formGroup: UntypedFormGroup;
    /** The form manager */
    @Input() protected readonly formManager: FormManager;
    /** Date picker filter for the date picker to set (if it corresponds to the column) */
    @Input() public datePickerFilter: (date: Moment | null) => boolean = undefined;
    /** (Optional) Function to process every created model when this is an array and we click "add",
     * before sending it to the edition dialog */
    @Input() public modelInitialisation: (m: Resource) => Resource = m => {
        if (this.filters.length > 0) {
            m['filtrosAdicionales'] = this.filters;
        }
        return m;
    }
    @Input() public callbackFunctionForDeleteButton: (...args: any) => void;
    @Output() public deleteButton = new EventEmitter<UntypedFormArray>();
    @Input() public addButton = false;
    @Input() public filters = [];
    @Input() public selectedValue = null;
    @Input() public autoSelectFirst = true;

    @HostBinding('class.be-edition-row') public readonly classValue = true;
    @HostBinding('style.visibility') public visibility = 'visible';

    private _dependsOnSubscription: Subscription | null;

    constructor(private _fb: UntypedFormBuilder,
                private _matDialog: MatDialog,
                private _interfaceProvider: InterfaceProviderService) {
    }

    /**
     * If a column is an array, the template will call this function to add
     * a new element to the array when pressing the adding button
     * @param property - The column in which the add button has been pressed
     */
    public addClick(property: InternalPropertyMap) {
        if (property.type.toString() === TypeStr.NestedModel) {
            const nestedType = property.type.asNestedModel();
            if (nestedType.behavior === NestedBehavior.CreateOnly) {
                const interf = this._interfaceProvider.interfaceForModel(nestedType.modelType);
                const newModel = this.modelInitialisation(interf.serialiser.getEmptyModel());
                this._matDialog.open<BaseDialog<Resource>, BaseDialogData<Resource>, BaseDialogResult>(
                    nestedType.creationDialog || EditionDialogComponent,
                    {
                        disableClose: true,
                        autoFocus: true,
                        restoreFocus: true,
                        data: nestedType.baseDialogDataForModel(interf, newModel, true)
                    }
                ).afterClosed().subscribe(result => {
                    if (result === BaseDialog.RESULT_SHOULD_RELOAD) {
                        this.addArrayElement(property, newModel);
                    }
                });
                return;
            }
        }
        this.addArrayElement(property, "");
    }

    ngOnInit(): void {
        if (this.property.dependsOn != null) {
            const control = this.formGroup.get(this.property.dependsOn);
            if (control) {
                this._dependsOnSubscription = control.valueChanges.pipe(
                    map(valor => this.property.dependsOnContrary ? !valor : valor),
                    startWith([control.value]))
                    .subscribe(visible => {
                        if (visible) {
                            this.visibility = 'visible';
                            this.formGroup.get(this.property.modelKey).setValidators([Validators.required])
                        } else {
                            this.visibility = 'hidden';
                            this.formGroup.get(this.property.modelKey).clearValidators();
                            this.formGroup.get(this.property.modelKey).setValue(null);
                        }
                        this.formGroup.updateValueAndValidity({emitEvent: true});
                    });
                this.formGroup.get(this.property.modelKey).updateValueAndValidity({emitEvent: true});
            }
        }
        this.formGroup.get(this.property.modelKey).updateValueAndValidity({emitEvent: true});
    }

    ngOnDestroy(): void {
        if (this._dependsOnSubscription) {
            this._dependsOnSubscription.unsubscribe();
        }
    }

    /**
     * Add an element to the array of an array property (provided the value)
     * @param property
     * @param val
     */
    public addArrayElement(property: InternalPropertyMap, val: any) {
        const formArray = this.formGroup.get(property.modelKey) as UntypedFormArray;
        formArray.push(this.formManager.createControl(property, val, formArray.length));
    }

    /**
     * If the column is an array, the template will call this function to
     * delete elements from the array, when pressing the delete button.
     * @param property - The property in which the delete button has been pressed
     * @param index - The index of the row to delete
     */
    public deleteArrayElement(property: InternalPropertyMap, index: number) {
        if (this.callbackFunctionForDeleteButton) {
            (this.formGroup.get(property.modelKey) as UntypedFormArray).at(index).disable();
            this.callbackFunctionForDeleteButton(index);
            this.deleteButton.emit((this.formGroup.get(property.modelKey) as UntypedFormArray));
        } else {
            (this.formGroup.get(property.modelKey) as UntypedFormArray).removeAt(index);
        }
    }

    public deleteDisabled(property: InternalPropertyMap, index: number): boolean {
        return (this.formGroup.get(property.modelKey) as UntypedFormArray).at(index).disabled;

    }

    public getControls() {
        return (this.formGroup.controls[this.property.modelKey] as UntypedFormArray).controls
    }

    public getControlAsFormGroup(control: AbstractControl) {
        return control as UntypedFormGroup;
    }

    drop($event: CdkDragDrop<any, any>) {
        const posAnt = $event.previousIndex;
        const posAct = $event.currentIndex;

        moveItemInArray(this.getControls(), posAnt, posAct);

        this.getControls().forEach((c, i) => {
            c.value[BasicEntityInputComponent.UNIQUE_CONTROL_NAME].orden = i;
        })
    }

    hasOrder(control: AbstractControl<any>) {
        return control.value[BasicEntityInputComponent.UNIQUE_CONTROL_NAME].orden !== undefined && control.value[BasicEntityInputComponent.UNIQUE_CONTROL_NAME].orden !== null;
    }

}
