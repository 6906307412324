import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {TipoMaterial} from "./tipo-material.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {LoteMaterial} from "./lote.model";
import {ManagerCachingStrategy, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {BaseEntity} from "../base-entity.model";
import {ColorMaterial} from "./color.model";
import {MaterialTipoMaterialRel} from "./material-tipo-material-rel.model";
import {Resource} from "../../api/resource";

@BasicEntity({
    name: 'Materiales',
    endpoint: 'materials',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    type: 'Material',
    modelNameProperty: 'descripcion',
    managerCaching: ManagerCachingStrategy.PrecacheOnStart
})
export class Material extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
        sortable: true
    })
    private _id;

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String(),
        sortable: true
    })
    private _descripcion: string;

    @BasicProperty({
        name: 'Orden',
        type: PropertyType.Integer()
    })
    private _orden: number;

    @BasicProperty({
        name: 'Métodos de fabricación',
        type: PropertyType.Object(),
        readWrite: ReadWrite.ReadOnly,
        array: true
    })
    private _metodos;

    @BasicProperty({
        name: 'Tipos',
        type: PropertyType.NestedModel(MaterialTipoMaterialRel, 'material', NestedBehavior.CreateOnly),
        array: true
    })
    private _tiposMaterial;

    @BasicProperty({
        name: 'Colores',
        type: PropertyType.NestedModel(ColorMaterial, null, NestedBehavior.SendIriOnly),
        array: true
    })
    private _colores: ColorMaterial[];

    @BasicProperty({
        name: 'Marcas',
        type: PropertyType.String(),
        array: true
    })
    private _marcas: string[];

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {

        this._descripcion = value;
    }

    get colores(): ColorMaterial[] {
        return this._colores;
    }

    set colores(value: ColorMaterial[]) {
        this._colores = value;
    }

    get tiposMaterial(): TipoMaterial {
        return this._tiposMaterial;
    }

    set tiposMaterial(value) {
        this._tiposMaterial = value;
    }

    get orden(): number {
        return this._orden;
    }

    set orden(value: number) {
        this._orden = value;
    }

    get marcas(): string[] {
        return this._marcas;
    }

    set marcas(value: string[]) {
        this._marcas = value;
    }

    get metodos() {
        return this._metodos;
    }

    set metodos(value) {
        this._metodos = value;
    }
}

LoteMaterial.MATERIAL_MAP.type = PropertyType.Uri(Material);
MaterialTipoMaterialRel.MAP_MATERIAL.type = PropertyType.Uri(Material);
