import {Persona} from "./persona.model";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Uri} from "../../api/uri";
import {
    ManagerCachingStrategy,
    PropertyMap,
    ReadWrite
} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: 'Comerciales',
    endpoint: 'comercials',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'nombreCompleto',
    acceptsBulkIriSearch: true,
    type: 'Comercial'
})
export class Comercial extends Persona {
    constructor(iri?: Uri, entityType?: string) {
        super(iri, entityType);
    }

    @BasicProperty({
        name: 'Comisión',
        type: PropertyType.Float(0, 100),
        order: 100
    })
    private _comision = 0;


    public static readonly CLIENTE_MAP: PropertyMap = {
        name: 'Clientes',
        tag: 'Clientes',
        array: true,
        type: PropertyType.FutureUri('cliente.model.ts'),
        order: 101
    };
    @BasicProperty(Comercial.CLIENTE_MAP)
    private _clientes;

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String(),
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly
    })
    get nombreCompleto(): string {
        return `${this.nombre} ${(this.apellido1 ? this.apellido1 : '')}  ${(this.apellido2 ? this.apellido2 : '')}`
    }

    get comision(): number {
        return this._comision;
    }

    set comision(value: number) {
        this._comision = value;
    }

    get clientes() {
        return this._clientes;
    }

    set clientes(value) {
        this._clientes = value;
    }
}
