import {BasicEntity} from '../../basic-entity-back/annotations';
import {Persona} from './persona.model';
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: 'Proveedores',
    endpoint: 'proveedors',
    isPaginated: true,
    managerCaching: ManagerCachingStrategy.PrecacheOnStart,
    type: 'Proveedor',
    modelNameProperty: "nombre"
})
export class Proveedor extends Persona {


}
