import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from "../session/session.service";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Persona} from "../model/personas/persona.model";
import {Usuario} from "../model/personas/usuario.model";
import {Idioma} from "../model/localizacion/idioma.model";
import {startWith} from "rxjs/operators";
import {BehaviorSubject, Subscription} from "rxjs";
import {ErrorDisplayService} from "../basic-entity-front/services/error-display.service";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;
    private _loading = new BehaviorSubject<boolean>(false);

    public formGroup: UntypedFormGroup;
    public idiomaModel = Idioma;
    public cambiarPassword = false;
    public loading$ = this._loading.asObservable();
    public hayCambios = false;
    public debugMode = false;

    constructor(public sessionService: SessionService,
                private _errorDisplay: ErrorDisplayService,
                fb: UntypedFormBuilder) {
        this.debugMode = !environment.production;
        this.formGroup = fb.group({
            username: fb.control({value: '', disabled: true}),
            idioma: '',
            plainOldPassword: '',
            password: fb.control(''),
            repeatPassword: fb.control('', [Validators.required, (control: AbstractControl) => {
                if (control.parent
                    && control.value
                    && control.parent.get('password').value
                    && control.value !== control.parent.get('password').value) {
                    return {differentPasswords: 'Las contraseñas no coinciden.'};
                }
                return null;
            }])
        });
        this.formGroup.valueChanges.subscribe(v => {
            const currentProfile = this.sessionService.profile;
            if (currentProfile && currentProfile instanceof Persona) {
                this.hayCambios = v.idioma == null ?
                    currentProfile.idioma != null :
                    !v.idioma.isTheSame(currentProfile.idioma);
            }
        });
    }

    public guardar() {
        const profile = this.sessionService.profile;
        if (this.cambiarPassword && this.formGroup.get('repeatPassword').invalid) {
            return;
        }
        if (profile instanceof Persona) {
            profile.idioma = this.formGroup.get('idioma').value;
            if (this.cambiarPassword) {
                profile.usuario.plainOldPassword = this.formGroup.get('plainOldPassword').value;
                profile.usuario.plainPassword = this.formGroup.get('password').value;
            }
        } else if (profile instanceof Usuario) {
            if (this.cambiarPassword) {
                // profile.plainOldPassword = this.formGroup.get('plainOldPassword').value;
                // profile.password = this.formGroup.get('password').value;
            }
        }
        this._loading.next(true);
        this.sessionService.changeProfile(profile)
            .then(() => {
                this.hayCambios = false;
                this.cambiarPassword = false;
                this.formGroup.patchValue({password: '', repeatPassword: ''});
                // Loading queda a true hasta que se recargue el perfil (ver en ngOnInit)
            })
            .catch(err => {
                this._errorDisplay.displayError(err);
                this._loading.next(false);
            });
    }

    ngOnInit() {
        this._subscription = this.sessionService.profileChanges
            .pipe(startWith('')).subscribe(() => {
                const profile = this.sessionService.profile;
                let username = '';
                let idioma = null;

                if (profile instanceof Persona) {
                    username = profile.usuario.username;
                    idioma = profile.idioma;
                } else if (profile instanceof Usuario) {
                    username = profile.username;
                }

                this.formGroup.patchValue({username: username, idioma: idioma});
                this.hayCambios = false;
                this._loading.next(false);
            });
    }

    ngOnDestroy(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }
}
