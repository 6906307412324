import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {EditionDialogComponent} from "../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {FormaPagoCliente} from "../../../model/tesoreria/formas-pago/forma-pago-cliente.model";
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BaseDialogData} from "../../../basic-entity-front/basic-entity-table/base-dialog-data";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {FormBuilder} from "@angular/forms";
import {ErrorDisplayService} from "../../../basic-entity-front/services/error-display.service";
import {ApiModuleFactory} from "../../../api/api-module-factory.service";
import {LoteMaterial} from "../../../model/materiales/lote.model";
import {FilterAndData} from "../../../api/filter-list";
import {ExactSearchFilter} from "../../../basic-entity-back/filters/search-filter";
import {CuentaBancariaCliente} from "../../../model/tesoreria/cuentas-bancarias/cuenta-bancaria-cliente.model";

@Component({
    selector: 'app-formas-pago-cliente-edition',
    templateUrl: './formas-pago-cliente-edition.component.html',
    styleUrls: ['./formas-pago-cliente-edition.component.scss']
})
export class FormasPagoClienteEditionComponent extends EditionDialogComponent<FormaPagoCliente> implements OnInit, OnDestroy {
    public readonly propMap: Map<string, InternalPropertyMap>;
    public cargando = false;
    filtrosCuentaBancaria: any[];
    cuentaBancaria = null;

    constructor(@Inject(MAT_DIALOG_DATA) public data: BaseDialogData<FormaPagoCliente>,
                private dialogRef: MatDialogRef<EditionDialogComponent<FormaPagoCliente>>, private _dialogService: MatDialog,
                private apiFactory: ApiModuleFactory, public interfaceProvider: InterfaceProviderService, private fb: FormBuilder,
                private _myErrorDisplay: ErrorDisplayService) {
        super(data, dialogRef, apiFactory, interfaceProvider, fb, _myErrorDisplay);
        const propMap: [string, InternalPropertyMap][] = this.properties.map(p => [p.modelKey, p]) as [string, InternalPropertyMap][];
        this.propMap = new Map(propMap);

        if (data.model.cuentaBancariaCliente !== undefined) {
            this.cuentaBancaria = data.model.cuentaBancariaCliente;
        }

        // Filtrado de cuentas bancarias del cliente
        const cliente = data.model.isTemporalEntity ? data.model['filtrosAdicionales'][0].data : data.model.cliente
        const filtros = [];
        const clienteProp = this.interfaceProvider.interfaceForModel(CuentaBancariaCliente).mappingModelToApi['cliente'];
        filtros.push(new FilterAndData(ExactSearchFilter, cliente, clienteProp))
        this.filtrosCuentaBancaria = filtros;

    }

    ngOnInit() {
        super.ngOnInit();

    }

    public prop(key: string): InternalPropertyMap | undefined {
        return this.properties.find(p => p.modelKey === key);
    }

    public aceptar() {
        this.acceptDialog();
    }

    public cancel() {
        this.closeDialog(false);
    }
}
