import {Component} from '@angular/core';
import {BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Zona} from "../../../model/localizacion/zona.model";
import {ZonaEditionComponent} from "../../edition-list-dialog/zona-edition.component";

@Component({
    selector: 'app-zona',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model" [editionDialog]="dialog"></be-table>'
})
export class ZonaComponent {
    public model = Zona;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        {key: 'id', hiddenDisplayable: true},
        {key: 'nombre', weight: 2},
        {key: 'diasTransito', weight: 2},


    ];
    public dialog = ZonaEditionComponent;
}
