import {ReadWrite, SearchType} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {Zona} from "./zona.model";
import {Uri} from "../../api/uri";
import {Festivo} from "./festivo.model";
import {BaseEntity} from "../base-entity.model";

@BasicEntity({
    name: 'Paises',
    endpoint: 'pais',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    autogeneratedId: false,
    modelNameProperty: 'nombreDefault',
    acceptsBulkIriSearch: true,
    type: 'Pais'
})
export class Pais extends BaseEntity {
    @BasicProperty({
        name: 'Código',
        isId: true,
        type: PropertyType.String(),
        searchable: SearchType.Exact,
        sortable: true
    })
    private _codigoPais: string;

    @BasicProperty({
        name: 'Código de área',
        type: PropertyType.PhoneNumber(),
        searchable: SearchType.Partial,
        sortable: true,
        nullable: true
    })
    private _codArea: string;

    @BasicProperty({
        name: 'Nombres',
        type: PropertyType.Translation()
    })
    private _nombres: object = {};

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly,
        searchable: SearchType.Partial,
        paramName: 'nombres',
        dontReadFromApi: true
    })
    public get nombreDefault(): string {
        return this._nombres['es'] || 'Desconocido';
    }
    public set nombreDefault(value) {
    }

    @BasicProperty({
        name: 'Festivos',
        type: PropertyType.NestedModel(Festivo),
        array: true
    })
    private _festivos: object = [];

    @BasicProperty({
        name: 'Zona',
        type: PropertyType.Uri(Zona),
        // sortable: true, TODO: parametro para ordenar separado de busqueda
        // paramName: 'zona.id',
        nullable: true
    })
    private _zona: Uri;

    get codigoPais(): string {
        return this._codigoPais;
    }

    set codigoPais(value: string) {
        this._codigoPais = value;
    }

    get codArea(): string {
        return this._codArea;
    }

    set codArea(value: string) {
        this._codArea = value;
    }

    get nombres(): object {
        return this._nombres;
    }

    set nombres(value: object) {
        this._nombres = value;
    }

    get festivos(): object {
        return this._festivos;
    }

    set festivos(value: object) {
        this._festivos = value;
    }

    get zona() {
        return this._zona;
    }

    set zona(value) {
        this._zona = value;
    }
}
