import {Component, Inject, OnInit} from '@angular/core';
import {EditionDialogComponent} from "../../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {Proveedor} from "../../../../model/personas/proveedor.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseDialogData} from "../../../../basic-entity-front/basic-entity-table/base-dialog-data";
import {ApiModuleFactory} from "../../../../api/api-module-factory.service";
import {InterfaceProviderService} from "../../../../basic-entity-back/services/interface-provider.service";
import {UntypedFormArray, UntypedFormBuilder} from "@angular/forms";
import {ErrorDisplayService} from "../../../../basic-entity-front/services/error-display.service";
import {InternalPropertyMap} from "../../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {BasicEntityInputComponent} from "../../../../basic-entity-front/basic-entity-input/basic-entity-input.component";

@Component({
  selector: 'app-proveedores-edition',
  templateUrl: './proveedores-edition.component.html',
  styleUrls: ['./proveedores-edition.component.scss']
})
export class ProveedoresEditionComponent extends EditionDialogComponent<Proveedor> implements OnInit {
    public readonly propMap: Map<string, InternalPropertyMap>;
    public isEmpresa: boolean;
    public telefonos: UntypedFormArray;

    constructor(@Inject(MAT_DIALOG_DATA) data: BaseDialogData<Proveedor>, dialogRef: MatDialogRef<EditionDialogComponent<Proveedor>>, apiFactory: ApiModuleFactory,
                public interfaceProvider: InterfaceProviderService, fb: UntypedFormBuilder, private _errorDisp: ErrorDisplayService) {
        super(data, dialogRef, apiFactory, interfaceProvider, fb, _errorDisp);
        const propMap: [string, InternalPropertyMap][] = this.properties.map(p => [p.modelKey, p]) as [string, InternalPropertyMap][];
        this.propMap = new Map(propMap);
    }

    ngOnInit(): void {
        console.log(this.formGroup)
        if (this.isNew) {
            this.formGroup.get('isEmpresa').setValue(false);
        }
    }

    aceptar() {
        this.formManager.formToModel(this.formGroup.value);
        if (this.formGroup.valid) {
            const manager = this.interfaceProvider.managerForModel(Proveedor);
            const nuevo = manager.loader.entityInterface.serialiser.getEmptyModel();
            if (this.isNew) {
                this.formManager.formToModel(nuevo);
                manager.add(nuevo).subscribe(
                    result => {
                        this._errorDisp.displayRaw("Guardado correctamente", 1000);
                        this.closeDialog(true);
                    },
                    error => {
                        this._errorDisp.displayRaw("Algo ha ido mal: " + error.error['hydra:description'])
                    })
            } else {
                this.formManager.formToModel(this.model);
                manager.update(this.model).subscribe(
                    result => {
                        this._errorDisp.displayRaw("Guardado correctamente", 1000);
                        this.closeDialog(true);
                    }
                );
            }
        }
    }

    cancelar() {
        this.closeDialog(false);
    }

    public property(key: string): InternalPropertyMap {
        return this.propMap.get(key);
    }
}
