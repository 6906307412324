import {Component} from "@angular/core";
import {Usuario} from "../../../model/personas/usuario.model";
import {EditionDialogComponent} from "../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {BasicEntityTableColumnDescriptionOrString, ColumnJustification} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";


@Component({
    selector: 'app-usuario-add',
    template: '<be-table [modelType]="model" [editionDialog]="dialog" [columnsToDisplay]="displayedColumns" id="usuarios"></be-table>'
})
export class UsuariosComponent {
    public model = Usuario;
    public dialog = EditionDialogComponent;
    public displayedColumns: BasicEntityTableColumnDescriptionOrString[] = [
        {key: 'id', hiddenDisplayable: true},
        {key: 'username', weight: 5},
        'plainPassword',
        { key: 'locale', justification: ColumnJustification.Center},
        { key: 'enabled', weight: 1, justification: ColumnJustification.Center},
        {key: 'groups', weight: 2},
        {key: 'roles', weight: 2}
    ];
}
