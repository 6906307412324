import {Component, OnInit} from '@angular/core';
import {
    BasicEntityTableColumnDescriptionOrString
} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Municipio} from "../../../model/localizacion/municipio.model";
import {EditionDialogComponent} from "../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {Mercurable} from "../../../basic-entity-front/mercure/mercurable";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {SessionService} from "../../../session/session.service";
import {Subject} from "rxjs";

@Component({
    selector: 'app-municipio',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model" [editionDialog]="dialog" ></be-table>'
})
export class MunicipioComponent implements OnInit {

    public model = Municipio;
    public dialog = EditionDialogComponent;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        'id',
        'nombre',
        'alias',
        'provincia',
        {key: 'zonaPropia', hiddenDisplayable: true},
        'zona'
    ];

    constructor(private _intf: InterfaceProviderService) {
        // super(_intf.interfaceForModel(Municipio));
    }

    ngOnInit() {
        // this.onUpdateItem = this.getUpdateItem();
    }
}
