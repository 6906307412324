import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Trabajo} from "./trabajo.model";
import {ManagerCachingStrategy, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Opcion} from "./opcion.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {ExactSearchFilter, PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";

@BasicEntity({
    name: 'SubTrabajo',
    endpoint: 'sub_trabajos',
    type: "SubTrabajo",
    managerCaching: ManagerCachingStrategy.Lazy,
    isPaginated: false,
    modelNameProperty: 'descripcion'
})
export class SubTrabajo extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
        sortable: true
    })
    private _id: number;

    @BasicProperty({
        name: 'Descripción',
        type: PropertyType.String(),
        filters: [PartialSearchFilter]
    })
    private _descripcion: string;

    @BasicProperty({
        name: 'Trabajos Asociados',
        type: PropertyType.Uri(Trabajo),
        array: true,
    })
    private _trabajos: Trabajo[];

    @BasicProperty({
        name: 'Trabajos Asociados',
        keyInApi: 'trabajos.descripcion',
        type: PropertyType.String(),
        array: true,
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly,
        filters: [PartialSearchFilter]
    })
    get trabajosAsociados() {
        return this._trabajos ? this._trabajos.map(t => t.descripcion) : []
    }


    @BasicProperty({
        name: 'Métodos Asociados',
        keyInApi: 'trabajos.metodos',
        type: PropertyType.NestedModel(MetodoFabricacion),
        array: true,
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly,
        filters: [ExactSearchFilter]
    })
    get metodosFabricacion() {
        return this._trabajos ? this._trabajos.map(t => t.descripcion) : []
    }

    @BasicProperty({
        name: 'Subtrabajo Obligado (SubOb)',
        type: PropertyType.NestedModel(SubTrabajo, null, NestedBehavior.SendIriOnly),
        nullable: true,
        filters: [ExactSearchFilter]
    })
    private _subTrabajoObligado: SubTrabajo;

    @BasicProperty({
        name: 'Uds. Mínimas SubOb',
        type: PropertyType.Integer(),
        nullable: true,
        dependsOn: 'subTrabajoObligado'
    })
    private _unidadesMinimasSubTrabajoObligado: number = 0;

    @BasicProperty({
        name: 'Unitaria',
        type: PropertyType.Boolean()
    })
    private _unitaria: boolean = false;

    @BasicProperty({
        name: 'Opciones',
        type: PropertyType.NestedModel(Opcion, null, NestedBehavior.CreateAndPick),
        array: true
    })
    private _opciones: Opcion[]

    @BasicProperty({
        name: 'Carpeta de destino (Validaciones)',
        type: PropertyType.String(),
        nullable: true
    })
    private _carpetaDestino;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get descripcion(): string {
        return this._descripcion;
    }

    set descripcion(value: string) {
        this._descripcion = value;
    }

    get trabajos(): Trabajo[] {
        return this._trabajos;
    }

    set trabajos(value: Trabajo[]) {
        this._trabajos = value;
    }

    get subTrabajoObligado(): SubTrabajo {
        return this._subTrabajoObligado;
    }

    set subTrabajoObligado(value: SubTrabajo) {
        this._subTrabajoObligado = value;
    }

    get unidadesMinimasSubTrabajoObligado(): number {
        return this._unidadesMinimasSubTrabajoObligado;
    }

    set unidadesMinimasSubTrabajoObligado(value: number) {
        this._unidadesMinimasSubTrabajoObligado = value;
    }

    get unitaria(): boolean {
        return this._unitaria;
    }

    set unitaria(value: boolean) {
        this._unitaria = value;
    }

    get opciones(): Opcion[] {
        return this._opciones;
    }

    set opciones(value: Opcion[]) {
        this._opciones = value;
    }

    get carpetaDestino() {
        return this._carpetaDestino;
    }

    set carpetaDestino(value) {
        this._carpetaDestino = value;
    }
}
