import {BasicEntity, BasicProperty} from "../../../basic-entity-back/annotations";
import {CuentaBancaria} from "./cuenta-bancaria.model";
import {
    ManagerCachingStrategy,
    PropertyMap,
    ReadWrite
} from "../../../basic-entity-back/basic-entity-interface/mapping-external";
import {PropertyType} from "../../../basic-entity-back/property-type/property-type";

@BasicEntity({
    name: 'Cuenta bancaria del cliente',
    endpoint: 'cuenta_bancaria_clientes',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    type: "CuentaBancariaCliente",
    modelNameProperty: 'lectura',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class CuentaBancariaCliente extends CuentaBancaria {

    public static readonly CLIENTE_MAP: PropertyMap = {
        name: 'Cliente',
        type: PropertyType.FutureUri('cliente.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    @BasicProperty(CuentaBancariaCliente.CLIENTE_MAP)
    private _cliente;


    get cliente() {
        return this._cliente;
    }

    set cliente(value) {
        this._cliente = value;
    }
}
