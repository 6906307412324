<div class="container">
    <div class="listado_telefonos" *ngIf="telefonosArray.length > 0; else sintelefonos">
        <div class="telefono">
            <table>
                <th class="column" style="width: 35%">Dpto.</th>
                <th class="column"  style="width: 40%">Teléfono</th>
                <th class="column"  style="width: 20%">Extensión</th>
                <th class="actions"></th>
                <tr *ngFor="let telefono of telefonosArray; let i = index">
                    <td class="overflow">{{telefono.departamento}}</td>
                    <td class="overflow">+{{telefono.codigoArea}} {{telefono.telefono}}</td>
                    <td style="text-align: center">{{telefono.extension}}</td>
                    <td class="botones">
                        <button mat-icon-button (click)="addEdit(telefono, i)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button (click)="delete(telefono, i)"><mat-icon>delete</mat-icon></button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <ng-template #sintelefonos>
        <span class="notel">No existen teléfonos por el momento</span>
    </ng-template>
    <div class="add_button">
        <button mat-raised-button (click)="addEdit()">Añadir</button>
    </div>
</div>

