/*
 * This file is a part of BEI, a software developed by Nedaware SL.
 * This copy is licensed for use exclusively in Red Activa Integral S.L. systems.
 * It can be modified or removed without previous communication meanwhile its use is
 * for this company.
 *
 * Este fichero forma parte de BEI, un software desarrollado por Nedaware SL.
 * Esta copia está licenciada para su uso exclusivo en sistemas de Red Activa Integral S.L.
 * Podrá ser modificado o eliminado sin previo aviso siempre que su uso quede
 * restringido a esta empresa.
 */

import {Caster} from './caster';
import * as moment from 'moment-timezone';

/**
 * Casts between moments and strings
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class MomentCaster implements Caster<moment.Moment, string> {
    /**
     * @param _format - The casters to output when passing from
     * moment to string
     */
    constructor(private _format: string = null) {
    }

    fromModel(modelValue: moment.Moment | null): string | null {
        if (!modelValue || !moment.isMoment(modelValue)) {
            return null;
        }

        const res = this._format ? modelValue.local(true).format(this._format) : modelValue.local(true).format('Y-MM-DDTHH:mm:ss');
        return res;
    }

    toModel(apiValue: string | null): moment.Moment | null {
        if (!apiValue) {
            return null;
        }

        const result = moment(apiValue, this._format).tz('Europe/Madrid');

        // const fechaAPI = moment.parseZone(apiValue);
        // const offsetApi = fechaAPI.utcOffset();
        // const offsetCliente = moment().utcOffset();
        // const result = fechaAPI.utcOffset(offsetApi * 2 - offsetCliente);


        return result.isValid() ? result.local(true) : null;
    }
}
