<div class="dialog-cell field-name">
    <label [attr.for]="property.modelKey">{{ property.name }}</label>
</div>
<div class="dialog-cell field-value" [formGroup]="formGroup">
    <be-input *ngIf="!property.array; else arrayTemplate" [datePickerFilter]="datePickerFilter"
              [controlName]="property.modelKey" [property]="property" [formGroup]="formGroup" [filters]="filters"
              [selectedValue]="selectedValue" [autoSelectFirst]="autoSelectFirst">
    </be-input>

    <ng-template #arrayTemplate>
        <ng-container [formArrayName]="property.modelKey">
            <div cdkDropList (cdkDropListDropped)="drop($event)" class="array-list">
                <div *ngFor="let control of getControls(); let i = index">
                    <div *ngIf="!hasOrder(control)" class="array-input-item">
                        <be-input [property]="property" [formGroup]="getControlAsFormGroup(control)"
                                  [controlName]="UNIQUE_CONTROL_NAME" [datePickerFilter]="datePickerFilter"
                                  [forceRequired]="true"></be-input>
                        <button mat-raised-button color="warn" (click)="deleteArrayElement(property, i)"
                                *ngIf="!deleteDisabled(property, i)">
                            Borrar
                        </button>
                    </div>
                    <div cdkDrag *ngIf="hasOrder(control)" class="array-input-item">
                        <be-input [property]="property" [formGroup]="getControlAsFormGroup(control)"
                                  [controlName]="UNIQUE_CONTROL_NAME" [datePickerFilter]="datePickerFilter"
                                  [forceRequired]="true"></be-input>
                        <button mat-raised-button  color="warn" (click)="deleteArrayElement(property, i)"
                                *ngIf="!deleteDisabled(property, i)">
                            Borrar
                        </button>
                        <mat-icon>reorder</mat-icon>
                    </div>
                </div>
            </div>
            <button mat-raised-button [ngClass]="getControls().length > 0 ? 'btn-add' : 'btn-add-no-items'"
                    (click)="addClick(property)" *ngIf="formGroup.controls[property.modelKey].enabled">
                Añadir
            </button>
        </ng-container>
    </ng-template>
</div>
