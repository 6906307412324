import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {TABLES} from "./tables";
import {InicioComponent} from "./pages/inicio/inicio.component";
import {LoginComponent} from "./session/login/login.component";
import {SessionGuard} from "./session/session.guard";
import {ItemsComponent} from "./pages/admin/items/items.component";
import {ProfileComponent} from "./profile/profile.component";

const routes: Routes = [
    ...TABLES,
    {
        path: 'inicio',
        component: InicioComponent,
        canActivate: [SessionGuard]
    },
    {
        path: 'items',
        component: ItemsComponent,
        canActivate: [SessionGuard],
        data: {expectedRole: 'ROLE_ADMIN', title: 'Items'}
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [SessionGuard]
    },
    {
        path: 'materiales',
        loadChildren: () => import('./pages/materiales/materiales.module').then(m => m.MaterialesModule)
    },
    {
        path: 'almacen',
        loadChildren: () => import('./pages/almacen/almacen.module').then(m => m.AlmacenModule)
    },
    {
        path: 'trabajos',
        loadChildren: () => import('./pages/trabajos/trabajos.module').then(m => m.TrabajosModule)
    },
    {
        path: 'pedidos',
        loadChildren: () => import('./pages/pedidos/pedidos.module').then(m => m.PedidosModule)
    },
    {
        path: 'solicitudes',
        loadChildren: () => import('./pages/solicitudes/solicitudes.module').then(m => m.SolicitudesModule)
    },
    {
        path: 'facturacion',
        loadChildren: () => import('./pages/facturacion/facturacion.module').then(m => m.FacturacionModule)
    },
    {
        path: 'produccion',
        loadChildren: () => import('./pages/produccion/produccion.module').then(m => m.ProduccionModule)
    },
    {
        path: 'logistica',
        loadChildren: () => import('./pages/logistica/logistica.module').then(m => m.LogisticaModule)
    },
    {
        path: 'tesoreria',
        loadChildren: () => import('./pages/tesoreria/tesoreria.module').then(m => m.TesoreriaModule)
    },
    {
        path: 'informes',
        loadChildren: () => import('./pages/informes/informes.module').then(m => m.InformesModule)
    },
    {
        path: 'incidencias',
        loadChildren: () => import('./pages/incidencias/incidencias.module').then(m => m.IncidenciasModule)
    },
    {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
    }];

@NgModule({
    imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
