<be-dialog-shell class="dialog-shell" [title]="title" (close)="closeDialog()" [closeButton]="!saving"
                 [showFooter]="showSaveAndCancelButtons"
                 [className]="tags.size > 1 ? 'fixed-size' : null">
    <form autocomplete="off" (submit)="$event.preventDefault();" [formGroup]="formGroup">

        <!--        <be-edition-tab [propertiesToShow]="colsForTag('Principal')" [formGroup]="formGroup"-->
        <!--                        [formManager]="formManager" [cien]="true"></be-edition-tab>-->

        <h3 class="referencia">REFERENCIA: <i>{{ referencia }}</i></h3>

        <be-edition-row [class]="'dialog-row cien'"
                        [property]="prop('cuentaBancariaCliente')" [formGroup]="formGroup"
                        [formManager]="formManager" [filters]="filtrosCuentaBancaria"
                        [selectedValue]="cuentaBancaria">
        </be-edition-row>

        <be-edition-row [class]="'dialog-row cien'"
                        [property]="prop('pagoRecurrente')" [formGroup]="formGroup"
                        [formManager]="formManager">
        </be-edition-row>

        <div class="dialog-row" *ngIf="!data.model.isTemporalEntity">
            <be-file-upload [disableButton]="false" [buttonName]="'Seleccionar STL'"
                            [modelType]="tipoArchivo" [property]="prop('archivoFirmado')" [isInput]="true"
                            [seleccionarPrimero]="true" [formGroup]="formGroup"
                            [listaArchivos]="!data.model.isTemporalEntity && data.model.archivoFirmado ? [data.model.archivoFirmado] : []"
                            [controlName]="prop('archivoFirmado').modelKey"
                            [parentModelType]="data.model.modelType.name"
                            (fileRemovedEmitter)="eliminado()"
                            (fileUploadedEmmiter)="formGroup.get('archivoFirmado').setValue($event[0])"
                            [archivoSeleccionado]="!data.model.isTemporalEntity ? data.model.archivoFirmado : null"></be-file-upload>
        </div>
    </form>
    <dialog-actions>
        <button mat-button (click)="cancel()" [disabled]="saving">Cancelar</button>
        <button mat-flat-button *ngIf="modified && !cargando && !firmado" (click)="guardarYDescargar()" color="primary"
                [disabled]="!formGroup.valid || saving">
            <mat-icon>receipt</mat-icon>
            Generar y descargar mandato
        </button>
        <button mat-button (click)="aceptar()"
                [disabled]="modified && !formGroup.valid && firmado">Guardar
        </button>
    </dialog-actions>
</be-dialog-shell>
