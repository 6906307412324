<be-dialog-shell [title]="title" (close)="closeDialog(false)" [showFooter]="showSaveAndCancelButtons" [className]="tags.size > 1 ? 'fixed-size' : null">
    <form [formGroup]="formGroup">
        <mat-tab-group>
            <mat-tab label="Principal">
                <div class="contenedor">
                    <div class="grupo">
                        <div class="empresa_idioma_cont">
                            <div class="empresa">
                                <label>Empresa:</label>
                                <be-input  [formGroup]="formGroup" [property]="property('isEmpresa')" [controlName]="property('isEmpresa').modelKey"></be-input>
                            </div>
                            <be-input [formGroup]="formGroup" [property]="property('idioma')" [controlName]="property('idioma').modelKey"></be-input>
                        </div>
                        <ng-container *ngIf="formGroup.get('isEmpresa').value; else persona">
                            <be-input title="Denominación" [formGroup]="formGroup" [property]="property('nombre')" [controlName]="property('nombre').modelKey"></be-input>
                        </ng-container>
                        <ng-template #persona>
                            <be-input  [formGroup]="formGroup" [property]="property('nombre')" [controlName]="property('nombre').modelKey"></be-input>
                            <be-input  [formGroup]="formGroup" [property]="property('apellido1')" [controlName]="property('apellido1').modelKey"></be-input>
                            <be-input  [formGroup]="formGroup" [property]="property('apellido2')" [controlName]="property('apellido2').modelKey"></be-input>
                        </ng-template>
                        <be-input  [formGroup]="formGroup" [property]="property('cifNif')" [controlName]="property('cifNif').modelKey"></be-input>
                        <be-input  [formGroup]="formGroup" [property]="property('email')" [controlName]="property('email').modelKey"></be-input>
                    </div>

                </div>
            </mat-tab>

            <mat-tab label="Direcciones">
                <div class="grupo">
                    <app-proveedor-direcciones [formGroup]="formGroup"></app-proveedor-direcciones>
                </div>
            </mat-tab>

            <mat-tab label="Teléfonos">
                <div class="grupo telefonos">
                    <app-proveedor-telefonos [formGroup]="formGroup"></app-proveedor-telefonos>
                </div>
            </mat-tab>


        </mat-tab-group>
    </form>
    <dialog-actions>
        <button mat-button class="btn_cancelar" (click)="cancelar()" [disabled]="saving">Cancelar</button>
        <button mat-flat-button  class="btn_guardar" *ngIf="shouldManageSaving && modified" (click)="aceptar()" color="primary" [disabled]="!formGroup.valid || saving">
            <mat-icon>save</mat-icon>Guardar
        </button>
    </dialog-actions>
</be-dialog-shell>
