import {Resource} from "../../api/resource";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {ManagerCachingStrategy, PropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {DireccionCentro} from "./centro-produccion-direccion.model";
import {Empleado} from "../personas/empleado.model";
import {CuentaBancariaCentro} from "../tesoreria/cuentas-bancarias/cuenta-bancaria-centro.model";
import {Uri} from "../../api/uri";
import {IRI_PROPERTY, TYPE_PROPERTY} from "../../basic-entity-back/basic-entity-interface/mapping-internal";
import {FormaPago} from "../tesoreria/formas-pago/forma-pago.model";

@BasicEntity({
    name: 'Centros de Producción',
    endpoint: 'centro_produccions',
    isPaginated: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNameProperty: 'nombre'
})
export class CentroProduccion extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String()
    })
    private _nombre: string;

    @BasicProperty({
        name: 'Razón social',
        type: PropertyType.String()
    })
    private _razonSocial: string;

    @BasicProperty({
        name: 'CIF',
        type: PropertyType.String(9, 9)
    })
    private _cif: string;

    @BasicProperty({
        name: 'Direcciones',
        type: PropertyType.NestedModel(DireccionCentro, null, NestedBehavior.CreateAndPick),
        array: true
    })
    private _direcciones;

    @BasicProperty({
        name: 'Empleados',
        type: PropertyType.Uri(Empleado),
        array: true
    })
    private _empleados;

    public static readonly METODOS_MAP: PropertyMap = {
        name: 'Métodos de Fabricación',
        type: PropertyType.FutureUri('metodo-fabricacion.model.ts'),
        array: true
    }
    @BasicProperty(CentroProduccion.METODOS_MAP)
    private _metodos: MetodoFabricacion[];

    @BasicProperty({
        name: 'Cuentas Bancarias',
        type: PropertyType.NestedModel(CuentaBancariaCentro, null, NestedBehavior.CreateOnly, [TYPE_PROPERTY, IRI_PROPERTY, 'centroProduccion', 'fechaCreacion', 'creadoPor', 'modificadoPor', 'version', 'fechaModificacion', 'bloqueadoPor']),
        array: true,
        nullable: true,
        order: 3
    })
    private _cuentasBancariasReceptoras: Uri;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get nombre(): string {
        return this._nombre;
    }

    set nombre(value: string) {
        this._nombre = value;
    }

    get direcciones() {
        return this._direcciones;
    }

    set direcciones(value) {
        this._direcciones = value;
    }

    get empleados() {
        return this._empleados;
    }

    set empleados(value) {
        this._empleados = value;
    }

    get metodos(): MetodoFabricacion[] {
        return this._metodos;
    }

    set metodos(value: MetodoFabricacion[]) {
        this._metodos = value;
    }

    get cuentasBancariasReceptoras(): Uri {
        return this._cuentasBancariasReceptoras;
    }

    set cuentasBancariasReceptoras(value: Uri) {
        this._cuentasBancariasReceptoras = value;
    }

    get cif(): string {
        return this._cif;
    }

    set cif(value: string) {
        this._cif = value;
    }

    get razonSocial(): string {
        return this._razonSocial;
    }

    set razonSocial(value: string) {
        this._razonSocial = value;
    }
}

Empleado.CENTRO_MAP.type = PropertyType.Uri(CentroProduccion)
DireccionCentro.CENTRO_MAP.type = PropertyType.Uri(CentroProduccion)
CuentaBancariaCentro.CENTRO_MAP.type = PropertyType.Uri(CentroProduccion)
