import {BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Resource} from "../../api/resource";

export class Telefono extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _idTelefono;

    @BasicProperty({
        name: 'Código de área',
        type: PropertyType.String()
    })
    private _codigoArea;

    @BasicProperty({
        name: 'Teléfono',
        type: PropertyType.PhoneNumber()
    })
    private _telefono;

    @BasicProperty({
        name: 'Extensión',
        type: PropertyType.String(),
        nullable: true
    })
    private _extension;

    public get completo(): string {
        return `+${this._codigoArea} ${this._telefono}`;
    }

    get idTelefono() {
        return this._idTelefono;
    }

    set idTelefono(value) {
        this._idTelefono = value;
    }

    get codigoArea() {
        return this._codigoArea;
    }

    set codigoArea(value) {
        this._codigoArea = value;
    }

    get telefono() {
        return this._telefono;
    }

    set telefono(value) {
        this._telefono = value;
    }

    get extension() {
        return this._extension;
    }

    set extension(value) {
        this._extension = value;
    }
}
