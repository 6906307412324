import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {
    ManagerCachingStrategy,
    PropertyMap,
    ReadWrite
} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Resource} from "../../api/resource";
import {ExactSearchFilter, PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {Material} from "./material.model";
import {TipoMaterial} from "./tipo-material.model";
import {Uri} from "../../api/uri";
import {ColorMaterial} from "./color.model";

@BasicEntity({
    name: 'Lotes',
    endpoint: 'lote_materials',
    isPaginated: false,
    type: 'LoteMaterial',
    modelNameProperty: 'resumen',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class LoteMaterial extends Resource {
    static readonly ESTADOS_LOTE = {
        NUEVO: 'Nuevo',
        EN_USO: 'Activado',
        ALMACENADO: 'Desactivado',
    };

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
        sortable: true
    })
    private _id;

    @BasicProperty({
        name: 'Nombre/Identificador',
        type: PropertyType.String(),
        filters: [PartialSearchFilter],
        sortable: true,
        nullable: true,
        order: 0
    })
    private _descripcion;

    @BasicProperty({
        name: 'Estado',
        type: PropertyType.Choice(...Object.values(LoteMaterial.ESTADOS_LOTE)),
        array: false,
        readWrite: ReadWrite.ReadOnly,
        sortable: true,
        order: 10
    })
    private _estado;

    public static readonly MATERIAL_MAP: PropertyMap = {
        name: 'Material',
        type: PropertyType.FutureUri('material.model.ts'),
        sortable: true,
        filters: [ExactSearchFilter],
        // paramName: 'material.descripcion',
        order: 1
    }
    @BasicProperty(LoteMaterial.MATERIAL_MAP)
    private _material;

    public static readonly TIPO_MATERIAL_MAP: PropertyMap = {
        name: 'Tipo Material',
        type: PropertyType.FutureUri('tipo-material.model.ts'),
        sortable: true,
        nullable: true,
        filters: [ExactSearchFilter],
        // paramName: 'tipoMaterial.descripcion',
        order: 2
    }
    @BasicProperty(LoteMaterial.TIPO_MATERIAL_MAP)
    private _tipoMaterial;

    @BasicProperty({
        name: 'Color',
        type: PropertyType.Uri(ColorMaterial),
        nullable: true,
        order: 3
    })
    private _color: ColorMaterial;

    @BasicProperty({
        name: 'Cantidad',
        type: PropertyType.Float(),
        sortable: true,
        order: 7
    })
    private _cantidad;

    @BasicProperty({
        name: 'Fecha de Inicio',
        type: PropertyType.Date(),
        sortable: true,
        nullable: true,
        order: 4
    })
    private _fechaInicio;

    @BasicProperty({
        name: 'Fecha de Fin',
        type: PropertyType.Date(),
        sortable: true,
        nullable: true,
        order: 5
    })
    private _fechaFin;

    @BasicProperty({
        name: 'Fecha de caducidad',
        type: PropertyType.Date(),
        sortable: true,
        nullable: true,
        order: 6
    })
    private _fechaCaducidad;

    get resumen() {
        let res = this._descripcion;
        if (this._fechaInicio) {
            res += ' (Inicio: ' + this._fechaInicio.format('DD/MM/YYYY') + (this._fechaFin ? ' - Fin: ' + this._fechaFin.format('DD/MM/YYYY') : '') + ' )'
        }
        return res;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {
        this._descripcion = value;
    }

    get estado() {
        return this._estado;
    }

    set estado(value) {
        this._estado = value;
    }

    get cantidad() {
        return this._cantidad;
    }

    set cantidad(value) {
        this._cantidad = value;
    }

    get fechaInicio() {
        return this._fechaInicio;
    }

    set fechaInicio(value) {
        this._fechaInicio = value;
    }

    get fechaFin() {
        return this._fechaFin;
    }

    set fechaFin(value) {
        this._fechaFin = value;
    }

    get material() {
        return this._material;
    }

    set material(value) {
        this._material = value;
    }

    get tipoMaterial() {
        return this._tipoMaterial;
    }

    set tipoMaterial(value) {
        this._tipoMaterial = value;
    }

    get color(): ColorMaterial {
        return this._color;
    }

    set color(value: ColorMaterial) {
        this._color = value;
    }

    get fechaCaducidad() {
        return this._fechaCaducidad;
    }

    set fechaCaducidad(value) {
        this._fechaCaducidad = value;
    }
}
