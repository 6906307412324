import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {TelefonoPersona} from "../../../../../model/personas/telefono-persona.model";
import {InterfaceProviderService} from "../../../../../basic-entity-back/services/interface-provider.service";
import {NestedBehavior, NestedModelTypeInterface} from "../../../../../basic-entity-back/property-type/nested-model-type";
import {PropertyType} from "../../../../../basic-entity-back/property-type/property-type";
import {MatDialog} from "@angular/material/dialog";
import {BaseDialog} from "../../../../../basic-entity-front/basic-entity-table/base-dialog";
import {BaseDialogData} from "../../../../../basic-entity-front/basic-entity-table/base-dialog-data";
import {EditionDialogComponent} from "../../../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {BasicEntityInputComponent} from "../../../../../basic-entity-front/basic-entity-input/basic-entity-input.component";
import {FormManager} from "../../../../../basic-entity-front/edition-dialog/edition-row/edition-row.component";

@Component({
    selector: 'app-proveedor-telefonos',
    templateUrl: './proveedor-telefonos.component.html',
    styleUrls: ['./proveedor-telefonos.component.scss']
})
export class ProveedorTelefonosComponent {

    @Input() formGroup: UntypedFormGroup;

    constructor(private _intf: InterfaceProviderService, private _dialogService: MatDialog) {
    }

    addEdit(telefono?: TelefonoPersona, index?: number) {
        const telefonoIntf = this._intf.interfaceForModel(TelefonoPersona);
        const fakeType: NestedModelTypeInterface<TelefonoPersona> = PropertyType.NestedModel(TelefonoPersona, 'persona', NestedBehavior.CreateOnly).asNestedModel();
        const nuevoTelefono = telefonoIntf.serialiser.getEmptyModel();
        this._dialogService.open<BaseDialog<TelefonoPersona>, BaseDialogData<TelefonoPersona>>(EditionDialogComponent, {
            disableClose: true,
            autoFocus: true,
            restoreFocus: true,
            data: fakeType.baseDialogDataForModel(
                telefonoIntf,
                telefono ?? nuevoTelefono,
                !telefono,
                true,
                false
            )
        }).afterClosed().subscribe(result => {
            if (result === EditionDialogComponent.RESULT_SHOULD_RELOAD) {
                const arr = {};
                if (telefono) {
                    arr[BasicEntityInputComponent.UNIQUE_CONTROL_NAME] = telefono
                    this.telefonos.at(index).setValue(arr);
                } else {
                    arr[BasicEntityInputComponent.UNIQUE_CONTROL_NAME] = nuevoTelefono
                    this.telefonos.push(new UntypedFormControl(arr));
                }
            }
        })
    }

    get telefonos() {
        return this.formGroup.get('telefonos') as UntypedFormArray;
    }

    delete(telefono: TelefonoPersona, i: number) {
        this.telefonos.removeAt(i);
    }

    get telefonosArray() {
        return this.telefonos.value.map(v => v[BasicEntityInputComponent.UNIQUE_CONTROL_NAME]) as TelefonoPersona[];
    }
}
