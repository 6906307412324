<be-dialog-shell [title]="title" (close)="closeDialog()" [closeButton]="!saving"
                 [showFooter]="showSaveAndCancelButtons" [className]="tags.size > 1 ? 'fixed-size' : null">
    <form autocomplete="off" (submit)="$event.preventDefault();" [formGroup]="formGroup">
        <mat-tab-group *ngIf="tags.size > 1">
            <mat-tab *ngFor="let tag of tags" [label]="tag">
                <div class="tab-content"
                     *ngIf="['Principal', 'Direcciones', 'Teléfonos', 'Comercial asociado', 'Usuario', 'Facturación'].includes(tag); else arrayTemplate">
                    <be-edition-tab
                            *ngIf="['Principal', 'Teléfonos', 'Comercial asociado', 'Usuario'].includes(tag)"
                            [propertiesToShow]="colsForTag(tag)" [formGroup]="formGroup"
                            [formManager]="formManager"
                            [cien]="tag !== 'Principal'"></be-edition-tab>

                    <ng-container *ngIf="['Direcciones'].includes(tag)">
                        <be-edition-tab [propertiesToShow]="colsForTag(tag)" [formGroup]="formGroup"
                                        [formManager]="formManager" [cien]="true"></be-edition-tab>

                        <button mat-flat-button color="primary"
                                *ngIf="mostrarBotonDireccionesIguales()"
                                (click)="direccionesIguales()"
                                [disabled]="modified && !formGroup.valid">Las dos direcciones son iguales
                        </button>
                    </ng-container>

                    <ng-container *ngIf="['Facturación'].includes(tag)">
                        <be-edition-row [class]="'dialog-row cien'"
                                        [property]="prop('facturaDetallada')" [formGroup]="formGroup"
                                        [formManager]="formManager">
                        </be-edition-row>

                        <be-edition-row [class]="'dialog-row cien'"
                                        [property]="prop('diaMesParaCargo')" [formGroup]="formGroup"
                                        [formManager]="formManager">
                        </be-edition-row>

                        <be-edition-row [class]="'dialog-row cien'"
                                        [property]="prop('cuentasBancariasCliente')" [formGroup]="formGroup"
                                        [formManager]="formManager">
                        </be-edition-row>

                        <be-edition-row [class]="'dialog-row cien'"
                                        [property]="prop('formasPagoDisponibles')" [formGroup]="formGroup"
                                        [formManager]="formManager" [filters]="clienteFilter">
                        </be-edition-row>

                        <div class="boton-mandato">
                            <button mat-flat-button (click)="generarMandato()"
                                    [class]="model.mandato ? 'creado' : 'no-creado'">
                                <mat-icon>receipt</mat-icon>
                                Mandato SEPA
                            </button>
                        </div>

                    </ng-container>
                </div>
                <ng-template #arrayTemplate>
                    <ng-container [formArrayName]="'descuentos'" *ngIf="!model.isTemporalEntity">
                        <!--                        <be-table [editionDialog]="dialog" [modelType]="modelEdition"-->
                        <!--                                  [columnsToDisplay]="columns" [addNewRowSubject]="newRowSubject"-->
                        <!--                                  (modelEdited)="modeloEditado.bind(this)"></be-table>-->

                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                            <ng-container matColumnDef="metodoFabricacion">
                                <th mat-header-cell *matHeaderCellDef> Método Fabricación</th>
                                <td mat-cell *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.metodoFabricacion.descripcion }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="material">
                                <th mat-header-cell *matHeaderCellDef> Material</th>
                                <td mat-cell *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.material.descripcion }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="trabajo">
                                <th mat-header-cell *matHeaderCellDef> Trabajo</th>
                                <td mat-cell *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.trabajo ? element.trabajo.descripcion : ' --- ' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="subTrabajo">
                                <th mat-header-cell *matHeaderCellDef> Subtrabajo</th>
                                <td mat-cell *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.subTrabajo ? element.subTrabajo.descripcion : ' --- ' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tipoMaterial">
                                <th mat-header-cell *matHeaderCellDef> Acabado</th>
                                <td mat-cell *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.acabado ? element.acabado.descripcion : ' --- ' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="opcion">
                                <th mat-header-cell *matHeaderCellDef> Opción</th>
                                <td mat-cell *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.opcion ? element.opcion.descripcion : ' --- ' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="importeTarifa">
                                <th mat-header-cell class="centrado" *matHeaderCellDef> Importe tarifa</th>
                                <td mat-cell class="centrado" *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.importeTarifa }}€
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="descuento">
                                <th mat-header-cell class="centrado" *matHeaderCellDef> Descuento (%)</th>
                                <td mat-cell class="centrado" *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.descuento }}%
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="importeFinal">
                                <th mat-header-cell class="centrado" *matHeaderCellDef> Importe final</th>
                                <td mat-cell class="centrado" *matCellDef="let element"
                                    (click)="editarItem(element)"> {{ element.importeFinal }}€
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="delete">
                                <th mat-header-cell class="centrado" *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-flat-button (click)="editarItem(element)">
                                        <mat-icon color="primary">edit</mat-icon>
                                    </button>
                                    <button mat-flat-button (click)="eliminarItem(element)">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columns"></tr>
                            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
                        </table>
                        <button mat-fab class="mat-fab" color="primary" (click)="anadirItem()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-container>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
        <be-edition-tab *ngIf="tags.size <= 1"
                        [propertiesToShow]="colsToShow" [formGroup]="formGroup"
                        [formManager]="formManager"></be-edition-tab>
    </form>
    <dialog-actions>
        <button mat-button class="btn_cancelar" (click)="closeDialog()" [disabled]="saving">Cancelar</button>
        <button mat-flat-button class="btn_guardar" *ngIf="shouldManageSaving && modified" (click)="acceptDialog()"
                color="primary"
                [disabled]="!formGroup.valid || !nombreORazonSocialValidos || saving">
            <mat-icon>save</mat-icon>
            Guardar
        </button>
        <button mat-button *ngIf="!(shouldManageSaving && modified)" (click)="acceptDialog()"
                [disabled]="modified && (!formGroup.valid || !nombreORazonSocialValidos)">Aceptar
        </button>
    </dialog-actions>
</be-dialog-shell>
