<mat-progress-bar class="d-block" mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>

<div class="basic-entity-table-top">
    <button mat-raised-button class="add-btn" color="primary"  (click)="add(null)">
        <mat-icon>add</mat-icon>
        Nuevo
    </button>

</div>


<div *ngIf="!(loading$|async)">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding ngClass="final">
            <div class="item">
                <div>
                    <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon>
                    <span>{{node.text}}</span>
                </div>
                <div>
                    <mat-icon>grade</mat-icon>
                    <span>{{node.requiredRole}}</span>
                </div>
                <div>
                    <mat-icon>link</mat-icon>
                    <span>{{node.link}}</span>
                </div>
                <div class="separator"></div>

                    <button mat-icon-button color="primary" (click)="add(node)">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button mat-icon-button (click)="move(node, -1)">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </button>
                    <button mat-icon-button (click)="move(node, +1)">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="edit(node)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="remove(node)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>

            </div>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <div class="group">
                <button mat-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.text" class="group-button"
                        color="primary">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{node.icon ? node.icon : (treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right')}}
                    </mat-icon>
                    &nbsp; {{node.text}} [{{node.requiredRole}}]
                </button>

                <details>
                    <summary class="sumary">Acciones</summary>
                <button mat-icon-button color="primary" (click)="add(node)">
                    <mat-icon>add</mat-icon>
                </button>
                <button mat-icon-button (click)="move(node, -1)">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <button mat-icon-button (click)="move(node, +1)">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="edit(node)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="remove(node)">
                    <mat-icon>delete_forever</mat-icon>
                </button>
                </details>
            </div>
        </mat-tree-node>
    </mat-tree>

    <!-- POSICÓN DEL BOTÓN EN LA PARTE SUPERIOR
    <button mat-raised-button class="add-btn" color="primary"  (click)="add(null)">
        <mat-icon>add</mat-icon>
        Nuevo
    </button>
    -->
</div>

<ng-template #deletionConfirmation>
    <form [formGroup]="templateForm">
        <be-dialog-shell title="Editar item" (close)="cancelEdition()">
            <mat-form-field appearance="outline">
                <mat-label>Icono</mat-label>
                <input   matInput type="text" formControlName="icon">
            </mat-form-field> &nbsp;
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput type="text" formControlName="name" required>
            </mat-form-field> &nbsp;
            <mat-form-field appearance="outline">
                <mat-label>Rol requerido</mat-label>
                <input matInput type="text" formControlName="role" required>
            </mat-form-field> &nbsp;
            <mat-form-field appearance="outline"  [style.visibility]="linkEditable?'visible':'hidden'">
                <mat-label>Link</mat-label>
                <mat-select formControlName="link" [required]="linkRequerido">
                    <mat-option *ngFor="let ruta of rutasAplicacion" [value]="ruta">
                        {{ruta !== '' ? ruta : '-- Origen --'}}
                    </mat-option>
                </mat-select>
            </mat-form-field> &nbsp;
            <mat-form-field  appearance="outline"[style.visibility]="linkEditable?'visible':'hidden'">
                <mat-label>Parametros</mat-label>
                <input matInput type="text" formControlName="queryParams">
            </mat-form-field> &nbsp;
            <mat-label>No visible para empleados</mat-label>
            <mat-checkbox formControlName="notForEmployee"></mat-checkbox>


            <dialog-actions>
                <button class="btn_cancelar" mat-flat-button color="none" (click)="cancelEdition()">Cancelar</button>
                <button class="btn_guardar" mat-flat-button color="none" (click)="acceptEdition()" [disabled]="templateForm.invalid">
                    Aceptar
                </button>
            </dialog-actions>
        </be-dialog-shell>
    </form>
</ng-template>
