import {Component, Input, OnDestroy} from "@angular/core";
import {BasicEntityTableColumn} from "../basic-entity-table.component";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {TypeStr} from "../../../basic-entity-back/property-type/type-str";
import {Uri} from "../../../api/uri";
import {EntityNameService} from "../../../basic-entity-back/services/entity-name.service";
import {BehaviorSubject} from "rxjs";
import {IRI_PROPERTY} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {Resource} from "../../../api/resource";
import {RouteFilterTranslator} from "../../basic-entity-filtering/route-filter-translator";
import {MultipleSearchFilter} from "../../../basic-entity-back/filters/search-filter";
import {TranslationInputService} from "../../../basic-entity-back/services/translation-input.service";
import {ErrorDisplayService} from "../../services/error-display.service";

@Component({
    selector: "be-cell",
    styleUrls: ['./basic-entity-cell.component.scss'],
    templateUrl: "./basic-entity-cell.component.html"
})
export class BasicEntityCellComponent implements OnDestroy {
    public TypeStr = TypeStr;
    private _value;

    @Input() public column: BasicEntityTableColumn;

    public valueToPrint$ = new BehaviorSubject<string>('');
    public tooltip$ = new BehaviorSubject<string>('');

    private cachedLanguageNames: Map<string, string>;

    constructor(public translation: TranslationInputService,
                private _interfaceProvider: InterfaceProviderService,
                private _errorDisplay: ErrorDisplayService,
                private _nameService: EntityNameService) {
    }

    @Input()
    set value(value: any) {
        this._value = value;
        const val = this._basicCastedValue;
        this.valueToPrint$.next(val instanceof Array ? val.join(', ') : val);
        this.tooltip$.next(this._tooltip(this._value));

        if (!this._value) {
            return;
        }

        // Obtain the name if it is an URI
        if (this.column.type.toString() === TypeStr.Uri &&
            this.column.modelKey !== IRI_PROPERTY) {
            if (this.column.array) {
                const nombres = [];
                for (const uri of this._value) {
                    this._nameService.get(uri, false)
                        .then(name => {
                            nombres.push(name);
                            if (nombres.length === this._value.length) {
                                this.valueToPrint$.next(nombres.join(', '));
                            }
                        });
                }
            } else {
                this._nameService.get(this._value, false)
                    .then(name => {
                        this.valueToPrint$.next(name);
                        this.tooltip$.next(name);
                    })
                    .catch(error => console.error(error));
            }
        }
    }

    get value() {
        return this._value;
    }

    ngOnDestroy(): void {
        this.valueToPrint$.complete();
        this.tooltip$.complete();
    }

    public getLanguageName(languageCode: string): string {
        if (!this.cachedLanguageNames) {
            this.cachedLanguageNames = new Map();
        }
        if (!this.cachedLanguageNames.has(languageCode)) {
            this.translation.entityManager.getById(languageCode).then(
                l => this.cachedLanguageNames.set(
                    languageCode, this.translation.languageInterface.getName(l)
                ),
                err => this._errorDisplay.displayError(err)
            );
        }
        return this.cachedLanguageNames.get(languageCode) || languageCode;
    }

    private get _basicCastedValue() {
        let val;
        if (this.column.toStrCaster) {
            if (this.column.array) {
                val = this.value
                    .map(subValue => this.column.toStrCaster.fromModel(subValue))
                    .join("\n");
            } else {
                val = this.column.toStrCaster.fromModel(this.value);
            }
        } else {
            val = this.value;
        }
        return val;
    }

    private _tooltip(value: string | null): string {
        const column = this.column;
        let casted = this._basicCastedValue;
        if (casted === null || value === undefined || casted === undefined) {
            casted = "-- Vacío --";
        } else if (typeof casted === "boolean") {
            casted = casted ? "Sí" : "No";
        } else if (column.type.toString() === TypeStr.Dictionary) {
            return Object.entries(value).map(([k, v]) => k + ': ' + v).join(', ');
        } else {
            casted = casted.toString();
        }
        return `${column.title}: ${casted}`;
    }

    public get canBeLinked(): boolean {
        return (
            !this.column.array &&
            this.column.type.toString() === TypeStr.Uri &&
            ((this.value instanceof Uri &&
                    this._interfaceProvider.pathForModel(this.value.model) !== null) ||
                (this.value instanceof Resource &&
                    this._interfaceProvider.pathForModel(this.value.iri.model) !== null))
        );
    }

    public get canBeShownAsButton() {
        return this.column.shownAsButton;
    }

    public get textButton() {
        return this.column.buttonData.text(this.value);
    }

    public get actionButton() {
        return this.column.buttonData.action(this.value) ? this.column.buttonData.action(this.value) : null;
    }

    public get classButton() {
        return this.column.buttonData.class(this.value);
    }

    public get colorButton() {
        return this.column.buttonData.color ? 'background-color: ' + this.column.buttonData.color(this.value) : '';
    }

    public get disabledButton() {
        return this.column.buttonData ? this.column.buttonData.disabled(this.value) : true;
    }

    public get link(): string[] {
        if (this.value === null) {
            return null;
        }

        let subPath;
        if (this.value instanceof Uri) {
            subPath = this._interfaceProvider.pathForModel(this.value.model);
        } else if (this.value instanceof Resource) {
            subPath = this._interfaceProvider.pathForModel(this.value.iri.model);
        } else {
            throw new Error('The value is neither an Uri nor a Model');
        }
        if (!subPath) {
            return null;
        }
        return ['..', subPath];
    }

    public get linkParams(): any {
        if (this.value === null) {
            return null;
        }
        let interf;
        if (this.value instanceof Uri) {
            interf = this._interfaceProvider.interfaceForModel(this.value.model);
        } else if (this.value instanceof Resource) {
            interf = this._interfaceProvider.interfaceForModel(this.value.iri.model);
        } else {
            throw new Error('The value is neither an Uri nor a Model');
        }

        const params = {};
        for (const [idx, property] of interf.idProperties.entries()) {
            const paramName = RouteFilterTranslator.sParamToFilterBy(MultipleSearchFilter, '0', property);
            params[paramName] = (this.value as Uri).id[idx];
        }
        return params;
    }

    copyToClipboard(el: HTMLElement) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(el.innerText).then(() => {
            }, (error) => {
                console.log(error);
            });
        } else {
        }
    }
}
