import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Persona} from "./persona.model";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: 'Fabricante',
    endpoint: 'fabricantes',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'denominacionSocial',
    type: 'Fabricante',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class Fabricante extends Persona {
    @BasicProperty({
        name: 'Nombre Fabricante',
        type: PropertyType.String()
    })
    private _denominacionSocial: string;

    get denominacionSocial(): string {
        return this._denominacionSocial;
    }

    set denominacionSocial(value: string) {
        this._denominacionSocial = value;
    }
}
