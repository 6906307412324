/**
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */

import {InterfaceMapping, InternalPropertyMap, IRI_PROPERTY, TYPE_PROPERTY} from "./mapping-internal";
import {ReadWrite} from "./mapping-external";
import {TypeStr} from "../property-type/type-str";
import {IriNotPresent} from "./errors";
import {Resource} from "../../api/resource";
import {Type} from "@angular/core";
import {ApiInterface} from "../../api/api-interface";
import {InterfaceProviderService} from "../services/interface-provider.service";
import {BasicEntityInterface} from "./basic-entity-interface";
import {TempUri} from "../../api/uri";
import {map} from "rxjs/operators";
import {NestedBehavior} from "../property-type/nested-model-type";

/**
 * Serialiser used by the BasicEntityInterface to serialise the objects
 * into and from the API.
 */
export class BasicEntitySerialiser<ModelType extends Resource> {
    /**
     * Checks whether it is possible for the basic entity interface to set a concrete property
     * on the model. This is possible if and only if the model has defined a SETTER for it.
     * @private
     */
    private static _sCanSetValue(descriptors: { [s: string]: PropertyDescriptor }, key: string): boolean {
        const descriptor = descriptors[key];
        if (!descriptor) {
            return false;
        } else {
            return descriptor.set !== undefined;
        }
    }

    /**
     * Notice the interface needs to have the mapping initialised before calling this constructor!!
     */
    constructor(
        private readonly _interfaceProvider: InterfaceProviderService,
        public readonly mappingModelToApi: InterfaceMapping,
        public readonly model: Type<ModelType>,
        public readonly entityType: string) {

        const descriptors = this.getModelDescriptors();
        for (const [inModel, inApi] of Object.entries(this.mappingModelToApi)) {
            // If it can't be read from API and it is ReadOnly, we will never need
            // to write it to the model, so we don't need to check we can set the value.
            if (!inApi.apiBehavior.read && inApi.readWrite === ReadWrite.ReadOnly) {
                continue;
            }

            if (!BasicEntitySerialiser._sCanSetValue(descriptors, inModel)) {
                throw new Error(
                    `Property '${inModel}' defined in the mapping in ${this.entityType} cannot be set. ` +
                    `Notice that, by security, BasicEntityInterface WORKS ONLY with properties with get/set defined.`);
            }
        }
    }

    /**
     * Use this to get the value of a property from the model. Notice there is a special method to get the complete id.
     */
    public getValue(model: ModelType, property: string): any {
        return model[property];
    }

    /**
     * Use this method to obtain a value for a property as an string (if possible). Null and undefined are returned as they are.
     */
    public getValueAsString(model: ModelType, property: string): string | null | undefined {
        if (!this.mappingModelToApi[property]) {
            throw new Error(`The property ${property} does not exist in ${this.entityType}.`);
        }
        const value = this.getValue(model, property);
        if (value === undefined || this.mappingModelToApi[property].type.stringCaster === null) {
            return value;
        } else {
            return this.mappingModelToApi[property].type.stringCaster.fromModel(value);
        }
    }

    /**
     * Use this to set the value of a property to the model.
     */
    public setValue(model: ModelType, property: string, value: any): void {
        model[property] = value;
    }

    /**
     * Gets the descriptors of the model properties
     */
    public getModelDescriptors(): { [s: string]: PropertyDescriptor } {
        const descriptors: { [s: string]: PropertyDescriptor } = {};
        let prototype = Object.getPrototypeOf(this.getEmptyModel());
        while (prototype !== Object.prototype) {
            Object.assign(descriptors, Object.getOwnPropertyDescriptors(prototype));
            prototype = Object.getPrototypeOf(prototype);
        }
        return descriptors;
    }

    /**
     * Gets an empty model object for the interface to populate with data
     */
    public getEmptyModel(): ModelType {
        return new this.model();
    }

    /**
     * Parses an object from the API and returns a model object.
     * This method might modify the body param
     */
    public fromApi(body: any): ModelType {
        const model = this.getEmptyModel();
        for (const [inModel, inApi] of Object.entries(this.mappingModelToApi)) {
            if (inApi.apiBehavior.read) {
                let value = body[inApi.key];
                if (inModel === TYPE_PROPERTY && value !== this.entityType) {
                    console.error(`Error parsing '${new URL(body['@id'], ApiInterface.URL).toString()}', expected type was '${this.entityType}', but we received '${value}'`);
                }
                if (value instanceof Array && !inApi.array) {
                    throw new Error(`Received array in ${this.entityType}::${inModel}, but property has array: false. May this be a mistake?`);
                }
                this._checkTypeOf(value, inApi);
                if (value !== undefined) {
                    value = this._toModelCast(model, inApi, value);
                    this.setValue(model, inModel, value);
                }
            }
        }
        return model;
    }

    /**
     * Checks if the value has the right type (typeof) for the recommended types
     * for the property.type. Nulls an undefineds are directly skipped.
     * @param value
     * @param propertyMap
     * @param isSubcheck - don't use! leave it by default
     * @private
     */
    private _checkTypeOf(value: any, propertyMap: InternalPropertyMap, isSubcheck = false) {
        if (value === null || value == undefined) {
            return;
        }
        if (value instanceof Array) {
            for (const subValue of value) {
                this._checkTypeOf(subValue, propertyMap, true);
            }
        } else if (!isSubcheck && propertyMap.array) {
            console.warn(`The property ${propertyMap.modelKey} was expected to be an array, ${typeof value} received.`);
        } else if (propertyMap.type.expectedApiTypes.length === 0) {
            console.warn(`The type '${propertyMap.type.toString()}' has no defined expectedApiTypes, please define them (received type in this case: ${typeof value})`);
        } else if (propertyMap.type.expectedApiTypes.indexOf(typeof value) < 0) {
            console.warn(`Type checking error: ${this.entityType}::${propertyMap.modelKey} is expected ` +
                `to have one of the following types: ${propertyMap.type.expectedApiTypes.join(', ')}, but '${typeof value}' received.`);
        }
    }

    /**
     * Cast the given value from the api to set it to the model, it needs the target model
     * because if the parsed value is a nested model it needs to set the parent correctly.
     * It will throw an error if the parent IRI is not present yet.
     *
     * @throws {IriNotPresent} When the IRI of the model is not set before trying to perform a cast of a nested model
     */
    private _toModelCast(model: ModelType, mapping: InternalPropertyMap, value: any): any {
        if (mapping.caster) {
            if (mapping.array) {
                value = value.map(subVal => {
                    subVal = mapping.caster.toModel(subVal);
                    return this._insertParentUriIfNeeded(subVal, mapping, model);
                });
            } else {
                value = mapping.caster.toModel(value);
                this._insertParentUriIfNeeded(value, mapping, model);
            }
        }
        return value;
    }

    /**
     * @throws {IriNotPresent} - When no IRI is present in the model but we need an IRI to set it into the nested child
     */
    private _insertParentUriIfNeeded(val: any, inApi: InternalPropertyMap, model: ModelType) {
        if (val instanceof Resource && inApi.type.asNestedModel().mappedBy) {
            if (!model.iri || (model.iri instanceof TempUri)) {
                throw new IriNotPresent('Trying to parse nested model but no parent IRI present!');
            }
            const mappedBy = inApi.type.asNestedModel().mappedBy;
            const interf: BasicEntityInterface<Resource> = this._interfaceProvider.interfaceForModel(inApi.type.asNestedModel().modelType);
            const map = interf.mappingModelToApi[mappedBy];
            let valueForMappedBy;
            if (map && map.type.toString() === TypeStr.Uri) {
                if (map.array) {
                    const currentValue = interf.serialiser.getValue(val, mappedBy);
                    if (currentValue instanceof Array) {
                        currentValue.push(model.iri);
                        valueForMappedBy = currentValue;
                    } else {
                        valueForMappedBy = [model.iri];
                    }
                } else {
                    valueForMappedBy = model.iri;
                }
                interf.serialiser.setValue(val, mappedBy, valueForMappedBy);
            } else {
                throw new Error(
                    `${this.entityType}::${inApi.modelKey} is indicated to be mapped by ` +
                    `${interf.serialiser.entityType}::${mappedBy}, but such property is not ` +
                    `present in ${interf.serialiser.entityType} or it does not have type Uri.`);
            }
        }
        return val;
    }

    /**
     * Obtains an object to send to the API from the model object.
     */
    public toApi(model: ModelType, removeIds: boolean = false): any {
        const body = {};
        for (const [property, mapping] of Object.entries(this.mappingModelToApi)) {
            if (!mapping.apiBehavior.write) {
                continue;
            }
            const val = this.getValue(model, property);
            if (val !== undefined && !(val instanceof TempUri)) {
                if (removeIds) {
                    if (!["id", IRI_PROPERTY].includes(property)) {
                        body[mapping.key] = this._toApiCast(mapping, val, true);
                    }
                } else {
                    body[mapping.key] = this._toApiCast(mapping, val);
                }
            }
        }
        return body;
    }

    /**
     * Cast the given value of the given property (mapping) into the API value
     */
    private _toApiCast(mapping: InternalPropertyMap, value: any, removeIds: boolean = false): any {
        if (mapping.caster) {
            if (mapping.array) {
                return value.map(subVal => mapping.caster.fromModel(subVal, removeIds));
            } else {
                return mapping.caster.fromModel(value, removeIds);
            }
        } else {
            return value;
        }
    }

    /**
     * Clones the entity, so we can edit it as a nested entity without modifying the original.
     * This is done making sure also that all writable properties which are arrays are not the original arrays too.
     * It does NOT clone the values of the NestedModel properties (but yes the arrays, still).
     * The readonly properties are not cloned (since they are supposed not to be modified).
     * @param entity
     */
    public clone(entity: ModelType): ModelType {
        if (!entity) {
            return entity;
        }
        const newEntity = Object.assign(this.getEmptyModel(), entity);
        newEntity.iri = entity.iri.clone();
        for (const prop of Object.values(this.mappingModelToApi)) {
            if (prop.readWrite !== ReadWrite.ReadOnly) {
                const val = this.getValue(newEntity, prop.modelKey);
                if (val instanceof Array) {
                    this.setValue(newEntity, prop.modelKey, val.slice(0));
                }
            }

        }
        return newEntity;
    }


    /**
     * Clones the entity, so we can edit it as a nested entity without modifying the original.
     * This is done making sure also that all writable properties which are arrays are not the original arrays too.
     * It does NOT clone the values of the NestedModel properties (but yes the arrays, still).
     * The readonly properties are not cloned (since they are supposed not to be modified).
     * @param entity
     */
    public cloneWithoutId(entity: ModelType): ModelType {
        let newEntity = this.clone(entity)
        newEntity.iri = this.getEmptyModel().iri;
        for (const prop of Object.values(this.mappingModelToApi)) {
            if (prop.isId) {
                delete newEntity['_' + prop.modelKey];
            }
        }
        return newEntity;
    }


    /**
     * Copies all the properties from origin to target, arrays and nested objects will be passed
     * exactly (they will NOT be cloned, so the references will be exactly the same).
     * @param target
     * @param origin
     */
    public copyInto(target: ModelType, origin: ModelType) {
        if (!target || !origin) {
            return;
        }
        Object.assign(target, origin);
    }
}
