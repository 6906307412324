import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: 'Zonas',
    endpoint: 'zonas',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    modelNameProperty: 'nombre',
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'Zona'
})
export class Zona extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        keyInApi: 'nombreZona',
        name: 'Nombre',
        type: PropertyType.String()
    })
    private _nombre;

    @BasicProperty({
        name: 'Días de tránsito',
        type: PropertyType.Integer()
    })
    private _diasTransito;


    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get nombre() {
        return this._nombre;
    }

    set nombre(value) {
        this._nombre = value;
    }

    get diasTransito() {
        return this._diasTransito;
    }

    set diasTransito(value) {
        this._diasTransito = value;
    }
}
