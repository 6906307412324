import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {ManagerCachingStrategy} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Resource} from "../../api/resource";
import {CentroProduccion} from "../centros/centro-produccion.model";
import {ExactSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {MaterialMetodoFabricacionRel} from "./material-metodo-fabricacion-rel.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {IRI_PROPERTY, TYPE_PROPERTY} from "../../basic-entity-back/basic-entity-interface/mapping-internal";

@BasicEntity({
    name: 'Metodos de fabricación',
    endpoint: 'metodo_fabricacions',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    type: 'MetodoFabricacion',
    managerCaching: ManagerCachingStrategy.PrecacheOnStart,
    modelNameProperty: 'descripcion'
})
export class MetodoFabricacion extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
    })
    private _id;

    @BasicProperty({
        name: 'Método',
        type: PropertyType.String(),
        sortable: true,
        filters: [ExactSearchFilter],
        order: 0
    })
    private _descripcion;

    @BasicProperty({
        name: 'Materiales',
        type: PropertyType.NestedModel(MaterialMetodoFabricacionRel, 'metodoFabricacion', NestedBehavior.CreateOnly, [IRI_PROPERTY, TYPE_PROPERTY, 'orden']),
        array: true,
        order: 4
    })
    private _materiales;

    @BasicProperty({
        name: 'Centros de Producción',
        type: PropertyType.Uri(CentroProduccion),
        array: true
    })
    private _centros: CentroProduccion[]

    @BasicProperty({
        name: 'Hora de corte',
        type: PropertyType.Time(),
        order: 1
    })
    private _horaCorte;

    @BasicProperty({
        name: 'Tiempo de entrega (d)',
        type: PropertyType.Integer(),
        order: 3
    })
    private _tiempoFabricacion;

    @BasicProperty({
        name: 'Se fabrica en disco',
        type: PropertyType.Boolean(),
        order: 2
    })
    private _fabricarEnDisco;


    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {

        this._descripcion = value;
    }

    get materiales() {
        return this._materiales;
    }

    set materiales(value) {
        this._materiales = value;
    }

    get centros(): CentroProduccion[] {
        return this._centros;
    }

    set centros(value: CentroProduccion[]) {
        this._centros = value;
    }

    get horaCorte() {
        return this._horaCorte;
    }

    set horaCorte(value) {
        this._horaCorte = value;
    }

    get tiempoFabricacion() {
        return this._tiempoFabricacion;
    }

    set tiempoFabricacion(value) {
        this._tiempoFabricacion = value;
    }

    get fabricarEnDisco() {
        return this._fabricarEnDisco;
    }

    set fabricarEnDisco(value) {
        this._fabricarEnDisco = value;
    }
}

MaterialMetodoFabricacionRel.MAP_METODO.type = CentroProduccion.METODOS_MAP.type = PropertyType.Uri(MetodoFabricacion);
