import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmpleadosComponent} from './empleados/empleados.component';
import {MaterialModule} from "../../material/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BasicEntityFrontModule} from "../../basic-entity-front/basic-entity-front.module";
import {ClientesComponent} from './clientes/clientes.component';
import {ClientesEditionComponent} from './clientes/clientes-edition/clientes-edition.component';
import {RegistrosEmpleadosComponent} from './registros-empleados/registros-empleados.component';
import {ProveedoresComponent} from "./proveedores/proveedores.component";
import {MatRadioModule} from "@angular/material/radio";
import {ProveedoresEditionComponent} from './proveedores/proveedores-edition/proveedores-edition.component';
import {
    ProveedorDireccionesComponent
} from './proveedores/proveedores-edition/proveedor-direcciones/proveedor-direcciones.component';
import {
    ProveedorTelefonosComponent
} from './proveedores/proveedores-edition/proveedor-telefonos/proveedor-telefonos.component';
import {ComercialesComponent} from "./comerciales/comerciales.component";
import { DireccionesEditionComponent } from './clientes/direcciones-edition/direcciones-edition.component';

@NgModule({
    declarations: [
        EmpleadosComponent,
        ComercialesComponent,
        ClientesComponent,
        ClientesEditionComponent,
        RegistrosEmpleadosComponent,
        ProveedoresComponent,
        ProveedoresEditionComponent,
        ProveedorDireccionesComponent,
        ProveedorTelefonosComponent,
        DireccionesEditionComponent
    ],
    imports: [
        CommonModule,
        BasicEntityFrontModule,
        ReactiveFormsModule,
        MaterialModule,
        FormsModule,
        MatRadioModule
    ]
})
export class PersonasModule {
}
