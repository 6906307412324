import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {EditionDialogComponent} from "../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BaseDialogData} from "../../../basic-entity-front/basic-entity-table/base-dialog-data";
import {ApiModuleFactory} from "../../../api/api-module-factory.service";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {FormBuilder} from "@angular/forms";
import {ErrorDisplayService} from "../../../basic-entity-front/services/error-display.service";
import {Mandato} from "../../../model/tesoreria/mandatos/mandato.model";
import {ArchivoMandatoSepa} from "../../../model/archivos/archivo-mandato-sepa.model";
import {BehaviorSubject} from "rxjs";
import {CuentaBancariaCliente} from "../../../model/tesoreria/cuentas-bancarias/cuenta-bancaria-cliente.model";
import {FilterAndData} from "../../../api/filter-list";
import {ExactSearchFilter} from "../../../basic-entity-back/filters/search-filter";
import {ApiService} from "../../../api/api.service";
import {MandatoService} from "../../../services/mandato.service";

@Component({
    selector: 'app-mandatos-edition',
    templateUrl: './mandatos-edition.component.html',
    styleUrls: ['./mandatos-edition.component.scss']
})
export class MandatosEditionComponent extends EditionDialogComponent<Mandato> implements OnInit, OnDestroy {
    public readonly propMap: Map<string, InternalPropertyMap>;
    public cargando = false;
    protected readonly tipoArchivo = ArchivoMandatoSepa;
    referencia: string = '';
    filtrosCuentaBancaria: any[];
    cuentaBancaria = null;
    firmado: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: BaseDialogData<Mandato>,
                private dialogRef: MatDialogRef<EditionDialogComponent<Mandato>>, private _dialogService: MatDialog,
                private apiFactory: ApiModuleFactory, public interfaceProvider: InterfaceProviderService, private fb: FormBuilder,
                private _myErrorDisplay: ErrorDisplayService, private mandatoService: MandatoService) {
        super(data, dialogRef, apiFactory, interfaceProvider, fb, _myErrorDisplay);
        const propMap: [string, InternalPropertyMap][] = this.properties.map(p => [p.modelKey, p]) as [string, InternalPropertyMap][];
        this.propMap = new Map(propMap);

        this.referencia = data.model.isTemporalEntity ? 'Pendiente de generación' : data.model.referencia;
        console.log(data.model)

        if (data.model.cuentaBancariaCliente !== undefined) {
            this.cuentaBancaria = data.model.cuentaBancariaCliente;
        }

        this.firmado = this.data.model && !this.data.model.isTemporalEntity && this.data.model.archivoFirmado instanceof ArchivoMandatoSepa;
        // console.log(this.data.model.archivoFirmado)
        // console.log(this.firmado)
    }

    public prop(key: string): InternalPropertyMap | undefined {
        return this.properties.find(p => p.modelKey === key);
    }

    ngOnInit() {
        super.ngOnInit();
        const filtros = [];
        const clienteProp = this.interfaceProvider.interfaceForModel(CuentaBancariaCliente).mappingModelToApi['cliente'];
        filtros.push(new FilterAndData(ExactSearchFilter, this.data.model.cliente, clienteProp))
        this.filtrosCuentaBancaria = filtros;
    }


    public aceptar() {
        console.log(this.formGroup)
        this.acceptDialog();
    }

    public cancel() {
        this.closeDialog(false);
    }

    public guardarYDescargar() {
        const _manager = this.interfaceProvider.managerForModel(Mandato)
        const clone = this.interfaceProvider.interfaceForModel(Mandato).serialiser.clone(this.model);
        this.formManager.formToModel(clone);
        if (this.shouldManageSaving) {
            let observable;
            if (this.isNew) {
                observable = _manager.add(clone);
            } else {
                observable = _manager.update(clone);
            }
            this.saving = true;
            observable.subscribe(
                (received) => {
                    _manager.loader.entityInterface.serialiser.copyInto(this.model, received);
                    this.mandatoService.descargarMandato(this.model)
                    this.closeDialog(true);
                },
                err => {
                    this.saving = false;
                    this._myErrorDisplay.displayError(err);
                }
            );
        } else {
            _manager.loader.entityInterface.serialiser.copyInto(this.model, clone);
            this.closeDialog(true);
        }
    }

    eliminado() {
        this.formGroup.get('archivoFirmado').setValue(null);
        this.model.archivoFirmado = null;
    }
}

