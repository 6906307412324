import { keyframes } from '@angular/animations';
import { animation, trigger, transition, animate, style, sequence } from '@angular/animations';


export const fadeIn = animation([
    style({ opacity: 0 }),
    animate('100ms', style({ opacity: 1 }))
]);

export const fadeOut = animation([
    animate('100ms', style({ opacity: 0 }))

]);

// Animación para el fadeIn de la matTable. El fadeOut sólo se ejecuta cuando se borra la fila.
export const fadeInFadeOut =
    trigger('fadeInFadeOut', [
        transition('void=> *', [
            style({ opacity: 0 }),
            animate('50ms', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            animate('50ms', style({ opacity: 0 }))
        ])
]);

// Animación de caida de la fila verticalmente para edición de líneas
export const rowsAnimation =
    trigger('rowsAnimation', [
        transition('void => *', [
            style({ height: '*', opacity: '0', transform: 'translateY(-400px)', 'box-shadow': 'none' }),
            sequence([
                animate("600ms ease", style({ height: '*', opacity: 1, transform: 'translateY(0)', 'box-shadow': 'none' }))
            ])
        ])
    ]);

// Animación con rebote
export const bounceInDown =
    trigger('bounceInDown', [
        transition('void => *',
            animate('.3s 0s cubic-bezier(0.215, 0.610, 0.355, 1.000)',
                keyframes([
                    style({
                        opacity: 1,
                        transform: 'translate3d(0, -700px, 0)',
                        offset: 0
                    }),
                    style({
                        opacity: 1,
                        transform: 'translate3d(0, 50px, 0)',
                        offset: 0.6
                    }),
                    style({ transform: 'translate3d(0, -10px, 0)', offset: 0.75 }),
                    style({ transform: 'translate3d(0, 5px, 0)', offset: 0.9 }),
                    style({ opacity: 1, transform: 'none', offset: 1 })
                ])
            )
         )
    ]);

    export const flip =
        trigger ('flip', [
            transition('* => *', [
                animate('1s 0s ease-out',
                    keyframes([
                        style({
                            transform: 'perspective(400px) rotate3d(0, 1, 0, -360deg)',
                            offset: 0
                        }),
                        style({
                            transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotate3d(0, 1, 0, -190deg)',
                            offset: 0.4
                        }),
                        style({
                            transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotate3d(0, 1, 0, -170deg)',
                            offset: 0.5
                        }),
                        style({
                            transform: 'perspective(400px) scale3d(.95, .95, .95)',
                            offset: 0.8
                        }),
                        style({
                            transform: 'perspective(400px)',
                            offset: 1
                        })
                    ])
                )]
            )
        ]);
