import {BasicEntity, BasicProperty} from "../../../basic-entity-back/annotations";
import {CuentaBancaria} from "./cuenta-bancaria.model";
import {
    ManagerCachingStrategy,
    PropertyMap,
    ReadWrite
} from "../../../basic-entity-back/basic-entity-interface/mapping-external";
import {PropertyType} from "../../../basic-entity-back/property-type/property-type";


@BasicEntity({
    name: 'Cuenta bancaria del centro',
    endpoint: 'cuenta_bancaria_centros',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    type: "CuentaBancariaCentro",
    modelNameProperty: 'lectura',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class CuentaBancariaCentro extends CuentaBancaria {

    public static readonly CENTRO_MAP: PropertyMap = {
        name: 'Centro de producción',
        type: PropertyType.FutureUri('centro-produccion.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    @BasicProperty(CuentaBancariaCentro.CENTRO_MAP)
    private _centroProduccion;

    @BasicProperty({
        name: 'Identificador de acreedor',
        type: PropertyType.String(),
    })
    private _identificacionAcreedor: string;

    get centroProduccion() {
        return this._centroProduccion;
    }

    set centroProduccion(value) {
        this._centroProduccion = value;
    }

    get identificacionAcreedor(): string {
        return this._identificacionAcreedor;
    }

    set identificacionAcreedor(value: string) {
        this._identificacionAcreedor = value;
    }
}
