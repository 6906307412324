import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {FilterAndData} from "../../../api/filter-list";
import {FilterDefinition} from "../../../basic-entity-back/basic-entity-interface/mapping-external";
import {UntypedFormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {RangeFilters} from "../../../basic-entity-back/filters/range-filters";
import {GeneralSearchFilter} from "../../../basic-entity-back/filters/general-search-filter";
import {IriSearchFilter} from "../../../basic-entity-back/filters/iri-search-filter";
import {ExistsFilter} from "../../../basic-entity-back/filters/exists-filter";
import {DateFilters} from "../../../basic-entity-back/filters/date-filter";

enum FilterController {
    Default,
    Range,
    Exists,
    Ignore,
    Date
}

interface FilterAndProperty {
    id: number;
    control: UntypedFormControl;
    controller: FilterController;
    filter: FilterDefinition;
    property: InternalPropertyMap;
    subscription?: Subscription;
}

@Component({
    selector: 'be-filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent {
    /** The filter editions we have */
    public filters: FilterAndProperty[] = [];
    @Output() public newValue = new EventEmitter<{ [id: string]: FilterAndData }>();
    @Input() public fixedFilteringProperties: InternalPropertyMap[] = [];

    public setFilters(filterList: { [id: string]: FilterAndData }) {
        const notMatched = this.filters.slice();
        for (const [strId, filterAndData] of Object.entries(filterList)) {
            const id = Number(strId);
            const controller = this._controller(filterAndData.filter);
            if (controller === FilterController.Ignore) {
                continue;
            }
            const match = this.filters.find(f => f.id === id
                && f.property.modelKey === filterAndData.property.modelKey
                && f.controller === controller);
            if (match) {
                match.control.setValue(filterAndData, {emitEvent: false});
                notMatched.splice(notMatched.indexOf(match), 1);
            } else {
                this._addFilter(id, filterAndData.property, filterAndData.filter, filterAndData);
            }
        }
        for (const filter of notMatched) {
            // filter.subscription.unsubscribe();
            this.filters.splice(this.filters.indexOf(filter), 1);
        }
    }

    public readonly(filter) {
        for (const filterFixed of this.fixedFilteringProperties) {
            if (filter.property.key === filterFixed.key) {
                return true;
            }
        }
        return false;
    }

    public addFilter(property: InternalPropertyMap, filter: FilterDefinition) {
        this._addFilter(this._nextId(), property, filter, null);
    }

    private _nextId(): number {
        return this.filters.length > 0 ?
            Math.max(...this.filters.map(f => f.id)) + 1 :
            0;
    }

    private _addFilter(id: number, property: InternalPropertyMap, filter: FilterDefinition, filterAndData: FilterAndData | null) {
        const controller = this._controller(filter);
        if (controller === FilterController.Ignore) {
            return;
        }
        const filterAndProperty: FilterAndProperty = {
            id: id,
            controller: controller,
            property: property,
            filter: filter,
            control: new UntypedFormControl(filterAndData)
        };
        // filterAndProperty.subscription = filterAndProperty.control.valueChanges.subscribe(this._valueChanged.bind(this));
        this.filters.push(filterAndProperty);
    }

    private _valueChanged() {
        let filterList: { [id: string]: FilterAndData } = {};
        for (const filter of this.filters) {
            filterList[filter.id] = filter.control.value;
        }
        this.newValue.emit(filterList);
    }

    /**
     * @param filter
     * @private
     */
    private _controller(filter: FilterDefinition): FilterController {
        if (!Array.isArray(filter)) {
            if (RangeFilters.includes(filter)) {
                return FilterController.Range;
            } else if ([GeneralSearchFilter, IriSearchFilter].includes(filter)) {
                return FilterController.Ignore;
            } else if (filter === ExistsFilter) {
                return FilterController.Exists;
            } else if (DateFilters.includes(filter)) {
                return FilterController.Date;
            }
        } else {
            switch (filter) {
                case RangeFilters:
                    return FilterController.Range;
                case DateFilters:
                    return FilterController.Date;
            }
        }
        return FilterController.Default;
    }

    public removeFiltering(filtering: FilterAndProperty) {
        const index = this.filters.indexOf(filtering);
        if (index >= 0) {
            filtering.subscription.unsubscribe();
            this.filters.splice(index, 1);
            this._valueChanged();
        }
    }
}
