import {Component, OnInit} from '@angular/core';
import {Pais} from "../../../model/localizacion/pais.model";
import {
    BasicEntityTableColumnDescriptionOrString
} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Router} from "@angular/router";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {Comunidad} from "../../../model/localizacion/comunidad.model";
import {Provincia} from "../../../model/localizacion/provincia.model";
import {MultipleSearchFilter} from "../../../basic-entity-back/filters/search-filter";
import {RouteFilterTranslator} from "../../../basic-entity-front/basic-entity-filtering/route-filter-translator";
import {Subject} from "rxjs";
import {Municipio} from "../../../model/localizacion/municipio.model";
import {SessionService} from "../../../session/session.service";
import {Mercurable} from "../../../basic-entity-front/mercure/mercurable";

@Component({
    selector: 'app-pais',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model" [actions]="actions" [reloadItem]="onUpdateItem"></be-table>'
})
export class PaisComponent implements OnInit {
    public onUpdateItem: Subject<Pais>;

    public model = Pais;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        'codigoPais',
        {key: 'nombres', hidden: true},
        {key: 'nombreDefault', hiddenInEdition: true, weight: 2},
        'codArea',
        'zona',
        {key: 'festivos', hidden: true}
    ];
    public actions: {
        text: string;
        tooltip?: string;
        handler: (model: Pais) => void;
    }[] = [
        {text: 'Comunidades', tooltip: 'Ver comunidades', handler: this.verComunidades.bind(this)},
        {text: 'Provincias', tooltip: 'Ver provincias', handler: this.verProvincias.bind(this)}
    ];

    constructor(private _router: Router, private _interfaceProvider: InterfaceProviderService) {
        // super(_interfaceProvider.interfaceForModel(Pais))
    }

    ngOnInit() {
        // this.onUpdateItem = this.getUpdateItem();
    }

    private verComunidades(pais: Pais) {
        const endpoint = this._interfaceProvider.pathForModel(Comunidad);
        this._router.navigateByUrl(
            `${endpoint}?${RouteFilterTranslator.sParamToFilterBy(MultipleSearchFilter, '0', 'pais')}=${pais.codigoPais}`,
            /* Removed unsupported properties by Angular migration: preserveQueryParams. */ {}
        );
    }

    private verProvincias(pais: Pais) {
        const endpoint = this._interfaceProvider.pathForModel(Provincia);
        this._router.navigateByUrl(
            `${endpoint}?${RouteFilterTranslator.sParamToFilterBy(MultipleSearchFilter, '0', 'pais')}=${pais.codigoPais}`,
            /* Removed unsupported properties by Angular migration: preserveQueryParams. */ {}
        );
    }


}
