<mat-form-field (click)="onClick($event)">
    <input type="text" matInput (focus)="onFocus($event)" (keydown)="onKeyDown($event)" [placeholder]="placeholder"
           [formControl]="inputControl" [matAutocomplete]="auto"
           [matAutocompleteDisabled]="cannotPick" [attr.id]="id" [required]="required" [title]="title">
    <button mat-button matSuffix *ngIf="cannotPick && lastAcceptedValue && !required" (click)="clear($event)">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-button matSuffix *ngIf="isPickAndCreate" (click)="createOne()">
        <mat-icon>add</mat-icon>
    </button>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption">
    <mat-option @fadeInFadeOut class="autocompleteOption" *ngFor="let option of options | async" [value]="option">
        <span *ngIf="!contenido">{{getName(option)}}</span>
        <div style="display: flex; flex-direction: row; " *ngIf="contenido">
            <div *ngIf="!getContenido(option).matIcon.toString().includes('_svg')">
                <mat-icon>{{getContenido(option).matIcon}}</mat-icon>
            </div>
            <div *ngIf="getContenido(option).matIcon.toString().includes('_svg')">
                <mat-icon svgIcon="{{getContenido(option).matIcon}}"></mat-icon>
            </div>
            <div>
                <div class="autocompleteTitulo">{{getContenido(option).titulo}}</div>
                <div class="autocompleteSegundaLinea">{{getContenido(option).segundaLinea}}</div>
            </div>
        </div>
    </mat-option>
</mat-autocomplete>
